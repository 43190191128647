import { createRoutine } from 'redux-saga-routines';

import { EditCommunityEventTypeFormData } from 'sidebars/EditCommunityEventType/form';

export const doGetCommunityEventTypes = createRoutine(
  'GET_COMMUNITY_EVENT_TYPES',
  {
    trigger: (payload: string) => payload,
  },
);

export const doCreateCommunityEventType = createRoutine(
  'CREATE_COMMUNITY_EVENT_TYPE',
  {
    trigger: (payload: {
      data: EditCommunityEventTypeFormData;
      communityId: string;
      onSuccess: () => void;
    }) => payload,
  },
);

export const doEditCommunityEventType = createRoutine(
  'EDIT_COMMUNITY_EVENT_TYPE',
  {
    trigger: (payload: {
      data: EditCommunityEventTypeFormData;
      communityId: string;
      id: string;
      onSuccess: () => void;
    }) => payload,
  },
);

export const doDeleteCommunityEventType = createRoutine(
  'DELETE_COMMUNITY_EVENT_TYPE',
  {
    trigger: (payload: {
      communityId: string;
      id: string;
      onSuccess: () => void;
    }) => payload,
  },
);

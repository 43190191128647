// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';
import { map, noop } from 'lodash/fp';

import { EditButton, DeleteButton, SpottedText, IconText } from 'components';

import { Container, ButtonsContainer } from './styled';

interface TabListProps {
  items: any[];
  type?: string;
  onEdit?: (data: any) => void;
  onDelete?: (id: string) => void;
}

const TabList: FC<TabListProps> = ({
  items = [],
  type,
  onEdit = noop,
  onDelete = noop,
}) =>
  map(
    (item) => (
      <Container key={item.id}>
        {type === 'tasks' ? (
          <IconText icon={item.icon} text={item.title} />
        ) : (
          <SpottedText text={item.title} color={item.color} />
        )}
        <ButtonsContainer>
          {onEdit && <EditButton outlined onClick={() => onEdit(item)} />}
          {onDelete && (
            <DeleteButton
              outlined
              type="button"
              onClick={() => onDelete(item.id)}
            />
          )}
        </ButtonsContainer>
      </Container>
    ),
    items,
  );

export default withTranslation()(TabList);

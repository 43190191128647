import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, 300px) minmax(300px, 600px);
  gap: 36px;
  height: 100%;
  @media (max-width: 684px) {
    grid: none;
  }
`;

// export const SectionWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   height: calc(100vh - ${({ height }) => height + 27 + 'px'});
//   overflow: hidden;

//   &.members {
//     overflow-y: scroll;
//   }
// `;

export const SidebarTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: white;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SidebarDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: white;
  text-align: left;
  @media (max-width: 684px) {
    font-size: 16px;
  }
`;

export const StyledSteps = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 100%;
  overflow: auto;
  gap: 60px;
  border-radius: 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  background-color: ${({ theme }) => theme.color.brightTurquoise};
  transition: height 0.2s ease;
  padding: 24px;
  padding-bottom: 72px;
  z-index: 0;
  @media (max-width: 684px) {
    gap: 36px;
    padding: 24px 18px;
  }
`;

export const StepsHeightWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const StepsContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.brightTurquoise};
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  height: 400px;
  gap: 40px;
  ::before {
    margin-left: 18px;
    content: '';
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: calc(100% - 36px);
    width: 3px;
    background-color: ${({ theme }) => theme.color.casper};
    transition: all 0.5s ease;
  }
  ::after {
    content: '';
    margin-left: 18px;
    position: absolute;
    top: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
    height: ${({ height = 0 }) => height};
    background-color: ${({ theme }) => theme.color.white};
    width: 3px;
    transition: all 0.5s ease;
  }
  @media (max-width: 684px) {
    flex-direction: row;
    width: 100%;
    gap: 0px;
    justify-content: space-between;
    height: 100%;

    ::before {
      margin-left: 18px;
      content: '';
      position: absolute;
      z-index: 1;
      display: flex;
      flex-direction: column;
      width: calc(100% - 36px);
      height: 3px;
      top: 22px;
      background-color: ${({ theme }) => theme.color.casper};
      transition: all 0.5s ease;
    }
    ::after {
      content: '';
      margin-left: 18px;
      position: absolute;
      top: 0;
      z-index: 3;
      top: 22px;
      display: flex;
      flex-direction: column;
      width: ${({ height = 0 }) => `calc(${height} - 36px)`};
      background-color: ${({ theme }) => theme.color.white};
      height: 3px;
      transition: all 0.5s ease;
    }
  }
`;

export const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  z-index: 10;
  font-size: 16px;
  gap: 20px;
  background-color: ${({ theme }) => theme.color.brightTurquoise};
  @media (max-width: 684px) {
    flex-direction: column;
  }
`;

export const Step = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size = '40px' }) => size};
  height: ${({ size = '40px' }) => size};
  border-radius: 50%;
  border: 3px solid;
  font-weight: 700;
  transition: all 0.5s ease;
  border-color: ${({ theme, currentStep, idx }) =>
    idx <= currentStep ? theme.color.white : theme.color.casper};
  color: ${({ theme, currentStep, idx }) =>
    idx <= currentStep ? theme.color.white : theme.color.casper};
  @media (max-width: 684px) {
    width: ${({ size = '44px' }) => size};
    height: ${({ size = '44px' }) => size};
  }
`;

export const StepText = styled.span`
  font-size: 16px;
  color: white;
  transition: all 0.5s ease;
  color: ${({ theme, currentStep, idx }) =>
    idx <= currentStep ? theme.color.white : theme.color.casper};
  font-weight: 700;
  @media (max-width: 684px) {
    font-size: 14px;
  }
`;

export const ComponentWrapper = styled.div`
  position: relative;
  /* margin-left: auto;
  margin-right: auto; */
  display: flex;
  border-radius: 20px;
  /* border: 1px solid black; */
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  align-items: center;
  flex-direction: column;
`;
export const GhostWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1234;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  /* position: absolute; */
  /* position: sticky; */
  bottom: 72px;
  width: min(100%, 314px);
`;

export const ButtonContainer = styled.div`
  width: min(550px, 100%);
  display: flex;
  justify-content: center;
  margin-top: ${({ extraMargin }) => (extraMargin ? '36px' : '18px')};
  margin-bottom: ${({ extraMargin }) => (extraMargin ? '36px' : '18px')};
  @media (max-width: 684px) {
    width: 100%;
  }
`;

import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useMedia } from 'react-use';

import {
  Link as LinkIcon,
  SendMail,
  Sync,
  LeftArrow,
  AddMemberIcon,
} from 'static';
import { Button, Loader } from 'components';
import useOutsideClick from 'hooks/outsideClick';
import { Community, CommunityRoles } from 'types';
import { closeModal, openModal } from 'store/actionCreators/modal';
import { doRenewInvitationCode } from 'store/actionCreators/currentCommunity/currentCommunity';
import { INVITE_MEMBER } from 'constants/sidebars';
import CloseButton from 'components/Buttons/CloseButton';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
  getNewInvitationCodeFetching,
} from 'store/selectors/currentCommunity';
import { breakpoints } from 'theme';

import {
  StyledAddMemberPopupWrapper,
  StyledAddMemberPopup,
  StyledRenewCode,
} from './styled';

interface AddMemberPopupProps {
  isOpen: boolean;
}

const AddMemberPopup: FC<AddMemberPopupProps> = ({ isOpen }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isDownMd = useMedia(breakpoints.downMd);
  const invitationCodeFetching = useSelector(getNewInvitationCodeFetching);
  const community = useSelector(getCurrentCommunity);
  const communityUser = useSelector(getCurrentUserInCommunity);
  const popupRef = useRef<HTMLDivElement>();
  const codeLinkRef = useRef<HTMLInputElement>();
  const invitationLinkRef = useRef<HTMLInputElement>();
  const [copiedLink, setCopiedLink] = useState<number | null>(null);
  const [expandedSections, setExpandedSections] = useState<number[]>([0, 1, 2]);

  useEffect(() => {
    if (copiedLink !== null) {
      setCopiedLink(null);
    }
  }, [community.invitationCode]);

  const handleClose = () => {
    dispatch(closeModal());
  };

  useOutsideClick(popupRef, handleClose);

  const supportLink = useMemo(() => {
    if (i18n.language === 'de') {
      return 'https://gumb.app/support/mitglieder-zu-deiner-gemeinschaft-hinzufuegen-und-einladen';
    }

    return 'https://gumb.app/en/support/add-new-user';
  }, [i18n]);

  const copyToClipboard = async (ind) => {
    const value =
      ind === 0 ? codeLinkRef.current.value : invitationLinkRef.current.value;

    await navigator.clipboard.writeText(value);
    setCopiedLink(ind);
  };

  const toggleSection = (ind) => {
    setExpandedSections(
      expandedSections.includes(ind)
        ? expandedSections.filter((i) => i !== ind)
        : [...expandedSections, ind],
    );
  };

  const sectionAnimationProps = {
    initial: { height: 0, opacity: 0 },
    animate: { height: 'auto', opacity: 1 },
    exit: { height: 0, opacity: 0 },
    transition: { duration: 0.3 },
  };

  const onAddMemberClick = () => {
    dispatch(
      openModal({
        type: INVITE_MEMBER,
        placement: 'right',
      }),
    );
  };

  const onCodeRenew = () => {
    dispatch(doRenewInvitationCode(community?.id));
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <AnimatePresence>
      {isOpen && (
        <StyledAddMemberPopupWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <StyledAddMemberPopup
            ref={popupRef}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <StyledAddMemberPopup.Header>
              <h2>{t('communityInvitation.membersInvitation.title')}</h2>
              <p>
                <Trans
                  i18nKey="communityInvitation.membersInvitation.description"
                  t={t}
                  components={[
                    <a
                      key="support"
                      href={supportLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    />,
                  ]}
                />
              </p>
              <CloseButton hidden={false} onClick={handleClose} />
            </StyledAddMemberPopup.Header>
            <PerfectScrollbar
              style={{
                width: '100%',
                maxHeight: isDownMd
                  ? 'calc(100svh - 480px)'
                  : 'calc(100svh - 280px)',
              }}
              options={{
                wheelSpeed: 0.2,
                wheelPropagation: false,
                minScrollbarLength: 4,
              }}
            >
              {/*<StyledAddMemberPopup.Card>*/}
              {/*  <StyledAddMemberPopup.Card.Main>*/}
              {/*    <LinkIcon />*/}
              {/*    <div>*/}
              {/*      <input*/}
              {/*        type="hidden"*/}
              {/*        value={community.invitationCode}*/}
              {/*        ref={codeLinkRef}*/}
              {/*      />*/}
              {/*      <h3>*/}
              {/*        {t(*/}
              {/*          'communityInvitation.membersInvitation.codeInvitation.title',*/}
              {/*        )}*/}
              {/*      </h3>*/}
              {/*      <Button*/}
              {/*        variant="secondary"*/}
              {/*        disabled={invitationCodeFetching}*/}
              {/*        onClick={() => copyToClipboard(0)}*/}
              {/*      >*/}
              {/*        {invitationCodeFetching ? (*/}
              {/*          <Loader type="button" size="28px" thickness={2} />*/}
              {/*        ) : copiedLink === 0 ? (*/}
              {/*          t('common.copied')*/}
              {/*        ) : (*/}
              {/*          t(*/}
              {/*            'communityInvitation.membersInvitation.codeInvitation.button',*/}
              {/*          )*/}
              {/*        )}*/}
              {/*      </Button>*/}
              {/*      <StyledRenewCode onClick={onCodeRenew}>*/}
              {/*        <Sync />*/}
              {/*{community?.invitationCode*/}
              {/*  ? t(*/}
              {/*      'communityInvitation.membersInvitation.codeInvitation.updateLink',*/}
              {/*    )*/}
              {/*  : t(*/}
              {/*      'communityInvitation.membersInvitation.codeInvitation.generateLink',*/}
              {/*    )}*/}
              {/*      </StyledRenewCode>*/}
              {/*    </div>*/}
              {/*    <StyledAddMemberPopup.Card.Toggler*/}
              {/*      selected={expandedSections.includes(0)}*/}
              {/*      onClick={() => toggleSection(0)}*/}
              {/*    >*/}
              {/*      <LeftArrow />*/}
              {/*      {expandedSections.includes(0)*/}
              {/*        ? t('communityInvitation.membersInvitation.showLess')*/}
              {/*        : t('communityInvitation.membersInvitation.showMore')}*/}
              {/*    </StyledAddMemberPopup.Card.Toggler>*/}
              {/*  </StyledAddMemberPopup.Card.Main>*/}
              {/*  /!* eslint-disable-next-line @typescript-eslint/ban-ts-comment *!/*/}
              {/*  /!* @ts-ignore *!/*/}
              {/*  <AnimatePresence>*/}
              {/*    {expandedSections.includes(0) ? (*/}
              {/*      <StyledAddMemberPopup.Card.Description*/}
              {/*        {...sectionAnimationProps}*/}
              {/*      >*/}
              {/*        <div>*/}
              {/*          <h4>*/}
              {/*            {t(*/}
              {/*              'communityInvitation.membersInvitation.codeInvitation.howItWorks',*/}
              {/*            )}*/}
              {/*          </h4>*/}
              {/*          <p>*/}
              {/*            {t(*/}
              {/*              'communityInvitation.membersInvitation.codeInvitation.howItWorksText',*/}
              {/*            )}*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*        <div>*/}
              {/*          <h4>*/}
              {/*            {t(*/}
              {/*              'communityInvitation.membersInvitation.codeInvitation.goodToKnow',*/}
              {/*            )}*/}
              {/*          </h4>*/}
              {/*          <p>*/}
              {/*            {t(*/}
              {/*              'communityInvitation.membersInvitation.codeInvitation.goodToKnowText',*/}
              {/*            )}*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </StyledAddMemberPopup.Card.Description>*/}
              {/*    ) : null}*/}
              {/*  </AnimatePresence>*/}
              {/*</StyledAddMemberPopup.Card>*/}
              <StyledAddMemberPopup.Card>
                <StyledAddMemberPopup.Card.Main>
                  <LinkIcon />
                  <div>
                    <input
                      type="hidden"
                      value={community.inviteLink}
                      ref={invitationLinkRef}
                    />
                    <h3>
                      {t(
                        'communityInvitation.membersInvitation.linkInvitation.title',
                      )}
                    </h3>
                    <Button
                      variant="secondary"
                      onClick={() => copyToClipboard(1)}
                    >
                      {copiedLink === 1
                        ? t('common.copied')
                        : t(
                            'communityInvitation.membersInvitation.linkInvitation.button',
                          )}
                    </Button>
                  </div>
                  <StyledAddMemberPopup.Card.Toggler
                    selected={expandedSections.includes(1)}
                    onClick={() => toggleSection(1)}
                  >
                    <LeftArrow />
                    {expandedSections.includes(1)
                      ? t('communityInvitation.membersInvitation.showLess')
                      : t('communityInvitation.membersInvitation.showMore')}
                  </StyledAddMemberPopup.Card.Toggler>
                </StyledAddMemberPopup.Card.Main>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <AnimatePresence>
                  {expandedSections.includes(1) ? (
                    <StyledAddMemberPopup.Card.Description
                      {...sectionAnimationProps}
                    >
                      <div>
                        <h4>
                          {t(
                            'communityInvitation.membersInvitation.linkInvitation.howItWorks',
                          )}
                        </h4>
                        <p>
                          <ol>
                            <Trans
                              i18nKey="communityInvitation.membersInvitation.linkInvitation.howItWorksText"
                              t={t}
                              components={[<li key="howItWorks" />]}
                            />
                          </ol>
                        </p>
                      </div>
                      <div>
                        <h4>
                          {t(
                            'communityInvitation.membersInvitation.linkInvitation.adminFlow',
                          )}
                        </h4>
                        <p>
                          {t(
                            'communityInvitation.membersInvitation.linkInvitation.adminFlowText',
                          )}
                        </p>
                      </div>
                    </StyledAddMemberPopup.Card.Description>
                  ) : null}
                </AnimatePresence>
              </StyledAddMemberPopup.Card>
              {[CommunityRoles.OWNER, CommunityRoles.ADMIN].includes(
                communityUser?.communityPermission?.role,
              ) && (
                <StyledAddMemberPopup.Card>
                  <StyledAddMemberPopup.Card.Main>
                    <SendMail />
                    <div>
                      <h3>
                        {t(
                          'communityInvitation.membersInvitation.addMember.title',
                        )}
                      </h3>
                      <Button
                        variant="primary"
                        IconComponent={AddMemberIcon}
                        onClick={onAddMemberClick}
                      >
                        {t(
                          'communityInvitation.membersInvitation.addMember.button',
                        )}
                      </Button>
                    </div>
                    <StyledAddMemberPopup.Card.Toggler
                      selected={expandedSections.includes(2)}
                      onClick={() => toggleSection(2)}
                    >
                      <LeftArrow />
                      {expandedSections.includes(2)
                        ? t('communityInvitation.membersInvitation.showLess')
                        : t('communityInvitation.membersInvitation.showMore')}
                    </StyledAddMemberPopup.Card.Toggler>
                  </StyledAddMemberPopup.Card.Main>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore */}
                  <AnimatePresence>
                    {expandedSections.includes(2) ? (
                      <StyledAddMemberPopup.Card.Description
                        {...sectionAnimationProps}
                      >
                        <div>
                          <h4>
                            {t(
                              'communityInvitation.membersInvitation.addMember.selfSignUp',
                            )}
                          </h4>
                          <p>
                            {t(
                              'communityInvitation.membersInvitation.addMember.selfSignUpText',
                            )}
                          </p>
                        </div>
                        <div>
                          <h4>
                            {t(
                              'communityInvitation.membersInvitation.addMember.preRegister',
                            )}
                          </h4>
                          <p>
                            {t(
                              'communityInvitation.membersInvitation.addMember.preRegisterText',
                            )}
                          </p>
                        </div>
                      </StyledAddMemberPopup.Card.Description>
                    ) : null}
                  </AnimatePresence>
                </StyledAddMemberPopup.Card>
              )}
            </PerfectScrollbar>
          </StyledAddMemberPopup>
        </StyledAddMemberPopupWrapper>
      )}
    </AnimatePresence>
  );
};

export default AddMemberPopup;

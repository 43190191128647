import styled from 'styled-components';

import { breakpoints } from 'theme';

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 43px 27px 60px 27px;
  padding-bottom: 100px;
  column-gap: 14%;
  row-gap: 20px;
  @media ${breakpoints.lg} {
    column-gap: 10%;
  }
  @media ${breakpoints.downMd} {
    column-gap: 32px;
  }
  @media ${breakpoints.downSmPlus} {
    flex-direction: column;
    column-gap: 26px;
  }
`;

export const StyledDescriptionBlock = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  @media ${breakpoints.downMd} {
    max-width: 100%;
  }
`;

export const StyledDescriptionTitle = styled.h1`
  text-transform: capitalize;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 18px;
  font-weight: 600;
  text-align: start;
`;

export const SocialMedia = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 32px;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  @media ${breakpoints.downSmPlus} {
    margin-top: 26px;
  }
  a {
    width: 50px;
    height: 50px;
    @media ${breakpoints.downSmPlus} {
      width: 36px;
      height: 36px;
    }
    svg:first-child {
      width: 21px;
      height: 21px;
      @media ${breakpoints.downSmPlus} {
        width: 14px;
        height: 14px;
      }
    }
    svg:nth-of-type(2) {
      top: 4px;
      right: -2px;
      @media ${breakpoints.downSmPlus} {
        top: -1px;
        right: -4px;
      }
    }
  }
`;

export const StyledDescriptionText = styled.p`
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  margin-top: 32px;
  width: 100%;
  font-weight: normal;
  white-space: pre-wrap;
  line-break: normal;
  border: 1px solid #ebf2f7;
  padding: 10px;
  background-color: #f7faf9;
  border-radius: 4px;
  @media ${breakpoints.downMd} {
    max-width: 480px;
    width: 100%;
  }
  @media ${breakpoints.downSmPlus} {
    margin-top: 26px;
  }
  a {
    color: ${({ theme }) => theme.color.brightTurquoise};
    word-wrap: break-word;
  }
`;

export const StyledLinksBlock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  white-space: -moz-pre-wrap !important;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;

  @media ${breakpoints.downMd} {
    width: 100%;
  }
`;

export const StyledInvitationBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  button {
    max-width: 340px;
  }

  @media ${breakpoints.downSmPlus} {
    width: 100%;
  }
`;

export const StyledLinksContainer = styled.div`
  margin-top: 17px;

  @media ${breakpoints.downMd} {
    margin-top: 17px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    & > div {
      width: 48%;

      @media ${breakpoints.downSmPlus} {
        width: 100%;
      }
    }
  }
`;

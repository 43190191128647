import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { COMMUNITY_ROUTES } from 'configs';
import { CommunityPlaceholder } from 'static';
import { EditButton, HeaderEditButton, SignedCounter } from 'components';
import { getCurrentMembersChat } from 'store/selectors/currentCommunity';

import {
  Cover,
  Container,
  MembersInfo,
  Footer,
  Logo,
  Title,
  Head,
  LogoWrapper,
  LogoPlaceholder,
  StyledLink,
} from './styled';

interface HeaderProps {
  title: string;
  cover: string | null;
  logo: string | null;
  isUserInCommunity: boolean;
  isMenuOpen: boolean;
  hasAdminPermissions: boolean;
  usersCount: number;
}

const Header: FC<HeaderProps> = ({
  cover,
  logo,
  title,
  isUserInCommunity,
  isMenuOpen,
  hasAdminPermissions,
  usersCount,
}) => {
  const { t } = useTranslation();
  const members = useSelector(getCurrentMembersChat);
  const onlineMembers = members.filter(({ online }) => online);
  const styleBtn = {
    marginRight: '0px',
  };
  return (
    <Container>
      <Head isMenuOpen={isMenuOpen}>
        <Title>{title}</Title>
        {hasAdminPermissions && (
          <StyledLink to={COMMUNITY_ROUTES.SETTINGS}>
            <HeaderEditButton
              title={t('communitySettings.title')}
              style={styleBtn}
            />
          </StyledLink>
        )}
      </Head>
      <Cover src={cover || CommunityPlaceholder} />
      <Footer>
        <LogoWrapper>
          {logo ? (
            <Logo src={logo} />
          ) : (
            <LogoPlaceholder>{title.charAt(0).toUpperCase()}</LogoPlaceholder>
          )}
        </LogoWrapper>
        <MembersInfo>
          <SignedCounter
            value={usersCount || members.length}
            description={t('community.members')}
          />
          {isUserInCommunity && (
            <SignedCounter
              value={onlineMembers.length}
              description={t('community.online')}
              variant="primary"
            />
          )}
        </MembersInfo>
      </Footer>
    </Container>
  );
};

export default Header;

import { find, toNumber } from 'lodash/fp';
import { format } from 'date-fns';
import { TFunction } from 'react-i18next';

import {
  getDateString,
  getTimeInterval,
  addDays,
  addMonths,
  hasCommunityAdminPermissions,
} from 'utils';
import { getDate } from './dates';
import {
  Event,
  EventToRender,
  EventType,
  CommunityUser,
  Community,
  EventResponse,
  EventTask,
  CommunityTotal,
} from 'types';
import { generatePdf } from './pdf';

export type EventStatisticValue = {
  label?: string | JSX.Element | JSX.Element[];
  values?: number[] | string[] | JSX.Element[];
  eventIds?: string[];
  eventDates?: Date[];
  eventResponseId?: string;
};

export const getEventToRender = (
  event: Event,
  date: Date,
  eventTypes: EventType[],
  timeFormat: 12 | 24,
  exportPdfData?: {
    community: Community;
    responses: EventResponse[];
    tasks: EventTask[];
  },
): EventToRender => {
  const result: EventToRender = {
    id: event.id,
    title: event.title,
    description: event.description,
    isAfterRegDeadline: event.isAfterRegDeadline,
    eventType:
      eventTypes &&
      eventTypes.filter((type) => type.id === event.eventTypeId)[0]?.title,
    dateString: `${getDateString(date)}`,
    time: !event.allDay
      ? getTimeInterval(new Date(event.from), new Date(event.to), timeFormat)
      : null,
    color: eventTypes
      ? eventTypes.filter((type) => type.id === event.eventTypeId)[0]?.color
      : 'gray',
    recipients: event.recipients,
    date: new Date(date),
    tasks: event.tasks,
    subEventReplies: event.subEventReplies,
    subEventTaskReplies: event.subEventTaskReplies,
    optionalMessage: event.optionalMessages,
  };

  result.onExportPDFClick = () =>
    generatePdf(
      event,
      exportPdfData.community,
      eventTypes,
      exportPdfData.responses,
      exportPdfData.tasks,
      timeFormat,
    );

  return result;
};

export const mapDailyRepeatedEventToArray = (
  event: Event,
  filterFrom: Date,
  filterTo: Date,
  eventTypes: EventType[],
  timeFormat: 12 | 24,
): EventToRender[] => {
  const startDate = new Date(event.from);
  let endDate;
  if (event.endDate) {
    endDate = new Date(event.endDate);
  }
  const step = event.repeatedRules.intervalStep;

  const eventsArray: EventToRender[] = [];

  if (endDate) {
    for (
      let i = new Date(startDate);
      i.getTime() <= endDate.getTime();
      i = addDays(i, step)
    ) {
      if (
        i.getTime() >= filterFrom.getTime() &&
        i.getTime() <= filterTo.getTime()
      ) {
        eventsArray.push(getEventToRender(event, i, eventTypes, timeFormat));
      }
    }
  } else {
    for (
      let i = new Date(startDate);
      i.getTime() <= filterTo.getTime();
      i = addDays(i, step)
    ) {
      if (i.getTime() >= filterFrom.getTime()) {
        eventsArray.push(getEventToRender(event, i, eventTypes, timeFormat));
      }
    }
  }

  return eventsArray;
};

export const mapMonthlyRepeatedEventToArray = (
  event: Event,
  filterFrom: Date,
  filterTo: Date,
  eventTypes: EventType[],
  timeFormat: 12 | 24,
): EventToRender[] => {
  const startDate = new Date(event.from);
  let endDate;
  if (event.endDate) {
    endDate = new Date(event.endDate);
  }
  const step = event.repeatedRules.intervalStep;

  const eventsArray: EventToRender[] = [];

  if (endDate) {
    for (
      let i = new Date(startDate);
      i.getTime() <= endDate.getTime();
      i = addMonths(i, step)
    ) {
      if (
        i.getTime() >= filterFrom.getTime() &&
        i.getTime() <= filterTo.getTime()
      ) {
        eventsArray.push(getEventToRender(event, i, eventTypes, timeFormat));
      }
    }
  } else {
    for (
      let i = new Date(startDate);
      i.getTime() <= filterTo.getTime();
      i = addMonths(i, step)
    ) {
      if (i.getTime() >= filterFrom.getTime()) {
        eventsArray.push(getEventToRender(event, i, eventTypes, timeFormat));
      }
    }
  }

  return eventsArray;
};

export const mapWeeklyRepeatedEventToArray = (
  event: Event,
  filterFrom: Date,
  filterTo: Date,
  eventTypes: EventType[],
  timeFormat: 12 | 24,
): EventToRender[] => {
  const startDate = new Date(event.from);
  let endDate;
  if (event.endDate) {
    endDate = new Date(event.endDate);
  }
  const step = event.repeatedRules.intervalStep;
  const weekDays = Array.from(
    event.repeatedRules.perWeek.toString(2).padStart(7, '0'),
  );

  const eventsArray: EventToRender[] = [];

  if (endDate) {
    for (
      let i = new Date(startDate);
      i.getTime() <= endDate.getTime();
      i = addDays(i, 1)
    ) {
      if (
        i.getTime() >= filterFrom.getTime() &&
        i.getTime() <= filterTo.getTime() &&
        weekDays[i.getDay()] === '1'
      ) {
        eventsArray.push(getEventToRender(event, i, eventTypes, timeFormat));
      }
      if (i.getDay() === 6) {
        i = addDays(i, 7 * (step - 1));
      }
    }
  } else {
    for (
      let i = new Date(startDate);
      i.getTime() <= filterTo.getTime();
      i = addDays(i, 1)
    ) {
      if (i.getTime() >= filterFrom.getTime() && weekDays[i.getDay()] === '1') {
        eventsArray.push(getEventToRender(event, i, eventTypes, timeFormat));
      }
      if (i.getDay() === 6) {
        i = addDays(i, 7 * (step - 1));
      }
    }
  }

  return eventsArray;
};

export const getRegisteredUsers = (
  events: EventToRender[],
  responses: EventResponse[] = [],
  total: CommunityTotal,
  t: TFunction,
): EventStatisticValue => {
  const data: number[] =
    events?.map((event) => {
      let registeredUsers = 0;

      responses.forEach((response) => {
        const eventTotal = find(
          ({ eventResponseId, id, date }) =>
            eventResponseId === response.id && id === event.id,
          // getDate(date) === format(event.date, 'dd.MM.yyyy'),
          total.data,
        );
        registeredUsers += eventTotal ? toNumber(eventTotal.count) : 0;
      });

      return registeredUsers;
    }) || [];

  return {
    label: t('event.responsesReceived'),
    values: data,
    eventIds: events?.map((e) => e.id) || [],
    eventDates: events?.map((e) => e.date) || [],
  };
};

export const getGuests = (
  events: EventToRender[],
  responses: EventResponse[] = [],
  total: CommunityTotal,
  t: TFunction,
): EventStatisticValue => {
  const data: number[] = events?.map((event) => {
    let guests = 0;

    responses.forEach((response) => {
      const eventTotal = find(
        ({ eventResponseId, id, date }) =>
          eventResponseId === response.id && id === event.id,
        // getDate(date) === format(event.date, 'dd.MM.yyyy'),
        total.data,
      );
      guests += eventTotal ? toNumber(eventTotal.guests) : 0;
    });

    return guests;
  });

  return {
    label: t('event.additionalGuests'),
    values: data,
    eventIds: events?.map((e) => e.id) || [],
    eventDates: events?.map((e) => e.date) || [],
  };
};

export const getResponsesValues = (
  events: EventToRender[],
  responses: EventResponse[],
  total: CommunityTotal,
): EventStatisticValue[] => {
  const data: (EventStatisticValue & { eventResponseId: string })[] =
    responses?.map((response) => {
      const values: number[] =
        events?.map((event) => {
          const eventTotal = find(
            ({ eventResponseId, id, date }) =>
              eventResponseId === response.id && id === event.id,
            // getDate(date) === format(event.date, 'dd.MM.yyyy'),
            total.data,
          );
          return eventTotal ? toNumber(eventTotal.count) : 0;
        }) || [];

      return {
        label: response.title,
        values,
        eventIds: events?.map((e) => e.id) || [],
        eventDates: events?.map((e) => e.date) || [],
        eventResponseId: response.id,
      };
    }) || [];

  return data;
};

export const getTotalInvitees = (
  events: EventToRender[],
  t: TFunction,
): EventStatisticValue => {
  const values = events?.map((event) => event.recipients.length);

  return {
    label: t('event.totalInvitees'),
    values,
    eventIds: events?.map((e) => e.id) || [],
    eventDates: events?.map((e) => e.date) || [],
  };
};

export const getAwaiting = (
  events: EventToRender[],
  totalInvitees: EventStatisticValue,
  registeredUsers: EventStatisticValue,
  t: TFunction,
): EventStatisticValue => {
  const values = totalInvitees.values?.map(
    (value, ind) => +value - +registeredUsers.values[ind],
  );

  return {
    label: t('event.awaiting'),
    values,
    eventIds: events?.map((e) => e.id) || [],
    eventDates: events?.map((e) => e.date) || [],
  };
};

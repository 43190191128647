import React, { CSSProperties, FC, ReactElement, ReactNode } from 'react';

import {
  StyledMenuItemBlock,
  StyledIcon,
  StyledTitle,
  StyledMenuItemLinkBlock,
  StyledRightIcon,
} from './styled';

interface CommunityMenuItemProps {
  title: string;
  icon: ReactNode;
  to?: string;
  left?: boolean;
  iconStyles?: CSSProperties;
  RightIcon?: JSX.Element;
  menuBlockStyles?: React.CSSProperties;
}

const CommunityMenuItem: FC<CommunityMenuItemProps> = ({
  title,
  left,
  icon,
  to,
  iconStyles,
  RightIcon = <></>,
  menuBlockStyles,
}) => {
  return (
    <>
      {to ? (
        <StyledMenuItemLinkBlock
          end
          to={to}
          left={left}
          menuBlockStyles={menuBlockStyles}
        >
          <StyledIcon iconStyles={iconStyles}>{icon}</StyledIcon>
          <StyledTitle>{title}</StyledTitle>
          <StyledRightIcon>{RightIcon}</StyledRightIcon>
        </StyledMenuItemLinkBlock>
      ) : (
        <StyledMenuItemBlock to={to}>
          <StyledIcon iconStyles={iconStyles}>{icon}</StyledIcon>
          <StyledTitle>{title}</StyledTitle>
        </StyledMenuItemBlock>
      )}
    </>
  );
};

export default CommunityMenuItem;

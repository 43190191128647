import { axiosInstance as axios } from '../index';
import { EditCommunityEventTypeFormData } from 'sidebars/EditCommunityEventType/form';

export const requestCommunityEventTypes = async (id: string) => {
  const response = await axios.get(`/communities/${id}/eventType?new=true`);
  return response.data;
};

export const requestCreateCommunityEventType = async (
  communityId: string,
  data: EditCommunityEventTypeFormData,
) => {
  const response = await axios.post(
    `/communities/${communityId}/eventType`,
    data,
  );
  return response.data;
};

export const requestEditCommunityEventType = async (
  communityId: string,
  id: string,
  data: EditCommunityEventTypeFormData,
) => {
  const response = await axios.put(
    `/communities/${communityId}/eventType/${id}`,
    data,
  );
  return response.data;
};

export const requestDeleteCommunityEventType = async (
  communityId: string,
  id: string,
) => {
  const response = await axios.delete(
    `/communities/${communityId}/eventType/${id}`,
  );
  return response.data;
};

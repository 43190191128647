import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';

import { getCurrentGroup } from 'store/selectors/currentGroup';
import {
  Button,
  ButtonsBlock,
  DeleteButton,
  DeleteButtonBlock,
  Loader,
  PopupEventWrapper,
  Sidebar,
  SidebarFooter,
  SidebarHeader,
  Tabs,
  TextInput,
} from 'components';
import EventTab from './Event';
import ParticipantsTab from './Participants';
import TasksTab from './Tasks';
import DocumentsTab from './Documents';
import { useEventDetails } from './hooks';
import { EditEventType } from 'constants/sidebars';
import {
  getProfileName,
  getUTCDateFromDate,
  isNotifyPopupDisabled,
} from 'utils';
import { isAdminOrOwner } from '../../utils/roles';
import { CommunityRoles, EventDetailsSidebarData, ModalTypes } from 'types';
import { getData } from 'store/selectors/modal';
import { getDeleteEventLoading } from 'store/selectors/events';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
} from 'store/selectors/currentCommunity';
import { closeModal, openModal } from 'store/actionCreators/modal';
import { useSingleEvent } from '../../hooks/useSingleEvent';
import { breakpoints } from 'theme';
import { NotificationType } from '../../pages/CommunitySettings/NotificationsTab/types';
import { useClickOutsideClose } from 'hooks';
import { EditButtonBlock } from 'components/Shared/styled';

const EventDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDownMd = useMedia(breakpoints.downMd);
  const navigate = useNavigate();
  const location = useLocation();
  const [notifyPopup, setNotifyPopup] = useState<{
    isOpen: boolean;
    type?: EditEventType;
    notifyText?: string;
  }>({
    isOpen: false,
  });
  const [selectedTask, setSelectedTask] = useState<string | null>(null);
  const currentGroup = useSelector(getCurrentGroup);
  const modalData = useSelector(getData) as EventDetailsSidebarData;
  const {
    filterFrom,
    filterTo,
    openTotal,
    eventResponseId,
    width,
    date,
    eventId,
  } = modalData;
  const community = useSelector(getCurrentCommunity);
  const group = useSelector(getCurrentGroup);
  const currentUser = useSelector(getCurrentUserInCommunity);
  const isDeleteLoading = useSelector(getDeleteEventLoading);

  const event = useSingleEvent(community.id, eventId);

  const isOverallEvent = !!event?.communityId && !event?.groupId;
  const tasks = community?.eventTasks.filter((task) =>
    event?.tasks.includes(task.id),
  );
  const refModal = React.createRef<HTMLDivElement>();

  const closeSidebar = () => {
    if (location.pathname.includes('/event/')) {
      navigate(location.pathname.split('/event/')[0], { replace: true });
    }

    dispatch(closeModal());
  };

  useClickOutsideClose({ ref: refModal, callback: closeSidebar });

  const eventUsers =
    (group?.id ? group.users?.data : community?.users)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ?.filter((user) => event?.recipients.includes(user.id))
      .map((user) => {
        const data = currentGroup?.users?.data;
        return {
          ...user.profile,
          groupPermission: data
            ? data.find((u) => u.id === user.id)?.groupPermission
            : null,
          communityPermission: user.communityPermission,
          user,
        };
      }) || [];

  const { onDeleteEvents } = useEventDetails({
    groupId: group?.id,
    communityId: community?.id,
    eventId: event?.id,
    event,
    isOverallEvent,
  });

  const openCreateEventSidebar = () => {
    if (location.pathname.includes('/event/')) {
      navigate(location.pathname.split('/event/')[0], { replace: true });
    }

    dispatch(
      openModal({
        type: isOverallEvent
          ? ModalTypes.CREATE_EVENT_OVERALL
          : ModalTypes.CREATE_EVENT,
        placement: 'right',
        data: isOverallEvent
          ? {
              overallScheduler: community.overallScheduler,
              event,
              date,
              filterFrom,
              filterTo,
            }
          : {
              groupId: event?.groupId,
              event,
              date,
              filterFrom,
              filterTo,
            },
      }),
    );
  };

  const DeleteButtonComponent = ({ onClick = () => {} }) => (
    <DeleteButton withLabel type="button" onClick={onClick}>
      {isDeleteLoading ? (
        <Loader type="button" size="28px" thickness={2} />
      ) : null}
    </DeleteButton>
  );

  const EditButtonComponent = ({ onClick }) => (
    <Button
      type="button"
      width="100%"
      disabled={isDeleteLoading}
      onClick={onClick}
    >
      {t('common.edit')}
    </Button>
  );

  const handleViewReplyClick = () => {
    const subReply = event?.subEventReplies
      ?.filter((r) => r.userId === currentUser.id)
      .filter(
        (r) =>
          new Date(r.date).getTime() ===
          getUTCDateFromDate(new Date(date)).getTime(),
      )[0];
    const subTaskReplies = event?.subEventTaskReplies
      ?.filter((r) => r.userId === currentUser.id)
      .filter(
        (r) =>
          new Date(r.date).getTime() ===
          getUTCDateFromDate(new Date(date)).getTime(),
      )
      .map((task) => task.eventTaskId);

    closeSidebar();

    dispatch(
      openModal({
        type: ModalTypes.GIVE_REPLY,
        placement: 'right',
        data: {
          userId: currentUser.id,
          isViewOnly: false,
          isAdmin: isAdminOrOwner(currentUser),
          subReply: subReply
            ? {
                ...subReply,
                tasks: subTaskReplies,
              }
            : null,
          eventId: event?.id,
          date: new Date(event?.from),
          name: getProfileName(currentUser.profile),
          filterFrom,
          filterTo,
        },
      }),
    );
  };

  const onDeleteButtonClick = (type: EditEventType) => {
    const ignoreNotifyPopup = isNotifyPopupDisabled(
      NotificationType.EVENT_DELETE,
      event,
      community.notifications,
    );

    if (ignoreNotifyPopup) {
      return onDeleteEvents(type, false);
    }

    setNotifyPopup({ isOpen: true, type });
  };

  const deletePopupButtons = [
    {
      popupButtonText: t('event.eventOperations.eventOnly'),
      handleConfirm: () => onDeleteButtonClick(EditEventType.SINGLE_EVENT),
    },
    {
      popupButtonText: t('event.eventOperations.eventAndFollowUp'),
      handleConfirm: () => onDeleteButtonClick(EditEventType.SINGLE_AND_FOLLOW),
    },
    {
      popupButtonText: t('event.eventOperations.all'),
      handleConfirm: () => onDeleteButtonClick(EditEventType.FULL_EVENT),
    },
  ];

  const deletePopupButtonsOne = [
    {
      popupButtonText: t('common.ok'),
      handleConfirm: () => onDeleteButtonClick(EditEventType.SINGLE_EVENT),
    },
    {
      popupButtonText: t('common.cancel'),
      handleConfirm: () => {},
    },
  ];

  const buttonStyles = { width: '100%', minWidth: '200px' };

  const popupButtons = [
    {
      popupButtonText: t('communitySettings.notificationPopupBack'),
      buttonStyles,
      variant: 'secondary',
      handleConfirm: () => setNotifyPopup({ isOpen: false }),
    },
    {
      popupButtonText: t('communitySettings.notificationPopupDoNotNotify'),
      buttonStyles,
      handleConfirm: () => {
        onDeleteEvents(notifyPopup.type, false);
        setNotifyPopup({ isOpen: false });
      },
    },
    {
      popupButtonText: t('communitySettings.notificationPopupNotify'),
      buttonStyles,
      handleConfirm: () => {
        onDeleteEvents(notifyPopup.type, true, notifyPopup.notifyText);
        setNotifyPopup({ isOpen: false });
      },
    },
  ];

  if (!event) {
    return null;
  }

  return (
    <Sidebar width={width} ref={refModal}>
      <SidebarHeader
        label={
          selectedTask
            ? tasks.filter((task) => task.id === selectedTask)[0].title
            : t('event.eventDetails')
        }
        onCloseClick={closeSidebar}
        onReturnClick={selectedTask ? () => setSelectedTask(null) : null}
      />
      {notifyPopup.isOpen && (
        <PopupEventWrapper
          innerComponent={
            <div style={{ width: '100%' }}>
              <TextInput
                width="100%"
                value={notifyPopup.notifyText}
                label={t('communitySettings.notificationPopupMessageLabel')}
                onChange={(e) =>
                  setNotifyPopup({
                    ...notifyPopup,
                    notifyText: e.target.value,
                  })
                }
              />
            </div>
          }
          isOpen={true}
          popupMessageText={t('communitySettings.notificationPopupHeader')}
          popupButtons={popupButtons}
          buttonContainerStyles={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '10px',
            marginTop: '30px',
            flexDirection: isDownMd ? 'column' : 'row',
          }}
          popupStyles={{
            width: '94%',
            maxWidth: '670px',
            padding: '20px',
          }}
          darkBackground={true}
          onPopupClose={() => setNotifyPopup({ isOpen: false })}
        />
      )}
      <Tabs isSidebar>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <div label={t('event.event')} index={0}>
          <EventTab
            event={event}
            currentUser={currentUser}
            date={date}
            width={width}
            eventResponseId={eventResponseId}
            openTotal={openTotal}
            onViewReplyClick={handleViewReplyClick}
          />
        </div>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <div label={t('common.participants')} index={1}>
          <ParticipantsTab users={eventUsers} />
        </div>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <div label={t('common.tasks')} index={2}>
          <TasksTab
            tasks={tasks}
            selectedTask={selectedTask}
            setSelectedTask={setSelectedTask}
            event={event}
            users={eventUsers}
            date={date}
          />
        </div>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <div label={t('common.documentation')} iconLabel grow index={3}>
          <DocumentsTab documents={event.documents} />
        </div>
      </Tabs>
      <SidebarFooter>
        {!(
          isOverallEvent &&
          ![CommunityRoles.OWNER, CommunityRoles.ADMIN].includes(
            currentUser.communityPermission.role,
          )
        ) && (
          <ButtonsBlock>
            <DeleteButtonBlock>
              {event.eventSeriesId ? (
                <PopupEventWrapper
                  popupMessageText={t('event.eventOperations.deleteText')}
                  popupButtons={deletePopupButtons}
                  darkBackground={true}
                >
                  <DeleteButtonComponent />
                </PopupEventWrapper>
              ) : (
                <PopupEventWrapper
                  popupMessageText={t('groupSettings.deleteGroupHeader')}
                  popupButtons={deletePopupButtonsOne}
                  darkBackground={true}
                >
                  <DeleteButtonComponent />
                </PopupEventWrapper>
              )}
            </DeleteButtonBlock>
            <EditButtonBlock>
              <EditButtonComponent onClick={openCreateEventSidebar} />
            </EditButtonBlock>
          </ButtonsBlock>
        )}
      </SidebarFooter>
    </Sidebar>
  );
};

export default EventDetails;

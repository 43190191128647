import styled, { css } from 'styled-components';

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const StyledLabel = styled.label`
  margin: ${({ zoom = 1, noMargin }) =>
    noMargin
      ? 0
      : `${Math.floor(10 * zoom)}px 0 ${Math.floor(2 * zoom)}px ${Math.floor(
          10 * zoom,
        )}px`};
  font-size: ${({ zoom = 1, fontSize = 12 }) => Math.floor(fontSize * zoom)}px;
  color: ${({ error, theme }) =>
    error ? theme.color.bittersweet : theme.color.baliHai};
  display: flex;
  align-items: center;
  text-align: ${({ labelAlignment = 'center' }) => labelAlignment};
  ${({ fontWeight }) =>
    fontWeight
      ? css`
          font-weight: ${fontWeight};
        `
      : ''}
  & > svg {
    margin-left: ${({ zoom = 1 }) => Math.floor(5 * zoom)}px;
    margin-top: ${({ zoom = 1 }) => Math.floor(2 * zoom)}px;
  }
`;

export const StyledErrorMessage = styled.p`
  text-align: left;
  margin: ${({ zoom = 1 }) =>
    `${Math.floor(5 * zoom)}px 0 ${Math.floor(10 * zoom)}px ${Math.floor(
      15 * zoom,
    )}px`};
  font-size: ${({ zoom = 1 }) => Math.floor(10 * zoom)}px;
  color: ${({ theme }) => theme.color.bittersweet};
`;

export const ScrollbarStyle = css`
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #cfcfcf #e4e4e4;
  }
  /* Chrome, Edge and Safari */
  *::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  *::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #f0f0f0;
  }

  *::-webkit-scrollbar-track:hover {
    background-color: #e4e4e4;
  }

  *::-webkit-scrollbar-track:active {
    background-color: #e3e3e3;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #cfcfcf;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: #9f9f9f;
  }

  *::-webkit-scrollbar-thumb:active {
    background-color: #8e8e8e;
  }
`;

import styled from 'styled-components';

import { CalendarIllustrate } from 'static';
import { breakpoints } from 'theme';

const Layout = styled.div`
  height: 100svh;
  text-align: left;
`;

Layout.Header = styled.div`
  background-color: ${({ theme }) => theme.color.aquaSqueeze};
  border-bottom: 1px solid ${({ theme }) => theme.color.porcelain};
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div:last-of-type {
    display: flex;
    justify-content: center;

    & > div:first-of-type {
    }

    & > div:nth-of-type(2) {
      @media ${breakpoints.downSmPlus} {
        min-width: 100px;
        max-width: 150px;
        width: auto;
        padding-right: 0;
      }
    }

    & > div:nth-of-type(3) {
      width: 120px;
      margin-left: 20px;
    }
  }

  @media ${breakpoints.downMd} {
    padding-right: 50px;

    & > div > div:first-of-type {
      input {
        width: 170px;
      }
    }
  }
`;

Layout.Data = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  height: calc(100svh - 50px);
`;

const StyledFloatingLoader = styled.div`
  position: fixed;
  width: 70px;
  height: 70px;
  right: 50px;
  top: 50px;
  background: #ffffffbf;
  border-radius: 50%;
  z-index: 100;
  box-shadow: 0px 0px 10px 0px #21cec9;
`;

const StyledFilterBar = styled.div`
  display: flex;
  justify-content: space-around;
`;

const Heading = styled.h1`
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 16px;
`;

const TasksList = styled.div`
  width: 100%;
  max-width: calc(100vw - 360px);
  min-height: calc(100svh - 100px);
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  background: white;
  padding-bottom: 80px;
  margin-top: 20px;
  padding-left: 15px;
  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }

  @media ${breakpoints.downMd} {
    max-width: 100vw;
  }
`;

const EventCard = styled.div`
  /* min-width: ${({ zoom }) => Math.floor(270 * zoom)}px; */
  width: ${({ zoom }) => Math.floor(270 * zoom)}px;
  height: max-content;
  border-right: 1px solid #e5e9e9;
  border-radius: 7px;
  padding: 0 ${({ zoom }) => Math.floor(5 * zoom)}px;
  position: relative;

  &:last-of-type {
    border-right: none;
  }

  &:before {
    content: '';
    position: absolute;
    right: -3px;
    width: 6px;
    height: 6px;
    background: white;
  }

  &:after {
    content: '';
    position: absolute;
    right: -3px;
    width: 6px;
    height: 15px;
    background: white;
    bottom: -2px;
  }
`;

EventCard.Community = styled.div`
  height: ${({ zoom }) => Math.floor(55 * zoom)}px;
  display: flex;
  align-items: center;
  background: #ebf2f7;
  border-radius: ${({ zoom }) => Math.floor(6 * zoom)}px;
  padding: ${({ zoom }) =>
    `${Math.floor(6 * zoom)}px ${Math.floor(12 * zoom)}px`};
  margin-bottom: ${({ zoom }) => Math.floor(5 * zoom)}px;
  cursor: pointer;
  transition: all 0.3s ease;
  -webkit-transform: translateZ(0) scale(1, 1);
  transform: translateZ(0);
  &:hover {
    outline: rgba(0, 0, 0, 0.16) 0px 1px 3px;
    transform: scale(1.03);
    -webkit-transform: scale(1.03);
  }
`;
EventCard.Community.ImageBlock = styled.div`
  width: ${({ zoom }) => Math.floor(40 * zoom)}px;
  height: ${({ zoom }) => Math.floor(40 * zoom)}px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: ${({ zoom }) => Math.floor(10 * zoom)}px;
  align-items: center;
  display: flex;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

EventCard.Community.TitleBlock = styled.div``;

EventCard.Event = styled.div`
  height: ${({ zoom }) => Math.floor(150 * zoom)}px;
  border-radius: ${({ zoom }) => Math.floor(6 * zoom)}px;
  padding: ${({ zoom }) =>
    `${Math.floor(6 * zoom)}px ${Math.floor(12 * zoom)}px`};
  margin-bottom: ${({ zoom }) => Math.floor(5 * zoom)}px;
  background: ${({ color = '#8298AB' }) => color};
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.03);
  }
`;

EventCard.Event.Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

EventCard.Event.EventType = styled.div`
  font-size: ${({ zoom }) => Math.floor(12 * zoom)}px;
  padding: 0 ${({ zoom }) => Math.floor(4 * zoom)}px;
  background: #0000003b;
  display: inline-block;
  width: max-content;
  border-radius: ${({ zoom }) => Math.floor(5 * zoom)}px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: ${({ zoom }) => Math.floor(235 * zoom)}px;
  min-height: ${({ zoom }) => Math.floor(18 * zoom)}px;

  span {
    position: relative;
  }
`;

EventCard.Event.EventTitle = styled.div`
  font-size: ${({ zoom }) => Math.floor(14 * zoom)}px;
  line-height: ${({ zoom }) => Math.floor(18 * zoom)}px;
  position: relative;
  height: auto;
  max-height: ${({ zoom }) => Math.floor(18 * zoom) * 3}px;
  display: flex;
  align-items: center;

  span {
    overflow: hidden;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: ${({ zoom }) => Math.floor(18 * zoom) * 3}px;
  }
`;

EventCard.Event.Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

EventCard.Event.DateTime = styled.div`
  font-size: ${({ zoom }) => Math.floor(12 * zoom)}px;
  line-height: ${({ zoom }) => Math.floor(16 * zoom)}px;
`;

EventCard.GreyDivider = styled.div`
  height: ${({ zoom }) => Math.floor(40 * zoom)}px;
  background: #ebf2f7;
  width: ${({ zoom }) => Math.floor(270 * zoom)}px;
  margin-bottom: ${({ zoom }) => Math.floor(5 * zoom)}px;
`;

EventCard.TasksList = styled.div`
  overflow: hidden;
`;

EventCard.Task = styled.div`
  height: ${({ zoom }) => Math.floor(40 * zoom)}px;
  background: #41a2ef;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ zoom }) => Math.floor(12 * zoom)}px;
  margin-bottom: ${({ zoom }) => Math.floor(5 * zoom)}px;
  border-radius: ${({ zoom }) => Math.floor(6 * zoom)}px;

  & > div:last-of-type {
    display: flex;
    align-items: center;

    & > span {
      transform: scale(${({ zoom }) => zoom});
      color: #fff;
      font-size: ${({ zoom }) => Math.floor(10 * zoom)}px;
      margin-right: ${({ zoom }) => Math.floor(5 * zoom)}px;
      width: ${({ zoom }) => Math.floor(20 * zoom)}px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;

EventCard.Task.Label = styled.div`
  overflow: hidden;
  white-space: nowrap;
  padding-right: ${({ zoom }) => Math.floor(8 * zoom)}px;
  text-overflow: ellipsis;
  font-size: ${({ zoom }) => Math.floor(14 * zoom)}px;
  color: white;
`;

EventCard.Task.ProfileRow = styled.div`
  padding: ${({ zoom }) =>
    `${Math.floor(6 * zoom)}px ${Math.floor(5 * zoom)}px`};
  display: flex;
  width: ${({ zoom }) => Math.floor(125 * zoom)}px;
`;

EventCard.Reply = styled.div`
  height: ${({ zoom }) => Math.floor(40 * zoom)}px;
  background-color: ${({ color }) => color};
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ zoom }) => Math.floor(5 * zoom)}px;
  border-radius: ${({ zoom }) => Math.floor(6 * zoom)}px;
  transition: transform 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.03);
  }

  div {
    svg {
      transform: scale(${({ zoom }) => zoom});
    }
  }

  img {
    margin: 0 ${({ zoom }) => Math.floor(5 * zoom)}px;
  }
`;

EventCard.ReplyFields = styled.div`
  height: ${({ zoom }) => Math.floor(300 * zoom)}px;
`;

EventCard.ResponsesBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ zoom }) => Math.floor(270 * zoom)}px;
  box-shadow: 0px 0px 4px 0px #6a6a6a7d;
  border-radius: ${({ zoom }) => Math.floor(6 * zoom)}px;
`;

EventCard.ResponseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  color: ${({ theme }) => theme.color.baliHai};
  width: 100%;
  min-height: ${({ zoom }) => Math.floor(40 * zoom)}px;
  height: ${({ zoom }) => Math.floor(40 * zoom)}px;
  overflow: hidden;
  position: relative;
`;

EventCard.ResponseRadio = styled.input`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.01;
  z-index: 100;
  margin-right: ${({ zoom }) => Math.floor(30 * zoom)}px;

  &:checked + label {
    color: ${({ color }) => color};

    svg {
      path {
        fill: ${({ color }) => color};
      }
    }
  }
`;

EventCard.ResponseLabel = styled.label`
  display: block;
  cursor: pointer;
  z-index: 90;
  font-size: ${({ zoom }) => Math.floor(13 * zoom)}px;
  line-height: ${({ zoom }) => Math.floor(18 * zoom)}px;
  text-transform: uppercase;
  margin-left: ${({ zoom }) => Math.floor(10 * zoom)}px;
  transition: color 0.2s;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  white-space: break-spaces;
  max-width: ${({ zoom }) => Math.floor(180 * zoom)}px;

  &:hover {
    color: ${({ color }) => color};
  }

  svg {
    margin-bottom: ${({ zoom }) => Math.floor(5 * zoom)}px;
    margin-left: ${({ zoom }) => Math.floor(3 * zoom)}px;
    transform: scale(${({ zoom }) => zoom});
  }
`;

EventCard.Divider = styled.hr`
  border: none;
  border-top: 1px solid #eaf2f6;
  margin-top: ${({ zoom }) => Math.floor(15 * zoom)}px;
`;

EventCard.Footer = styled.div`
  margin-top: ${({ zoom }) => Math.floor(15 * zoom)}px;
  margin-bottom: ${({ zoom }) => Math.floor(15 * zoom)}px;
`;

const CommunityTitle = styled.p`
  font-size: ${({ zoom }) => Math.floor(16 * zoom)}px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: max-content;
  max-width: ${({ zoom }) => Math.floor(185 * zoom)}px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscal;
`;

const GroupTitle = styled.p`
  font-size: ${({ zoom }) => Math.floor(12 * zoom)}px;
  color: ${({ theme }) => theme.color.baliHai};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: ${({ zoom }) => Math.floor(185 * zoom)}px;
`;

const FilterBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  & > div {
    width: 100%;
  }

  & > div:last-of-type {
    width: auto;
    margin: 10px auto;
  }
`;

const FilterContainer = styled.div`
  height: 40px;
  position: relative;
  min-width: max-content;
  padding: 0 14px;
  border: 1px solid ${({ theme }) => theme.color.botticelli};
  border-radius: 4px;
`;

const ProfilesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const NoEventsPlaceholder = styled.div`
  height: calc(100svh - 100px);
  width: 100%;
  padding: 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 26px;
  color: ${({ theme }) => theme.color.brightTurquoise};
`;

const NoEventIcon = styled(CalendarIllustrate)`
  width: 80px;
  height: 80px;
  display: block;
  margin-bottom: 20px;
`;

const RepliesCount = styled.div`
  position: absolute;
  right: ${({ zoom }) => Math.floor(12 * zoom)}px;
  height: 100%;
  display: flex;
  align-items: center;
`;

const RepliesCountResponse = styled.div`
  position: relative;
  height: max-content;
  display: flex;
  align-items: center;
  margin-top: ${({ zoom }) => Math.floor(-2 * zoom)}px;

  & > span {
    transform: scale(${({ zoom }) => zoom});
    color: #fff;
    font-size: ${({ zoom }) => Math.floor(10 * zoom)}px;
    margin-right: ${({ zoom }) => Math.floor(5 * zoom)}px;
    width: ${({ zoom }) => 30 * zoom}px;
    display: flex;

    img {
      margin-right: ${({ zoom }) => Math.floor(4 * zoom)}px;
      object-fit: contain;
    }
  }
`;

const StyledFilterBox = styled.div`
  max-width: ${({ fillWidth }) => (fillWidth ? 'auto' : '380px')};
  background-color: white;
  min-width: ${({ widthByLetterSize }) => `${150 + widthByLetterSize * 7}px`};
  margin-right: 20px;
  @media ${breakpoints.downSmPlus} {
    min-width: 150px;
    width: 150px;
  }
  @media ${breakpoints.downSm} {
    margin-right: 0px;
  }

  @media ${breakpoints.downSmPlus} {
    position: sticky;
    left: 0;
  }
`;

const StyledFilterText = styled.div`
  position: relative;
  width: auto;
  top: 50%;
  transform: translateY(-50%);
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.baliHai};
  @media ${breakpoints.downSmPlus} {
    display: none;
  }
`;

const StyledIconsBlock = styled.div`
  position: absolute;
  top: 5px;
  right: 0px;
  display: flex;
`;

const StyledIcon = styled.div`
  cursor: pointer;
  margin: 0 8px;
  width: 30px;
  position: relative;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  border-radius: 50%;
  background: ${({ theme, selected }) =>
    selected ? theme.color.brightTurquoise : 'transparent'};
  transition: background 0.3s;

  padding-right: ${({ paddingRight }) =>
    paddingRight ? paddingRight : 'auto'};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : 'auto')};

  svg path {
    fill: ${({ theme, selected, star }) =>
      star
        ? 'rgb(247,225,82)'
        : selected && star
        ? 'rgb(247,225,82)'
        : selected
        ? 'white'
        : theme.color.brightTurquoise};
    transition: fill 0.3s;
  }
`;

const StyledMiddleIcon = styled.div`
  border-right: 1px solid ${({ theme }) => theme.color.botticelli};
  border-left: 1px solid ${({ theme }) => theme.color.botticelli};
`;

export {
  Layout,
  StyledFloatingLoader,
  Heading,
  TasksList,
  EventCard,
  CommunityTitle,
  GroupTitle,
  FilterBlock,
  ProfilesList,
  NoEventsPlaceholder,
  NoEventIcon,
  RepliesCount,
  RepliesCountResponse,
  FilterContainer,
  StyledFilterBox,
  StyledFilterText,
  StyledIconsBlock,
  StyledIcon,
  StyledMiddleIcon,
  StyledFilterBar,
};

import styled, { css } from 'styled-components';
import { DeleteIcon } from 'static';

export const StyledIcon = styled(DeleteIcon)`
  user-select: none;
`;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ outlined }) => (outlined ? '1px solid #c9d9e2' : 'none')};
  outline: none;
  cursor: pointer;
  background: none;
  color: ${({ theme }) => theme.color.baliHai};
  width: ${({ width }) => width || 'unset'};

  svg {
    min-width: 16px;
  }

  ${({ outlined }) =>
    outlined &&
    `
    width: 35px;
    height: 35px;
    border-radius: 50%;
    `};
  transition: transform 0.2s;

  ${({ withLabel }) =>
    withLabel &&
    css`
      display: flex;
      align-items: center;

      & > span {
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 32px;
        text-transform: uppercase;
        margin-left: 10px;
      }
    `}

  &:hover {
    ${({ outlined, theme }) =>
      outlined
        ? `
      transform: scale(1.2);
      border-color: ${theme.color.bittersweet};
      svg path {
        fill: ${theme.color.bittersweet}
      };
    `
        : `
    ${StyledIcon} {
      path {
        fill: ${({ theme }) => theme.color.pickledBluewood};
      }
    }
    span {
      color: ${({ theme }) => theme.color.pickledBluewood};
    }
    `}
  }

  &:active {
    ${StyledIcon} {
      path {
        fill: ${({ theme }) => theme.color.brightTurquoise};
      }
    }
    span {
      color: ${({ theme }) => theme.color.brightTurquoise};
    }
  }

  &:disabled {
    opacity: 40%;
  }
`;

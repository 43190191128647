import styled from 'styled-components';

export const StyledInputWrapper = styled.div``;

export const StyledInputBlock = styled.div`
  display: flex;
  align-items: flex-start;
  label {
    text-transform: capitalize;
  }
  & > div:first-of-type {
    width: 100%;
  }
`;

export const StyledDeleteBlock = styled.div`
  display: flex;
  margin-left: 10px;
  margin-top: 39px;
`;

import React, {
  ChangeEvent,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { useToasts } from 'react-toast-notifications';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  usePushMessageForm,
  FORMFIELDS,
  PushNotificationModuleFormValues,
} from './form';

import NotificationPreview from './Preview';
import { ContentWrapper, Divider } from 'components';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
  getIsMenuOpen,
  getMembersToGroupsLoading,
  getUserPushTemplateLoaders,
  getUserPushTemplates,
} from 'store/selectors/currentCommunity';
import { HeaderContainer, Header } from 'pages/EmailModule/Header/styled';
import { GridGhostWrapper, GridWrapper } from 'pages/EmailModule/styled';
import PushMessageRecipients from 'pages/EmailModule/Recipients';
import PushMessageSelectEvent from 'pages/EmailModule/SelectEvent';
import ConfirmPopup from './ConfirmPopup';
import { getMyEventsLoaders } from 'store/selectors/events';
import { getProfileName, hasCommunityOrGroupAdminPermissions } from 'utils';
import {
  doCreatePushTemplate,
  doDeletePushTemplate,
  doGetCommunityMembersByGroups,
  doGetPushTemplates,
  doSendPushNotification,
} from 'store/actionCreators/currentCommunity';
import { getAuthUser } from 'store/selectors/auth';

import {
  getRecipientsFilteredByReplies,
  handleRecipientsChange,
} from 'utils/emailAndPush';
import PushMessage from './Message';
import PushMessageTemplates from './Templates';
import { PushMessageContainer, ContentContainer } from './styled';
import { MyAppointmentsEvent } from 'types';
import { useMedia } from 'react-use';
import { breakpoints } from 'theme';
import { getEventResponses } from 'store/selectors/eventResponses';
import { useInfoPopup } from 'hooks';
import { getDisplayFormat, useHideImage } from './util';
import i18n from 'i18next';
import { staticTemplatedata } from './Templates/statickData';

const defaultValue: PushNotificationModuleFormValues = {
  title: '',
  message: '',
  recipients: [],
  sendCopyToMe: true,
  redirectLink: '',
};

const PushNotificationPage = () => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [reciapient, setReciapient] = useState<string[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<MyAppointmentsEvent>(null);
  const [eventResponsesFilter, setEventResponsesFilter] = useState<
    {
      [p: string]: any;
    }[]
  >([]);
  const isDownMdPlus = useMedia(breakpoints.downMdPlus);
  const [pushMessageView, setPushMessageView] = useState<
    undefined | 'single' | 'double' | 'triple'
  >(undefined);
  const pushSectionRef = useRef<HTMLDivElement>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const pushWrapperRef = useRef<HTMLDivElement | null>(null);
  const isMenuOpen = useSelector(getIsMenuOpen);
  const community = useSelector(getCurrentCommunity);
  const eventResponses = useSelector(getEventResponses);
  const loaders = useSelector(getMyEventsLoaders);
  const currentUser = useSelector(getCurrentUserInCommunity);
  const membersToGroupsLoading = useSelector(getMembersToGroupsLoading);
  const user = useSelector(getAuthUser);
  const templateList = useSelector(getUserPushTemplates);
  const userPushTemplateLoaders = useSelector(getUserPushTemplateLoaders);
  const { showInfoPopup } = useInfoPopup();
  const newRef = React.createRef<HTMLDivElement>();
  const defaultTemplate = useMemo(() => {
    return {
      communityId: community.id,
      userId: user.userId,
      messageTemplate: staticTemplatedata[i18n.language || 'en']
        .map((li: { userId: string; communityId: string }) => {
          return { ...li, redirectLink: '' };
        })
        .reverse(),
    };
  }, [i18n.language, community.id, user.userId]);

  useEffect(() => {
    const modalRefVal = pushWrapperRef.current;

    const handleOutSideClick = (event: MouseEvent) => {
      if (modalRefVal && modalRefVal.contains(event.target as Node)) {
        event.preventDefault();
        event.stopPropagation();
        addToast(t('errors.onlyForGroupAndAdmin'), {
          appearance: 'info',
          autoDismiss: true,
        });
      }
    };
    if (!hasCommunityOrGroupAdminPermissions(currentUser.id, community)) {
      document.addEventListener('mousedown', handleOutSideClick, true);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutSideClick);
    };
  }, []);

  useEffect(() => {
    dispatch(
      doGetCommunityMembersByGroups({
        communityId: community.id,
        withoutBlocked: true,
      }),
    );
    if (templateList?.length === 0) {
      dispatch(
        doGetPushTemplates({
          communityId: community.id,
          userId: user.userId,
          defaultTemp: defaultTemplate,
        }),
      );
    }
  }, []);

  useEffect(() => {
    const manageLayout = () => {
      if (newRef.current) {
        const curw = window.getComputedStyle(newRef.current).width;
        const showmore = getDisplayFormat(parseInt(curw));
        if (showmore !== pushMessageView) {
          setPushMessageView(showmore);
        }
      }
    };
    const setLayout = setTimeout(manageLayout, 600);

    return () => {
      clearTimeout(setLayout);
    };
  }, [pushMessageView, newRef]);

  const {
    recipientFieldProps,
    sendCopyFieldProps,
    urlFieldProps,
    watchedValues,
    errors,
    register,
    handleSubmit,
    clearErrors,
    control,
    getValues,
    setValue,
    trigger: triggerFormValidation,
  } = usePushMessageForm(defaultValue, selectedEvent, reciapient);

  useEffect(() => {
    if (recipientFieldProps) setReciapient(recipientFieldProps.value);
  }, [recipientFieldProps.value]);

  const hideImage = useHideImage({
    title: watchedValues[FORMFIELDS.TITLE],
    message: watchedValues[FORMFIELDS.MESSAGE],
  });

  const handleSendCopyChange = (e: ChangeEvent<HTMLInputElement>) => {
    sendCopyFieldProps.onChange(e.target.checked);
  };

  const onChangeRecipients = (data: string[]) => {
    handleRecipientsChange(data, community, recipientFieldProps);
  };

  const handleEventResponsesChange = (responses: { [p: string]: any }[]) => {
    if (selectedEvent) {
      const updatedRecipients = getRecipientsFilteredByReplies(
        selectedEvent,
        recipientFieldProps.value,
        responses,
      );

      setEventResponsesFilter(responses);
      onChangeRecipients(updatedRecipients);
    } else {
      showInfoPopup({
        title: t('pushNotification.selectEventFirst'),
        noButton: true,
        duration: 2000,
        size: 'md',
        type: 'warning',
        loop: false,
      });
    }
  };

  const handleEventClick = (event: MyAppointmentsEvent) => {
    if (!hasCommunityOrGroupAdminPermissions(currentUser.id ?? '', community)) {
      return;
    }

    if (event.id === selectedEvent?.id) {
      onChangeRecipients(
        recipientFieldProps.value.filter(
          (id) => !event.recipients.includes(id),
        ),
      );
      setValue(FORMFIELDS.REDIRECT_LINK, '');
      setValue(FORMFIELDS.TITLE, '');
      setEventResponsesFilter([]);
      return setSelectedEvent(null);
    }
    setValue(FORMFIELDS.REDIRECT_LINK, event.link);

    setValue(FORMFIELDS.TITLE, `${event.title.slice(0, 75)}`);
    const defaultAllFilterValue = community.eventResponses.map((response) => ({
      value: response.id,
      label: response.title,
    }));

    setEventResponsesFilter(
      [
        { value: 'awaiting', label: t('event.awaiting') },
        { value: 'penalty', label: t('event.penalty') },
        { value: 'attended', label: t('event.nomination') },
      ].concat(defaultAllFilterValue),
    );

    const updatedRecipients = getRecipientsFilteredByReplies(
      event,
      recipientFieldProps.value,
      eventResponsesFilter,
    );

    onChangeRecipients(updatedRecipients);
    setSelectedEvent(event);
  };

  const getUsersValues = (data) => {
    return data.map((id) => ({
      value: id,
      label: `${getProfileName(
        community.users.find((i) => i.id === id)?.profile || {},
      )} (${community.users.find((i) => i.id === id)?.profile?.email})`,
    }));
  };

  const getEventResponsesOptions = () =>
    [
      { value: 'awaiting', label: t('event.awaiting') },
      { value: 'penalty', label: t('event.penalty') },
      { value: 'attended', label: t('event.nomination') },
    ].concat(
      eventResponses.map((r) => ({
        value: r.id,
        label: r.title,
      })),
    );

  const getUsersOptions = (arr) =>
    arr.map((u) => ({
      value: u.id,
      label: `${getProfileName(u.profile)} (${u.profile?.email})`,
    }));

  const handleSaveTemplate = async () => {
    if (!hasCommunityOrGroupAdminPermissions(currentUser.id ?? '', community)) {
      return;
    }
    //templateList <= 8
    if (templateList?.length === 8) {
      showInfoPopup({
        title: t('pushNotification.templateLimitTitle'),
        message: t('pushNotification.templateLimitMessage'),
        loop: true,
        type: 'warning',
      });
      return;
    }
    const formValues = getValues();
    const onSuccess = () =>
      showInfoPopup({
        title: t('common.createSuccess.title'),
        message: t('common.createSuccess.message'),
        duration: 1500,
        noButton: true,
        loop: false,
      });
    const isValid = await triggerFormValidation([
      FORMFIELDS.TITLE,
      FORMFIELDS.MESSAGE,
    ]);

    if (!isValid) {
      return true;
    }

    dispatch(
      doCreatePushTemplate({
        communityId: community.id,
        userId: user.userId,
        title: formValues[FORMFIELDS.TITLE],
        message: formValues[FORMFIELDS.MESSAGE],
        onSuccess,
      }),
    );
  };

  const getRedirectUrlValue = () => {
    const formValues = getValues();
    return formValues?.[FORMFIELDS.REDIRECT_LINK] ?? '';
  };

  const onClickSend = async () => {
    const isValid = await triggerFormValidation();
    if (isValid) setShowPopup(true);
  };

  const handleRemoveTemplate = (id: string) => {
    if (!hasCommunityOrGroupAdminPermissions(currentUser.id ?? '', community)) {
      return;
    }

    const onSuccess = () =>
      showInfoPopup({
        title: t('common.deleteSuccess.title'),
        message: t('common.deleteSuccess.message'),
        duration: 1500,
        noButton: true,
        loop: false,
      });
    dispatch(
      doDeletePushTemplate({
        communityId: community.id,
        id,
        userId: user.userId,
        onSuccess,
      }),
    );
  };

  const onSubmit = async () => {
    if (!hasCommunityOrGroupAdminPermissions(currentUser.id ?? '', community)) {
      return;
    }
    handleSubmit(async (data) => {
      const finalData = {
        ...data,
        communityId: community.id,
        userId: user.userId,
        withImage: !hideImage,
        eventId: selectedEvent?.id ?? '',
      };
      try {
        dispatch(
          doSendPushNotification({
            data: finalData,
            onSuccess: () => {
              showInfoPopup({
                title: t('pushNotification.successTitle'),
                richMessage: t('pushNotification.successMessage', {
                  totalRecipients: getValues()[FORMFIELDS.RECIPIENTS].length,
                }),
                type: 'success',
                loop: false,
              });
              setShowPopup(false);
            },
          }),
        );
      } catch (err) {
        showInfoPopup({
          title: 'Something went wrong',
          message: 'Please try later',
          type: 'error',
          loop: false,
        });
      }
    })();
  };

  const handleOnWrapperClick = (event) => {
    event?.stopPropagation();
    event?.preventDefault();
  };

  return (
    <ContentWrapper isMenuOpen={isMenuOpen} minHeight={'auto'}>
      <HeaderContainer>
        <Header isMenuOpen={isMenuOpen}>{t('pushNotification.header')}</Header>
      </HeaderContainer>

      <ConfirmPopup
        isOpen={showPopup}
        title={watchedValues[FORMFIELDS.TITLE]}
        message={watchedValues[FORMFIELDS.MESSAGE]}
        recipients={recipientFieldProps.value}
        redirectLink={watchedValues[FORMFIELDS.REDIRECT_LINK]}
        onClose={() => {
          setShowPopup(false);
        }}
        onSubmitHandler={onSubmit}
        isSendingMessage={userPushTemplateLoaders?.sendPushNotification}
      />

      <GridWrapper ref={pushWrapperRef}>
        {/* <div>user list component here</div> */}
        {!isDownMdPlus && (
          <PushMessageRecipients
            currentUserId={currentUser.id ?? ''}
            handleOnWrapperClick={handleOnWrapperClick}
            community={community}
            users={community.users}
            onChange={onChangeRecipients}
            selectedRecipients={recipientFieldProps.value}
            membersToGroupsLoading={membersToGroupsLoading}
            membersByGroups={community.membersByGroups}
          />
        )}
        <ContentContainer>
          <PushMessageSelectEvent
            currentUserId={currentUser.id ?? ''}
            handleOnWrapperClick={handleOnWrapperClick}
            community={community}
            isLoading={loaders.events}
            isMenuOpen={isMenuOpen}
            onEventResponsesChange={handleEventResponsesChange}
            eventResponsesFilter={eventResponsesFilter}
            selectedEvent={selectedEvent}
            onEventClick={handleEventClick}
            withSelectInput={false}
          />
          <Divider customMargin="0px 0px 20px 0px" />
          <PushMessageContainer ref={newRef} pushMessageView={pushMessageView}>
            {!hasCommunityOrGroupAdminPermissions(
              currentUser.id ?? '',
              community,
            ) && <GridGhostWrapper onClick={(e) => handleOnWrapperClick(e)} />}
            <PushMessage
              urlValue={urlFieldProps.value}
              options={getUsersOptions(community.users)}
              eventResponsesFilter={eventResponsesFilter}
              redirectLink={watchedValues[FORMFIELDS.REDIRECT_LINK]}
              control={control}
              errors={errors}
              onSendCopyChange={handleSendCopyChange}
              onSubmit={onClickSend}
              sendCopyToMe={sendCopyFieldProps.value}
              selectedRecipients={recipientFieldProps.value}
              setSelectedParticipants={onChangeRecipients}
              sending={false}
              register={register}
              eventResponseOptions={getEventResponsesOptions()}
              handleEventResponsesChange={handleEventResponsesChange}
              pushMessageView={pushMessageView}
              handleSaveTemplate={handleSaveTemplate}
              recipientFieldProps={recipientFieldProps}
              saveTemplateLoader={userPushTemplateLoaders.createTemplate}
            />
            <NotificationPreview
              title={watchedValues[FORMFIELDS.TITLE]}
              message={watchedValues[FORMFIELDS.MESSAGE]}
              selectedRecipients={recipientFieldProps.value}
              errors={errors}
              onSendCopyChange={handleSendCopyChange}
              register={register}
              currentView={pushMessageView}
              isSelected={sendCopyFieldProps.value}
              urlValue={urlFieldProps.value}
            />
            <PushMessageTemplates
              selectedRecipients={recipientFieldProps.value}
              errors={errors}
              onSendCopyChange={handleSendCopyChange}
              register={register}
              setValue={setValue}
              pushMessageView={pushMessageView}
              isSelected={sendCopyFieldProps.value}
              templateList={templateList}
              handleRemove={handleRemoveTemplate}
              getRedirectUrlValue={getRedirectUrlValue}
              urlValue={urlFieldProps.value}
            />
          </PushMessageContainer>
        </ContentContainer>
      </GridWrapper>
    </ContentWrapper>
  );
};

export default PushNotificationPage;

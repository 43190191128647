import styled from 'styled-components';

import { EditIcon } from 'static';

export const StyledIcon = styled(EditIcon)`
  user-select: none;
`;

export const StyledButton = styled.button`
  display: inline-block;
  border: ${({ outlined }) => (outlined ? '1px solid #33ABA7' : 'none')};
  outline: none;
  cursor: pointer;
  background: ${({ theme }) => theme.color.brightTurquoise};
  transition: all 0.3s ease-in-out;
  ${({ outlined, theme }) =>
    outlined &&
    `
    width: 36px;
    height: 36px;
    border-radius: 50%;
    padding-top: 2px;
    svg {
      width: 16px;
      height: 16px;
      align-self: center;
      justiy-self: center;
      path {
        fill: ${theme.color.pickledBluewood}
      };
    }
    `};
  transition: transform 0.2s;
  &:active:enabled {
    background: ${({ contained }) => contained && '#33aba7c4'};
  }
  &:hover {
    ${({ outlined, theme }) =>
      outlined
        ? `
      transform: scale(1.2);
      border-color: #33aba7c4;
      svg path {
        fill: ${theme.color.white}
      };
    `
        : `
    ${StyledIcon} {
      path {
        fill: ${({ theme }) => theme.color.white};
      }
    }
    span {
      color: ${({ theme }) => theme.color.white};
    }
    `};
  }

  &:active {
    ${StyledIcon} {
      path {
        fill: ${({ theme }) => theme.color.white};
      }
    }
    span {
      color: ${({ theme }) => theme.color.white};
    }
  }

  &:disabled {
    opacity: 40%;
  }
`;

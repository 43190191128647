import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from 'store/actionCreators/modal';
import {
  doCreateCommunityResponse,
  doEditCommunityResponse,
  doDeleteCommunityResponse,
} from 'store/actionCreators/currentCommunity';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { useEditCommunityResponseForm } from './form';
import { useInfoPopup } from 'hooks';
import { EventResponse } from 'types';

export const useEditCommunityResponse = (eventResponse: EventResponse) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const community = useSelector(getCurrentCommunity);
  const { showInfoPopup } = useInfoPopup();

  const { handleSubmit, ...form } = useEditCommunityResponseForm(eventResponse);

  const onSuccess = (title, message?: string) => {
    dispatch(closeModal());
    showInfoPopup({
      type: 'success',
      title,
      message,
      duration: 3000,
      loop: false,
      noButton: true,
    });
  };

  const onSuccessDelete = () => {
    dispatch(closeModal());
    showInfoPopup({
      type: 'success',
      title: t('common.deleteSuccess.title'),
      message: t('common.deleteSuccess.message'),
      duration: 3000,
      loop: false,
      noButton: true,
    });
  };

  const onDelete = () =>
    dispatch(
      doDeleteCommunityResponse({
        communityId: community.id,
        id: eventResponse.id,
        onSuccess: onSuccessDelete,
      }),
    );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      handleSubmit((data) => {
        if (eventResponse) {
          dispatch(
            doEditCommunityResponse({
              communityId: community.id,
              data,
              id: eventResponse.id,
              onSuccess: () =>
                onSuccess(
                  t('common.editSuccess.title'),
                  t('common.editSuccess.message'),
                ),
            }),
          );
        } else {
          dispatch(
            doCreateCommunityResponse({
              communityId: community.id,
              data,
              onSuccess: () =>
                onSuccess(
                  t('common.createSuccess.title'),
                  t('common.createSuccess.message'),
                ),
            }),
          );
        }
      })();
    },
    [eventResponse],
  );

  return {
    ...form,
    onSubmit,
    onDelete,
  };
};

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EventType } from 'types';

export enum FIELDS {
  TITLE = 'title',
  COLOR = 'color',
  IS_RESPONSE_REQUIRED = 'isResponseRequired',
}

export type EditCommunityEventTypeFormData = {
  [FIELDS.TITLE]: string;
  [FIELDS.COLOR]: string;
  [FIELDS.IS_RESPONSE_REQUIRED]: boolean;
};

export const useEditCommunityEventTypeForm = (eventType: EventType) => {
  const { t } = useTranslation();

  yup.setLocale({
    mixed: { required: t('errors.required') },
    string: {
      min: ({ min }) => t('errors.min', { value: min }),
      max: ({ max }) => t('errors.max', { value: max }),
    },
  });

  const resolver: Resolver<EditCommunityEventTypeFormData> = yupResolver(
    yup.object().shape({
      [FIELDS.TITLE]: yup.string().required().max(50).min(3).trim(),
      [FIELDS.COLOR]: yup.string().required().trim(),
      [FIELDS.IS_RESPONSE_REQUIRED]: yup.boolean().required(),
    }),
  );

  return useForm<EditCommunityEventTypeFormData>({
    resolver,
    defaultValues: eventType || {
      [FIELDS.TITLE]: '',
      [FIELDS.COLOR]: '#10CCC6',
      [FIELDS.IS_RESPONSE_REQUIRED]: false,
    },
  });
};

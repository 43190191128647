import { axiosInstance, axiosInstanceArrayBuffer } from './index';
import { Moment } from 'moment';

export const getAppointmentScheduleStatistics = async ({
  communityId,
  from,
  to,
  eventTypeIds,
  groupIds,
  visibleColumns,
  page,
  limit,
  excludeCommunityEvents,
}: {
  communityId: string;
  from: Moment;
  to: Moment;
  eventTypeIds?: string[];
  groupIds?: string[];
  visibleColumns: string[];
  page: number;
  limit: number;
  excludeCommunityEvents?: boolean;
}) => {
  const response = await axiosInstance.post(
    `/communities/${communityId}/appointmentSchedule`,
    {
      from: from.format(),
      to: to.format(),
      eventTypeIds,
      groupIds,
      visibleColumns,
      page,
      limit,
      excludeCommunityEvents,
    },
  );

  return response.data;
};

export const getAppointmentScheduleStatisticsTotal = async ({
  communityId,
  from,
  to,
  eventTypeIds,
  groupIds,
  visibleColumns,
  excludeCommunityEvents,
}: {
  communityId: string;
  from: Moment;
  to: Moment;
  eventTypeIds?: string[];
  groupIds?: string[];
  visibleColumns: string[];
  excludeCommunityEvents?: boolean;
}) => {
  const response = await axiosInstance.post(
    `/communities/${communityId}/appointmentScheduleTotal`,
    {
      from: from.format(),
      to: to.format(),
      eventTypeIds,
      groupIds,
      visibleColumns,
      excludeCommunityEvents,
    },
  );

  return response.data;
};

export const getAppointmentScheduleStatisticsXlsx = async ({
  communityId,
  from,
  to,
  eventTypeIds,
  groupIds,
  visibleColumns,
  excludeCommunityEvents,
}: {
  communityId: string;
  from: Moment;
  to: Moment;
  eventTypeIds?: string[];
  groupIds?: string[];
  visibleColumns: string[];
  excludeCommunityEvents?: boolean;
}) => {
  const response = await axiosInstanceArrayBuffer.post(
    `/communities/${communityId}/appointmentScheduleXlsx`,
    {
      from: from.format(),
      to: to.format(),
      eventTypeIds,
      groupIds,
      visibleColumns,
      excludeCommunityEvents,
    },
    // {
    //   responseType: 'blob',
    // },
  );

  return response.data;
};

export const getAppointmentScheduleStatisticsCsv = async ({
  communityId,
  from,
  to,
  eventTypeIds,
  groupIds,
  visibleColumns,
  excludeCommunityEvents,
}: {
  communityId: string;
  from: Moment;
  to: Moment;
  eventTypeIds?: string[];
  groupIds?: string[];
  visibleColumns: string[];
  excludeCommunityEvents?: boolean;
}) => {
  const response = await axiosInstance.post(
    `/communities/${communityId}/appointmentScheduleCsv`,
    {
      from: from.format(),
      to: to.format(),
      eventTypeIds,
      groupIds,
      visibleColumns,
      excludeCommunityEvents,
    },
    {
      responseType: 'blob',
    },
  );

  return response.data;
};

export const getMemberFiguresStatistics = async ({
  communityId,
  from,
  to,
  eventTypeIds,
  groupIds,
  memberIds,
  excludeCommunityEvents,
  page,
  limit,
}: {
  communityId: string;
  from: Moment;
  to: Moment;
  eventTypeIds?: string[];
  groupIds?: string[];
  memberIds?: string[];
  excludeCommunityEvents?: boolean;
  page: number;
  limit: number;
}) => {
  const response = await axiosInstance.post(
    `/communities/${communityId}/memberFigures`,
    {
      from: from.format(),
      to: to.format(),
      eventTypeIds,
      groupIds,
      memberIds,
      page,
      limit,
      excludeCommunityEvents,
    },
  );

  return response.data;
};

export const getMemberFiguresStatisticsTotal = async ({
  communityId,
  from,
  to,
  eventTypeIds,
  groupIds,
  memberIds,
  excludeCommunityEvents,
}: {
  communityId: string;
  from: Moment;
  to: Moment;
  eventTypeIds?: string[];
  groupIds?: string[];
  memberIds?: string[];
  excludeCommunityEvents?: boolean;
}) => {
  const response = await axiosInstance.post(
    `/communities/${communityId}/memberFiguresTotal`,
    {
      from: from.format(),
      to: to.format(),
      eventTypeIds,
      groupIds,
      memberIds,
      excludeCommunityEvents,
    },
  );

  return response.data;
};

export const getMemberFiguresStatisticsXlsx = async ({
  communityId,
  from,
  to,
  eventTypeIds,
  groupIds,
  memberIds,
  excludeCommunityEvents,
}: {
  communityId: string;
  from: Moment;
  to: Moment;
  eventTypeIds?: string[];
  groupIds?: string[];
  memberIds?: string[];
  excludeCommunityEvents?: boolean;
}) => {
  const response = await axiosInstance.post(
    `/communities/${communityId}/memberFiguresXlsx`,
    {
      from: from.format(),
      to: to.format(),
      eventTypeIds,
      groupIds,
      memberIds,
      excludeCommunityEvents,
    },
  );

  return response.data;
};

export const getMemberFiguresStatisticsCsv = async ({
  communityId,
  from,
  to,
  eventTypeIds,
  groupIds,
  memberIds,
  excludeCommunityEvents,
}: {
  communityId: string;
  from: Moment;
  to: Moment;
  eventTypeIds?: string[];
  groupIds?: string[];
  memberIds?: string[];
  excludeCommunityEvents?: boolean;
}) => {
  const response = await axiosInstance.post(
    `/communities/${communityId}/memberFiguresCsv`,
    {
      from: from.format(),
      to: to.format(),
      eventTypeIds,
      groupIds,
      memberIds,
      excludeCommunityEvents,
    },
  );

  return response.data;
};

import React, { FC } from 'react';
import { Control, FieldError, useController } from 'react-hook-form';

import {
  StyledBlock,
  StyledInput,
  StyledCheckbox,
  StyledErrorMessage,
} from './styled';

interface CheckBoxV2Props {
  label?: string | JSX.Element;
  disabled?: boolean;
  name: string;
  error?: FieldError;
  width?: string;
  indeterminate?: boolean;
  labelStyles?: { [key: string]: any };
  zoom?: number;
  control: Control;
  onClick?: () => void;
}

const CheckBoxV2: FC<CheckBoxV2Props> = ({
  label = '',
  disabled = false,
  name,
  error,
  width,
  indeterminate,
  labelStyles,
  zoom = 1,
  control,
  onClick,
}) => {
  const { field } = useController({
    name,
    control,
  });

  return (
    <StyledBlock
      className={disabled && 'disabled'}
      width={width}
      labelStyles={labelStyles}
      zoom={zoom}
      onClick={onClick}
    >
      <StyledInput
        type="checkbox"
        checked={field.value}
        disabled={disabled}
        onChange={(e) => field.onChange(e.target.checked)}
        indeterminate={indeterminate}
        zoom={zoom}
      />
      <StyledCheckbox zoom={zoom} />
      {label}
      {error && (
        <StyledErrorMessage zoom={zoom}>{error.message}</StyledErrorMessage>
      )}
    </StyledBlock>
  );
};

export default CheckBoxV2;

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from 'store/actionCreators/modal';
import {
  doCreateCommunityEventType,
  doEditCommunityEventType,
  doDeleteCommunityEventType,
} from 'store/actionCreators/currentCommunity';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { useEditCommunityEventTypeForm } from './form';
import { useInfoPopup } from 'hooks';
import { EventType } from 'types';

export const useEditCommunityEventType = (eventType: EventType) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const community = useSelector(getCurrentCommunity);
  const { showInfoPopup } = useInfoPopup();
  const { handleSubmit, ...form } = useEditCommunityEventTypeForm(eventType);

  const onSuccess = (title, message?: string) => {
    dispatch(closeModal());
    showInfoPopup({
      type: 'success',
      title,
      message,
      duration: 3000,
      noButton: true,
      loop: false,
    });
  };

  const onSuccessDelete = () => {
    dispatch(closeModal());
    showInfoPopup({
      type: 'success',
      title: t('common.deleteSuccess.title'),
      message: t('common.deleteSuccess.message'),
      duration: 3000,
      noButton: true,
      loop: false,
    });
  };

  const onDelete = () =>
    dispatch(
      doDeleteCommunityEventType({
        communityId: community.id,
        id: eventType.id,
        onSuccess: onSuccessDelete,
      }),
    );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      handleSubmit((data) => {
        if (eventType) {
          dispatch(
            doEditCommunityEventType({
              communityId: community.id,
              data,
              id: eventType.id,
              onSuccess: () =>
                onSuccess(
                  t('common.editSuccess.title'),
                  t('common.editSuccess.message'),
                ),
            }),
          );
        } else {
          dispatch(
            doCreateCommunityEventType({
              communityId: community.id,
              data,
              onSuccess: () =>
                onSuccess(
                  t('common.createSuccess.title'),
                  t('common.createSuccess.message'),
                ),
            }),
          );
        }
      })();
    },
    [eventType],
  );

  return {
    onDelete,
    onSubmit,
    ...form,
  };
};

import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { decodeJwt } from 'jose';
import validate from 'uuid-validate';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ColumnInfoBlock,
  ProfileImagePlaceholder,
  SocialLinkButton,
} from 'components';
import { capMap, getDate, getNameFromEmail } from 'utils';
import { ROUTES, PROFILE_ROUTES } from 'configs';
import {
  getAuthUser,
  getFullAuthUser,
  getMyId,
} from '../../../store/selectors/auth';

import {
  Container,
  ProfileWrapper,
  LinkEmail,
  ColumWrapper,
  Title,
  Description,
  SocialInfoContainer,
  MainInfoBlock,
  HeadInfoContainerWrapper,
  HeadInfoContainer,
  UserImage,
  ProfileName,
  SocialLinks,
  DescriptionContainer,
  UpdateBtnWrapper,
  BtnTitle,
} from './styled';
import { FIELDS } from 'sidebars/EditProfile/form';
import { phoneData } from 'configs/phones';
import { socialNetwork } from 'configs/socialNetworks';
import { CameraIcon, Redirect } from 'static';
import Linkify from 'react-linkify';
import { getProfileNameCapital } from 'utils/common';

const Content = ({ openEditSidebar, userRole }) => {
  const { t } = useTranslation();
  const user = useSelector(getFullAuthUser);
  const [socialLinks, setSocialLinks] = useState([]);
  const profile = useSelector(getAuthUser);
  const authId = useSelector(getMyId);
  const itsMe = authId === profile.userId;
  const socialData = useMemo(() => {
    return socialNetwork
      .map((li) => {
        if (![...(profile?.links || [])].some(({ label }) => label === li)) {
          return { label: li, href: '' };
        }
      })
      .filter(Boolean);
  }, [socialNetwork, profile?.links]);

  useEffect(() => {
    setSocialLinks(socialData);
  }, [socialData]);
  const decoded = decodeJwt(user?.idToken);
  const canChangeCredentials = decoded && validate(decoded['cognito:username']);
  const phoneNumberTypes = [
    'privatePhoneNumber',
    'officePhoneNumber',
    'mobilePhoneNumber',
  ];
  const extraStyle = {
    textTransform: 'capitalize',
  };
  const phoneNumbers = phoneNumberTypes.map((label) => ({
    label,
    value: user?.profile.phones?.find((p) => p.label === label)?.value || '-',
  }));
  const btnExtraStyle = {
    borderRadius: '999999px',
    width: '46%',
    minWidth: '320px',
    maxWidth: '700px',
    color: 'black',
  };

  return (
    <Container>
      <HeadInfoContainerWrapper>
        <HeadInfoContainer>
          <MainInfoBlock left={profile.description}>
            <SocialInfoContainer>
              {profile.logo ? (
                <UserImage src={profile.logo} onClick={openEditSidebar} />
              ) : (
                <ProfileImagePlaceholder
                  icon={<CameraIcon />}
                  onClick={openEditSidebar}
                />
              )}
            </SocialInfoContainer>
            <SocialInfoContainer>
              <ProfileName>
                {getProfileNameCapital({
                  firstName: profile.firstName,
                  lastName: profile.lastName,
                }) || getNameFromEmail(profile.email)}
              </ProfileName>
              <SocialLinks>
                {capMap(
                  ({ value, label }) => (
                    <SocialLinkButton
                      key={value}
                      openEditSidebar={openEditSidebar}
                      href={value}
                      socialNetwork={label}
                      width={value ? '18px' : '14px'}
                      userRole={userRole}
                      itsMe={itsMe}
                    />
                  ),
                  [...(profile?.links || []), ...(socialLinks || [])],
                )}
              </SocialLinks>
            </SocialInfoContainer>
          </MainInfoBlock>
          {profile.description && (
            <DescriptionContainer hasDescription={profile.description}>
              {profile.description ? (
                <Linkify>
                  <Description>{profile.description}</Description>
                </Linkify>
              ) : (
                <></>
              )}
            </DescriptionContainer>
          )}
        </HeadInfoContainer>
        <UpdateBtnWrapper>
          <Button onClick={openEditSidebar} style={btnExtraStyle}>
            <>
              <Redirect />
              <BtnTitle>{t('profile.profileEditBtn')}</BtnTitle>
            </>
          </Button>
        </UpdateBtnWrapper>
      </HeadInfoContainerWrapper>
      <ProfileWrapper>
        <Title>
          <p>{t('profile.basicInformation')}</p>
        </Title>
        <ColumWrapper>
          <ColumnInfoBlock
            addMaxWidth
            label={t('profile.firstName')}
            text={
              getProfileNameCapital({
                firstName: user.profile.firstName,
              }) as string
            }
            // style={extraStyle}
            name={FIELDS.FIRST_NAME}
          />
          <ColumnInfoBlock
            addMaxWidth
            label={t('profile.lastName')}
            name={FIELDS.LAST_NAME}
            // style={extraStyle}
            text={
              getProfileNameCapital({
                lastName: user.profile.lastName,
              }) as string
            }
            // text={user.profile.lastName}
          />
        </ColumWrapper>
        <ColumWrapper>
          <ColumnInfoBlock
            addMaxWidth
            label={t('profile.profileName')}
            text={user.profile.profileName}
            name={FIELDS.PROFILE_NAME}
          />
          <ColumnInfoBlock
            addMaxWidth
            label={t('profile.dateOfBirth')}
            text={getDate(user.profile.dob)}
            name={FIELDS.DATE_OF_BIRTH}
          />
        </ColumWrapper>
        <Title>
          <p style={{ marginTop: '32px' }}>{t('profile.contactInformation')}</p>
        </Title>
        <ColumWrapper>
          <ColumnInfoBlock
            addMaxWidth
            label={
              <>
                {t('profile.email')}{' '}
                {canChangeCredentials && (
                  <LinkEmail
                    to={`${ROUTES.PROFILE}/${PROFILE_ROUTES.SETTINGS}#update-email`}
                  >
                    {t('profile.linkChangeEmail')}
                  </LinkEmail>
                )}
              </>
            }
            text={user.profile.email}
          />
          {capMap(
            ({ value, label }, index) => (
              <ColumnInfoBlock
                addMaxWidth
                key={label}
                label={t(`profile.${label}`)}
                text={value}
                name={FIELDS[phoneData[index]]}
              />
            ),
            phoneNumbers,
          )}
        </ColumWrapper>
        <Title>
          <p style={{ marginTop: '32px' }}>{t('profile.addressInformation')}</p>
        </Title>
        <ColumWrapper>
          <ColumnInfoBlock
            addMaxWidth
            label={t('profile.street')}
            text={user.profile.street}
            name={FIELDS.STREET}
            style={extraStyle}
          />
          <ColumnInfoBlock
            label={t('profile.region')}
            text={user.profile.region}
            name={FIELDS.REGION}
            addMaxWidth
            style={extraStyle}
          />
          <ColumnInfoBlock
            addMaxWidth
            label={t('profile.postalCode')}
            text={user.profile.postalCode}
            name={FIELDS.POSTAL_CODE}
          />
          <ColumnInfoBlock
            addMaxWidth
            label={t('profile.city')}
            name={FIELDS.CITY}
            text={user.profile.city}
            style={extraStyle}
          />
          <ColumnInfoBlock
            addMaxWidth
            label={t('profile.country')}
            text={user.profile.country}
            name={FIELDS.COUNTRY}
          />
        </ColumWrapper>
      </ProfileWrapper>
    </Container>
  );
};

export default Content;

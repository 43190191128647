import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash/fp';

import { TabHeader, TabList, ContentPlaceholder, Loader } from 'components';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { getEventTypes } from 'store/selectors/eventTypes';
import { useEventTypes } from './hooks';
import { TabListContainer } from '../styled';

const EventTypesPage = () => {
  const { t } = useTranslation();
  const community = useSelector(getCurrentCommunity);
  const eventTypes = useSelector(getEventTypes);
  const filteredEventTypes = eventTypes?.filter(
    (eventType) => eventType?.deletedAt === null,
  );

  const { onAdd, onEdit, onDelete } = useEventTypes(community.id);

  return filteredEventTypes ? (
    <>
      <TabHeader
        title={t('communitySettings.tabs.eventTypes')}
        onAdd={onAdd}
        text={t('common.toolTipText.eventType')}
      />
      {!isEmpty(eventTypes) ? (
        <TabListContainer>
          <TabList
            items={filteredEventTypes}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </TabListContainer>
      ) : (
        <ContentPlaceholder />
      )}
    </>
  ) : (
    <Loader />
  );
};

export default EventTypesPage;

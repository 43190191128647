import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';

import newIconAnimation from 'static/animations/new.json';

import { CloseButton, Button } from 'components';

import { Wrapper } from '../FeaturePopup/styled';
import PushNotificationImage from 'static/images/push-notification-en.png';
import PushNotificationImageGerman from 'static/images/push-notification-de.png';
import { Image } from 'components/InfoPopup/styled';
import {
  StyledBody,
  StyledHeader,
  StyledPopup,
  StyledFooter,
  StyledAnchorTag,
} from './styled';
import { COMMUNITY_ROUTES, ROUTES } from 'configs';
import { getCurrentCommunity } from 'store/selectors/currentCommunity';
import { useSelector } from 'react-redux';

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

const PushNotificationFeaturePopup: React.FC<Props> = ({ onClose, isOpen }) => {
  const community = useSelector(getCurrentCommunity);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isGerman = i18n.language === 'de';
  const pushSupportPageUrl =
    i18n.language === 'de'
      ? 'https://gumb.app/support/erstellen-einer-gumb-push-nachricht/'
      : 'https://gumb.app/en/support/create-a-gumb-push-message/';
  const imageUrl =
    i18n.language === 'de'
      ? PushNotificationImageGerman
      : PushNotificationImage;
  const handleClick = () => {
    navigate(
      `${ROUTES.COMMUNITY}/${community.id}/${COMMUNITY_ROUTES.PUSH_NOTIFICATION}`,
      { replace: true },
    );
    onClose();
  };
  const buttonStyle = {
    textTransform: 'none',
    fontSize: '16px',
  };

  const animationOptions = {
    animationData: newIconAnimation,
    loop: true,
    autoplay: true,
  };

  return (
    <>
      {createPortal(
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
        /* @ts-ignore*/
        <AnimatePresence>
          {isOpen && (
            <Wrapper
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <StyledPopup>
                <CloseButton onClick={onClose} />
                <PerfectScrollbar
                  options={{
                    wheelSpeed: 0.1,
                    wheelPropagation: false,
                    minScrollbarLength: 4,
                  }}
                >
                  <StyledHeader>
                    <div style={{ position: 'relative', bottom: '0px' }}>
                      <Image
                        src={imageUrl}
                        style={{ border: 'none', marginBottom: '0px' }}
                        width="70%"
                      />
                    </div>
                  </StyledHeader>
                  <StyledBody>
                    <h3>
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '8px',
                          justifyContent: 'center',
                        }}
                      >
                        <Lottie
                          options={animationOptions}
                          width="48px"
                          height="48px"
                          style={{ margin: '0px' }}
                        />
                      </span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('featurePopups.pushNotifications.title'),
                        }}
                      ></span>
                    </h3>

                    <p
                      dangerouslySetInnerHTML={{
                        __html: t(
                          'featurePopups.pushNotifications.newMessageOne',
                        ),
                      }}
                    ></p>
                    <span>
                      <StyledAnchorTag
                        target="_blank"
                        marginRight="1px"
                        display="inline"
                        href={pushSupportPageUrl}
                      >
                        {t('featurePopups.pushNotifications.redirectLink')}
                      </StyledAnchorTag>
                      <p
                        style={{ display: 'inline' }}
                        dangerouslySetInnerHTML={{
                          __html: t(
                            'featurePopups.pushNotifications.newMessageTwo',
                          ),
                        }}
                      ></p>
                    </span>
                    <span
                      style={{
                        marginTop: '16px',
                        fontWeight: 'normal',
                        color: '#304569',
                        display: isGerman ? 'flex' : 'auto',
                        flexDirection: isGerman ? 'row-reverse' : 'row',
                      }}
                    ></span>
                  </StyledBody>
                  <StyledFooter>
                    <Button
                      variant="secondary"
                      onClick={onClose}
                      buttonStyles={buttonStyle}
                      {...{ rounded: true }}
                    >
                      {t('featurePopups.pushNotifications.cancelButton')}
                    </Button>
                    <Button
                      onClick={handleClick}
                      buttonStyles={{ ...buttonStyle, color: 'black' }}
                      {...{ rounded: true }}
                    >
                      {t('featurePopups.pushNotifications.okButton')}
                    </Button>
                  </StyledFooter>
                </PerfectScrollbar>
              </StyledPopup>
            </Wrapper>
          )}
        </AnimatePresence>,
        document.getElementById('modal'),
      )}
    </>
  );
};

export default PushNotificationFeaturePopup;

import styled from 'styled-components';

import { breakpoints } from 'theme';

export const SidebarFooter = styled.div`
  width: 100%;
  height: 61px;
  position: absolute;
  padding-right: 17px;
  padding-left: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  border-top: ${({ theme }) => `1px solid ${theme.color.porcelain}`};
  background-color: white;
  z-index: 100;

  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden scroll;
  height: ${({ fullHeight, height }) =>
    fullHeight
      ? 'calc(100svh - 51px)'
      : height
      ? height
      : 'calc(100svh - 190px)'};
  @media ${breakpoints.downSmPlus} {
    height: ${({ fullHeight, smHeight }) =>
      fullHeight
        ? 'calc(100svh - 51px)'
        : smHeight
        ? smHeight
        : 'calc(100svh - 190px)'};};
  }
`;

export const SidebarInputsWrapper = styled.div`
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 20px;

  @media ${breakpoints.downSmPlus} {
    padding-bottom: 100px;
  }
`;
export const SwitcherWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.color.botticelli};
  gap: 10px;
  border-radius: 4px;
`;
export const SidebarImageInputWrapper = styled.div`
  padding-top: 26px;
  padding-bottom: 26px;
`;

export const OverflowSidebarContent = styled(SidebarContent)`
  overflow: auto;
  height: calc(100svh - 112px);

  @media ${breakpoints.downSmPlus} {
    padding-bottom: 100px;
  }
`;

export const OverflowSidebarContentWithTabs = styled(SidebarContent)`
  @media ${breakpoints.downSmPlus} {
    height: calc(100svh - 155px);
    padding-bottom: 100px;
  }
`;

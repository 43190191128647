import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ExportSampleCsv from './ExportSampleCsv';
import ImportData from './ImportData';
import PreviewBeforeSend from './Preview';
import UploadSuccess from './UploadSuccess';
import { Loader } from 'components';
import Steps from 'components/Steps';
import { Community, Group } from 'types';
import { COMMUNITY_ROUTES, ROUTES } from 'configs';
import { getAuthUser } from 'store/selectors/auth';
import { ContentWrapper, SectionWrapper } from './styles';
import {
  getBulkUploadLoader,
  getBulkUploadStatus,
  getCurrentUserInCommunity,
} from 'store/selectors/currentCommunity';
import {
  doClearEventUploadStatus,
  doCreateBulkEvents,
  doGetEventUploadStatus,
} from 'store/actionCreators/currentCommunity/events';
import { uploadGroupBulkEvents, uploadBulkEvents } from 'services/events';
import { hasCommunityOrGroupAdminPermissions } from 'utils';
import { useToasts } from 'react-toast-notifications';
import { doGetCommunityMembersByGroups } from 'store/actionCreators/currentCommunity';

type Props = {
  headerHeight?: number;
  communityDetails: Community;
  groupDetails?: Group;
};

export type EventData = {
  title: string;
  description?: string;
  to: string;
  from: string;
  // communityId?: string;
  // groupId?: string;
  location: string;
  allDay: boolean;
  recipients: Array<string>;
  tasks: Array<string>;
  documents: Array<string>;
  isRepeated: boolean;
  notfiy: { toNotify: boolean; notifyText: string };
  eventSeriesId: string;
  // eventTypeId: string;
  eventTypeName: string;
  // userId?: string;
};

const ImportCsv: React.FC<Props> = ({
  headerHeight,
  communityDetails,
  groupDetails,
}) => {
  const { t } = useTranslation();
  const [participants, setParticipants] = useState<Array<string>>([]);
  const [eventData, setEventData] = useState<EventData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [uniqueEventTypes, setUniqueEventTypes] = useState<string[]>([]);
  const currentUser = useSelector(getCurrentUserInCommunity);
  const [validationSuccess, setValidationSuccess] = useState<boolean>(false);
  const [fileData, setFileData] = useState<File>(null);
  const userProfile = useSelector(getAuthUser);
  const isLoading = useSelector(getBulkUploadLoader);
  const bulkUploadStatus = useSelector(getBulkUploadStatus);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [overrideCurrentStep, setOverrideCurrentStep] = useState<number>(0);
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      doGetCommunityMembersByGroups({
        communityId: communityDetails.id,
        withoutBlocked: true,
      }),
    );
  }, []);

  useEffect(() => {
    if (hasCommunityOrGroupAdminPermissions(currentUser.id, communityDetails)) {
      if (groupDetails && groupDetails.id) {
        dispatch(
          doGetEventUploadStatus({
            communityId: communityDetails.id,
            groupId: groupDetails.id,
          }),
        );
      } else {
        dispatch(doGetEventUploadStatus({ communityId: communityDetails.id }));
      }
    }

    return () => {
      dispatch(doClearEventUploadStatus());
    };
  }, [groupDetails?.id, communityDetails?.id]);

  const goToScheduler = async () => {
    if (groupDetails) {
      navigate(
        `${ROUTES.COMMUNITY}/${communityDetails.id}/group/${groupDetails.id}#scheduler`,
      );
    } else {
      navigate(
        `${ROUTES.COMMUNITY}/${communityDetails.id}/${COMMUNITY_ROUTES.OVERALL}#scheduler`,
      );
    }
  };

  const handleParticipantChange = (value: string[]) => {
    const selectedUsers = value
      .map(
        (userId) =>
          communityDetails.users.find((user) => user.id === userId)?.id,
      )
      .filter((userId) => userId);
    setParticipants(selectedUsers);
  };

  const uploadEvents = async () => {
    try {
      let response: { newEventTypeList: any; savedEventsLength: any };

      if (groupDetails) {
        response = await uploadGroupBulkEvents(
          groupDetails.id,
          communityDetails.id,
          eventData,
          uniqueEventTypes,
          participants,
        );
      } else {
        response = await uploadBulkEvents(
          communityDetails.id,
          eventData,
          uniqueEventTypes,
          participants,
        );
      }
      dispatch(
        doCreateBulkEvents.success({
          newEventTypeList: response.newEventTypeList,
          uploadEventsCount: response.savedEventsLength,
          uploadStatus: 'completed',
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (communityDetails) {
      setParticipants(communityDetails.users.map((user) => user.id));
    }
  }, [communityDetails]);

  useEffect(() => {
    if (window.location.hash !== '') {
      (function () {
        history.replaceState(
          '',
          document.title,
          window.location.pathname + window.location.search,
        );
      })();
    }
  }, []);

  useEffect(() => {
    const modalRefVal = wrapperRef.current;

    const handleOutSideClick = (event: MouseEvent) => {
      if (modalRefVal && modalRefVal.contains(event.target as Node)) {
        event.preventDefault();
        event.stopPropagation();
        addToast(t('errors.onlyForGroupAndAdmin'), {
          appearance: 'info',
          autoDismiss: true,
        });
      }
    };

    if (
      !hasCommunityOrGroupAdminPermissions(currentUser.id, communityDetails)
    ) {
      document.addEventListener('mousedown', handleOutSideClick, true);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutSideClick);
    };
  }, []);

  useEffect(() => {
    if (bulkUploadStatus?.uploadStatus) {
      if (bulkUploadStatus.uploadStatus === 'completed') {
        setOverrideCurrentStep(3);
      }
    } else {
      if (bulkUploadStatus?.uploadStatus === '') {
        setOverrideCurrentStep(0);
        setEventData([]);
        setUniqueEventTypes([]);
        setFileData(null);
        setValidationSuccess(false);
      }
    }
  }, [bulkUploadStatus?.uploadStatus]);

  return (
    <SectionWrapper height={headerHeight} ref={wrapperRef}>
      {isLoading && !bulkUploadStatus?.uploadStatus ? (
        <Loader type="page" />
      ) : (
        <ContentWrapper>
          <Steps
            loading={loading}
            currentUser={currentUser}
            communityDetails={communityDetails}
            nextButtonsText={[
              { index: 0, text: t('bulkUploadEvents.stepOne.nextStepButton') },
              {
                index: 2,
                text: t('bulkUploadEvents.stepThree.nextStepButton'),
                preStepFunc: uploadEvents,
              },
              {
                index: 3,
                text: t('bulkUploadEvents.stepFour.goToScheduler'),
                preStepFunc: goToScheduler,
              },
            ]}
            overrideStep={overrideCurrentStep}
          >
            <div
              key="0"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              label="Step 1"
              titleText={t('bulkUploadEvents.stepOne.stepTitle')}
              description={t('bulkUploadEvents.stepOne.stepDescription')}
            >
              <ExportSampleCsv communityDetails={communityDetails} />
            </div>
            <div
              key="1"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              label="Step 2"
              titleText={t('bulkUploadEvents.stepTwo.stepTitle')}
              description={t('bulkUploadEvents.stepTwo.stepDescription')}
            >
              <ImportData
                loading={loading}
                setLoading={setLoading}
                community={communityDetails}
                setEventData={setEventData}
                user={userProfile}
                group={groupDetails}
                validationSuccess={validationSuccess}
                setValidationSuccess={setValidationSuccess}
                data={fileData}
                setData={setFileData}
                uniqueEventTypes={uniqueEventTypes}
                setUniqueEventTypes={setUniqueEventTypes}
              />
            </div>
            <div
              key="2"
              // style={{ height: '100%' }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              label="Step 3"
              titleText={t('bulkUploadEvents.stepThree.stepTitle')}
              description={t('bulkUploadEvents.stepThree.stepDescription')}
            >
              <PreviewBeforeSend
                eventData={eventData}
                group={groupDetails}
                community={communityDetails}
                participants={participants}
                onChange={handleParticipantChange}
              />
            </div>
            <div
              key="3"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              label="Step 4"
              titleText={t('bulkUploadEvents.stepFour.stepTitle')}
              description={t('bulkUploadEvents.stepFour.stepDescription')}
            >
              <UploadSuccess community={communityDetails} />
            </div>
          </Steps>
        </ContentWrapper>
      )}
    </SectionWrapper>
  );
};

export default ImportCsv;

import React, { FC, RefObject, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CREATE_EVENT_OVERALL } from 'constants/sidebars';
import { CreateEventButton, Loader } from 'components';
import GridScheduler from 'components/Grid/gridv2';
import { useGridData } from './hooks';
import {
  getOverallEventsLoading,
  getOverallEventsTotalCount,
} from 'store/selectors/events';
import {
  Community,
  CommunityOverallScheduler,
  CommunityRoles,
  SchedulerFilter,
} from 'types';
import {
  getCommunityTotal,
  getCurrentUserInCommunity,
} from 'store/selectors/currentCommunity';
import { getCurrentUserInGroup } from 'store/selectors/currentGroup';
import {
  doGetCommunityTotal,
  doRequestOverallEvents,
} from 'store/actionCreators/currentCommunity';
import { openModal } from 'store/actionCreators/modal';
import { useSchedulerPageData } from '../../../hooks/useSchedulerPageData';
import { useLazyLoading } from 'hooks';

import { StyledStickyButtonBlock } from './styled';
import { SectionWrapper } from '../styled';
import { useTranslation } from 'react-i18next';

export interface ScheduleProps {
  filters: SchedulerFilter;
  community: Community;
  usersFilter: string;
  overallScheduler?: CommunityOverallScheduler;
  zoom: number;
  headerHeight: number;
}

const Schedule: FC<ScheduleProps> = ({
  filters,
  community,
  usersFilter,
  overallScheduler,
  zoom,
  headerHeight,
}) => {
  const { t } = useTranslation();
  const { communityId } = useParams();
  const dispatch = useDispatch();
  const eventsLoading = useSelector(getOverallEventsLoading);
  const eventsTotalCount = useSelector(getOverallEventsTotalCount);
  const currentUser = useSelector(getCurrentUserInCommunity);
  const currentUserInGroup = useSelector(getCurrentUserInGroup);
  const total = useSelector(getCommunityTotal);

  const topHeadHeight = useMemo(() => {
    return headerHeight;
  }, [headerHeight]);

  const {
    eventsToRender,
    footerData,
    openGiveReplySidebar,
    openTaskDetailsSidebar,
    openViewMemberSidebar,
    onHeaderClick,
    onFooterClick,
  } = useSchedulerPageData({
    events: overallScheduler?.events,
    community,
    filters,
    total,
  });

  const canCreateEvent = [CommunityRoles.OWNER, CommunityRoles.ADMIN].includes(
    currentUser.communityPermission.role,
  );

  const gridData = useGridData({
    tasks: community?.eventTasks,
    eventsToRender,
    responses: community?.eventResponses,
    openGiveReplySidebar,
    openTaskDetailsSidebar,
    currentUser,
    currentUserInGroup,
    overallScheduler,
    zoom,
    openViewMemberSidebar,
    community,
  });

  const eventsFilterValues = useMemo(() => {
    return {
      communityId,
      from: filters.from,
      to: filters.to,
      eventTypeId: filters.eventType.value,
      filterValue: usersFilter,
    };
  }, [filters, usersFilter]);

  const loadEvents = (filterValues) => {
    dispatch(doRequestOverallEvents(filterValues));
  };

  useEffect(() => {
    if (communityId && overallScheduler?.events) {
      dispatch(
        doGetCommunityTotal({
          communityId,
          from: filters.from,
          to: filters.to,
        }),
      );
    }
  }, [communityId, usersFilter, filters]);

  const pageSize = 40;

  const { listRef }: { listRef: RefObject<HTMLElement> } = useLazyLoading({
    isLoading: eventsLoading,
    loader: loadEvents,
    pageSize,
    listLength: overallScheduler?.events?.length || 0,
    hasNextPage:
      overallScheduler?.events &&
      overallScheduler.events.length < eventsTotalCount,
    cleaner: () => {},
    filterValues: eventsFilterValues,
    scollOffset: 1000,
  });

  const openCreateEventSidebar = () => {
    dispatch(
      openModal({
        type: CREATE_EVENT_OVERALL,
        placement: 'right',
        data: {
          overallScheduler,
          filterFrom: filters.from,
          filterTo: filters.to,
        },
      }),
    );
  };

  if (eventsLoading && !overallScheduler?.events?.length) {
    return <Loader />;
  }

  return (
    <SectionWrapper height={topHeadHeight}>
      <>
        {eventsLoading ? <Loader floating={true} /> : null}
        <GridScheduler
          listRef={listRef}
          tableId={communityId}
          data={gridData}
          footer={footerData}
          header={eventsToRender}
          topHeadHeight={topHeadHeight}
          zoom={zoom}
          onHeaderClick={onHeaderClick}
          onFooterClick={onFooterClick}
        />
      </>
      {canCreateEvent && (
        <StyledStickyButtonBlock>
          <CreateEventButton
            width="72px"
            onClick={openCreateEventSidebar}
            extraText={t('event.createEvent')}
          />
        </StyledStickyButtonBlock>
      )}
    </SectionWrapper>
  );
};

export default Schedule;

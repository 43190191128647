import React, { FC, useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import { useMedia } from 'react-use';

import { Button, Card, CloseButton, Divider } from 'components';
import PopupWrapper from '../../Popups/Wrapper';
import useOutsideClick from 'hooks/outsideClick';
import { breakpoints } from 'theme';
import successCheck from 'static/animations/success-check.json';
import warningSign from 'static/animations/warning-sign.json';
import errorCross from 'static/animations/error-cross.json';
import eventCalendar from 'static/animations/event.json';
import info from 'static/animations/info.json';
import email from 'static/animations/email-sent.json';

import {
  Title,
  Message,
  Image,
  ExtraInfoWrapper,
  ExtraInfoText,
} from '../../InfoPopup/styled';
import { CopyButton } from 'components/Inputs/InvitationLinkInput/styled';
import {
  ButtonWrapper,
  MessageContainer,
  MessageContainerOuter,
  MessageWrapper,
  ProgressBarContainer,
  ProgressLine,
  ProgressStepCircle,
} from './styles';

export interface UserGuidePopupProps {
  children?: JSX.Element;
  isOpen?: boolean;
  title: string;
  message?: string;
  buttonText?: string;
  type?: 'success' | 'warning' | 'info' | 'error';
  size?: 'sm' | 'md' | 'lg';
  image?: string;
  loop?: boolean;
  noButton?: boolean;
  duration?: number;
  cancelButtonText?: string;
  extraInfoText?: string;
  extraInfoType?: 'message' | 'link' | 'warning';
  messageAlign?: 'left' | 'center' | 'right' | 'justify' | 'start' | 'end';
  multiStep?: { image: string; message: string }[];
  onButtonClick?: () => void;
  onClose?: () => void;
}

const UserGuidePopup: FC<UserGuidePopupProps> = ({
  children,
  isOpen,
  title,
  message,
  buttonText,
  type = 'success',
  size = 'md',
  image,
  loop,
  noButton,
  duration,
  cancelButtonText,
  extraInfoText,
  extraInfoType,
  messageAlign = 'center',
  multiStep = [],
  onButtonClick = () => {},
  onClose = () => {},
}) => {
  const { t } = useTranslation();
  const wrapperRef = useRef();
  const [open, setOpen] = useState(isOpen || false);
  const isDownSmPlus = useMedia(breakpoints.downSmPlus);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);

  const onChangeIndex = (idx: number) => {
    setCurrentStepIndex(idx);
  };

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  const toggleOpen = () => {
    setOpen((open) => !open);
  };

  const closePopup = () => {
    setOpen(false);
    onClose();
  };

  const handleButtonClick = () => {
    if (currentStepIndex + 1 === multiStep.length) {
      onButtonClick();
      closePopup();
    } else {
      setCurrentStepIndex((val) => val + 1);
    }
  };

  const cancelButtonClick = () => {
    if (currentStepIndex === 0) {
      closePopup();
    } else {
      setCurrentStepIndex((value) => value - 1);
    }
  };

  useOutsideClick(wrapperRef, closePopup);

  const animationData = useMemo(() => {
    if (type === 'success') {
      return eventCalendar;
    } else if (type === 'warning') {
      return warningSign;
    } else if (type === 'info') {
      return email;
    } else {
      return errorCross;
    }
  }, [type]);

  const animationOptions = {
    animationData,
    loop,
    autoplay: true,
  };

  const progressWidth = useMemo(() => {
    return `${Math.floor((currentStepIndex / (multiStep.length - 1)) * 100)}%`;
  }, [currentStepIndex, multiStep.length]);

  useEffect(() => {
    let timer;
    if (duration) {
      timer = setTimeout(() => setOpen(false), duration);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [duration]);

  return (
    <>
      {children && <div onClick={toggleOpen}>{children}</div>}
      <PopupWrapper isOpen={open}>
        <Card cardRef={wrapperRef} size={size} greenBorder={true}>
          <CloseButton onClick={closePopup} />
          <Lottie
            options={animationOptions}
            // width={size === 'lg' && !isDownSmPlus ? 150 : 80}
            // height={size === 'lg' && !isDownSmPlus ? 150 : 80}
            width={100}
            height={100}
          />
          <Title marginTop="8px" marginBot="8px">
            {title}
          </Title>
          {image && <Image src={image} alt="img" />}
          {message && (
            <Message noButton={noButton} textAlign={messageAlign}>
              {message}
            </Message>
          )}
          {extraInfoText && (
            <ExtraInfoWrapper>
              <ExtraInfoText>{extraInfoText}</ExtraInfoText>
              {extraInfoType === 'link' && <CopyButton>Copy Link</CopyButton>}
            </ExtraInfoWrapper>
          )}

          {multiStep.length > 0 && (
            <>
              <ProgressBarContainer>
                <ProgressLine width={progressWidth} />
                {multiStep.map((_, idx) => (
                  <ProgressStepCircle
                    key={idx}
                    currentStep={idx <= currentStepIndex}
                  >
                    {idx + 1}
                  </ProgressStepCircle>
                ))}
              </ProgressBarContainer>
              <MessageWrapper>
                <MessageContainerOuter totalWidth={multiStep.length * 100}>
                  {multiStep.map((step, idx) => (
                    <MessageContainer
                      key={idx}
                      currentStep={currentStepIndex}
                      eachWidth={(1 / multiStep.length) * 100}
                    >
                      {step.image && (
                        <>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Image src={step.image} width={'85%'} />
                          </div>
                        </>
                      )}
                      <Message textAlign={messageAlign}>{step.message}</Message>
                    </MessageContainer>
                  ))}
                </MessageContainerOuter>
              </MessageWrapper>
            </>
          )}
          {!noButton && (
            <Divider
              customMargin={size === 'lg' ? '0px 0px 32px' : '0px 0px 16px 0px'}
            />
          )}
          <ButtonWrapper>
            {cancelButtonText && (
              <Button
                width="40%"
                onClick={cancelButtonClick}
                variant="secondary"
                {...{ rounded: true }}
              >
                {currentStepIndex === 0 ? cancelButtonText : t('common.back')}
              </Button>
            )}
            {!noButton && (
              <Button
                width="40%"
                onClick={handleButtonClick}
                {...{ rounded: true }}
              >
                {currentStepIndex === multiStep.length - 1
                  ? buttonText
                  : t('common.next')}
              </Button>
            )}
          </ButtonWrapper>
        </Card>
      </PopupWrapper>
    </>
  );
};

export default UserGuidePopup;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';

import OverallSchedulerPage from './OverallSchedulerPage';
import {
  doRequestOverallScheduler,
  doClearOverallScheduler,
} from 'store/actionCreators/currentCommunity';
import { closeModal } from 'store/actionCreators/modal';
import {
  getCurrentCommunity,
  getOverallScheduler,
  getOverallSchedulerLoading,
  getIsMenuOpen,
} from 'store/selectors/currentCommunity';
import { getCurrentGroupError } from 'store/selectors/currentGroup';
import { SchedulerFilter } from 'types';
import useEventRedirection from '../../hooks/useEventRedirection';
import { getMyEventsData } from 'store/selectors/events';

import { ROUTES, COMMUNITY_ROUTES } from 'configs';

const OverallSchedulerPageContainer = () => {
  const { t } = useTranslation();
  const { communityId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [zoom, setZoom] = useLocalStorage<number>(
    'communitySchedulerZoom',
    window.innerHeight > 760 ? 1 : 0.7499999999999998,
  );
  const isMenuOpen = useSelector(getIsMenuOpen);
  const community = useSelector(getCurrentCommunity);
  const overallScheduler = useSelector(getOverallScheduler);
  const isLoading = useSelector(getOverallSchedulerLoading);
  const isError = useSelector(getCurrentGroupError);
  const eventsFromMyAppointments = useSelector(getMyEventsData);

  const initialFiltersState = {
    from: new Date(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
    ),
    to: new Date(
      new Date().getUTCFullYear() + 1,
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
    ),
    eventType: {
      label: t('common.all'),
      value: 'all',
    },
  };

  const [filters, setFilters] = useState<SchedulerFilter>(initialFiltersState);

  useEffect(() => {
    if (!overallScheduler?.events && !isError) {
      const currentRoute = location.pathname;
      const navigationValues = [
        ROUTES.COMMUNITY,
        COMMUNITY_ROUTES.OVERALL,
        COMMUNITY_ROUTES.EVENT,
        'details',
      ];
      if (
        navigationValues.every((navValue) => currentRoute.includes(navValue))
      ) {
        const routeArr = currentRoute.split('/');
        const eventId = routeArr[routeArr.length - 2];
        const event = eventsFromMyAppointments.events.find(
          (event) => eventId === event.id,
        );
        dispatch(
          doRequestOverallScheduler({
            communityId,
            ...filters,
            from: new Date(event.from),
            to: filters.to,
            eventType: filters.eventType,
            eventTypeId: filters.eventType.value,
          }),
        );
        setFilters((prevFilterState) => ({
          ...prevFilterState,
          from: new Date(event.from),
        }));
      } else {
        dispatch(
          doRequestOverallScheduler({
            communityId,
            ...filters,
            from: filters.from,
            to: filters.to,
            eventType: filters.eventType,
            eventTypeId: filters.eventType.value,
          }),
        );
      }
    }

    return () => {
      dispatch(closeModal());
      dispatch(doClearOverallScheduler());
    };
  }, []);

  useEventRedirection(filters, overallScheduler?.events);

  if (isError) {
    navigate('/404');
  }

  return (
    <>
      <OverallSchedulerPage
        isLoading={isLoading}
        community={community}
        overallScheduler={overallScheduler}
        isMenuOpen={isMenuOpen}
        zoom={zoom}
        filters={filters}
        initialFiltersState={initialFiltersState}
        setZoom={setZoom}
        setFilters={setFilters}
      />
    </>
  );
};

export default OverallSchedulerPageContainer;

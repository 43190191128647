import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledTooltip = styled(motion.div)`
  z-index: 100;
  background: white;
  box-shadow: 0 4px 12px 0 #30465980;
  padding: 10px 15px;
  border-radius: 6px;
  width: 220px;
`;

import React, { FC } from 'react';
import { noop } from 'lodash/fp';

import { PlusButton } from 'components';

import { Header, HeaderContainer } from './styled';

interface TabHeaderProps {
  title: string;
  onAdd?: () => void;
  text?: JSX.Element | JSX.Element[] | string;
}

const tooltipProps = {
  tooltipStyle: {
    fontSize: 12,
    maxWidth: 200,
    whiteSpace: 'pre-wrap',
    lineBreak: 'normal',
  },
};

const TabHeader: FC<TabHeaderProps> = ({ title, onAdd = noop, text }) => (
  <HeaderContainer>
    <Header>{title}</Header>
    <PlusButton
      width="50px"
      onClick={onAdd}
      contained
      position="left-center"
      text={text}
      tooltipStyle={tooltipProps.tooltipStyle}
    />
  </HeaderContainer>
);

export default TabHeader;

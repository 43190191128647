import moment from 'moment';
import { EventType } from 'types';
import { EXPORT_FIELDS } from 'constants/export_csv';
import { useTranslation } from 'react-i18next';

type ErrorObject = {
  errorMessage: string;
  errorType: string;
  row: number;
};

type EventParams = {
  [EXPORT_FIELDS.TITLE]: string;
  [EXPORT_FIELDS.DESCRIPTION]: string;
  [EXPORT_FIELDS.LOCATION]?: string;
  [EXPORT_FIELDS.DATE]: string;
  [EXPORT_FIELDS.FROM]: string;
  [EXPORT_FIELDS.TO]: string;
  [EXPORT_FIELDS.EVENT_TYPE_NAME]: string;
  [EXPORT_FIELDS.ALL_DAY]: string;
  row: number;
};

export const useValidateEventData: () => {
  dataValidation: (data: EventParams) => ErrorObject | null;
} = () => {
  const { t } = useTranslation();

  const dataValidation: (data: EventParams) => ErrorObject | null = ({
    [EXPORT_FIELDS.DESCRIPTION]: description,
    [EXPORT_FIELDS.EVENT_TYPE_NAME]: eventTypeName,
    [EXPORT_FIELDS.DATE]: date,
    [EXPORT_FIELDS.FROM]: from,
    [EXPORT_FIELDS.TO]: to,
    [EXPORT_FIELDS.LOCATION]: location,
    [EXPORT_FIELDS.TITLE]: title,
    [EXPORT_FIELDS.ALL_DAY]: allDay,
    row,
  }) => {
    let error: ErrorObject = {
      errorMessage: '',
      errorType: '',
      row: 0,
    };
    if (!title) {
      error = {
        ...error,
        errorMessage: t('bulkUploadEvents.errors.eventNameErrorMessage', {
          row: row + 1,
        }),
        errorType: t('bulkUploadEvents.errors.eventNameErrorTitle'),
        row,
      };
      return error;
    }
    if (!eventTypeName) {
      error = {
        ...error,
        errorMessage: t('bulkUploadEvents.errors.eventTypeErrorMessage', {
          row: row + 1,
        }),
        errorType: t('bulkUploadEvents.errors.eventTypeErrorTitle'),
        row,
      };
      return error;
    }
    // check if date field is empty
    if (!from || !to) {
      error = {
        ...error,
        errorMessage: t('bulkUploadEvents.errors.timeErrorMessage', {
          row: row + 1,
        }),
        errorType: t('bulkUploadEvents.errors.timeErrorTitle'),
        row,
      };
      return error;
    }

    if (!date) {
      error = {
        errorMessage: t('bulkUploadEvents.errors.dateErrorMessage', {
          row: row + 1,
        }),
        errorType: t('bulkUploadEvents.errors.dateErrorTitle'),
        row,
      };
      return error;
    }
    // check if date field is valid
    if (to && from && date) {
      const eventDate = moment(date, 'DD-MM-YYYY');
      const tempDate = moment(date);
      let formattedDate = '';
      if (eventDate.isValid() || tempDate.isValid()) {
        if (eventDate.isValid()) {
          formattedDate = eventDate.format('LL');
        } else if (tempDate.isValid()) {
          formattedDate = tempDate.format('LL');
        }
      }

      // formattedDate = eventDate.format('LL');
      const startTime = moment(formattedDate + ' ' + from);
      const endTime = moment(formattedDate + ' ' + to);
      // const fromDate = new Date(from);
      // const toDate = new Date(to);
      // const invalidFromDate = isNaN(Number(fromDate));
      // const invalidToDate = isNaN(Number(toDate));
      if (
        !startTime.isValid() ||
        !endTime.isValid() ||
        (!eventDate.isValid() && !tempDate.isValid())
      ) {
        error = {
          errorMessage: t('bulkUploadEvents.errors.invalidDateOrTimeMessage', {
            row: row + 1,
          }),
          errorType: t('bulkUploadEvents.errors.invalidDateOrTimeTitle'),
          row,
        };
        return error;
      }
    }

    if (!location) {
      error = {
        ...error,
        errorType: t('bulkUploadEvents.errors.locationErrorTitle'),
        errorMessage: t('bulkUploadEvents.errors.locationErrorMessage', {
          row: row + 1,
        }),
        row,
      };
      return error;
    }
    return null;
  };

  return { dataValidation };
};

export const exportFileData = (
  data: BlobPart,
  fileName: string,
  contentType: string,
) => {
  const blob = new Blob([data], { type: contentType });
  const url = URL.createObjectURL(blob);
  const downloadButton = document.createElement('a');
  downloadButton.href = url;
  downloadButton.setAttribute('download', fileName);
  downloadButton.click();
  URL.revokeObjectURL(url);
  downloadButton.remove();
};

import React from 'react';
import { isEmpty, map } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ROUTES, COMMUNITY_ROUTES } from 'configs';
import {
  ContentWrapper,
  ReturnLinkButton,
  FileItem,
  FileInput,
  ContentPlaceholder,
  Loader,
} from 'components';
import { hasCommunityAdminPermissions, hasGroupAdminPermissions } from 'utils';
import {
  getGroupDocuments,
  getIsDocumentUploading,
} from 'store/selectors/documents';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
  getGroup,
  getIsMenuOpen,
} from 'store/selectors/currentCommunity';
import { IRootState } from 'store/reducers';
import { useDocumentation } from 'hooks';

import {
  HeaderContainer,
  Header,
  FileInputContainer,
  Headline,
  FilesList,
} from './styled';

const DocumentationDetailsPage = () => {
  const { t } = useTranslation();
  const { communityId, groupId } = useParams();
  const documents = useSelector((state: IRootState) =>
    getGroupDocuments(state, groupId),
  );
  const group = useSelector((state: IRootState) => getGroup(state, groupId));
  const community = useSelector(getCurrentCommunity);
  const isMenuOpen = useSelector(getIsMenuOpen);
  const isUploading = useSelector(getIsDocumentUploading);
  const currentUser = useSelector(getCurrentUserInCommunity);

  const { onDelete, onSubmit, register, setValue } = useDocumentation(
    communityId,
    groupId,
  );

  const canUploadDocuments = () => {
    if (hasCommunityAdminPermissions(currentUser.id, community)) {
      return true;
    }

    return (
      group?.id &&
      hasGroupAdminPermissions(currentUser.id, community, group?.id)
    );
  };

  if (!(community || documents)) {
    return <Loader />;
  }

  return (
    <ContentWrapper isMenuOpen={isMenuOpen}>
      <HeaderContainer isMenuOpen={isMenuOpen}>
        <ReturnLinkButton
          to={`${ROUTES.COMMUNITY}/${community.id}/${COMMUNITY_ROUTES.DOCUMENTATION}`}
        />
        <Header>
          {group?.name || t('documentation.generalDocumentation')}
        </Header>
      </HeaderContainer>
      {canUploadDocuments() && (
        <FileInputContainer>
          <FileInput
            text={t('documentation.chooseFile')}
            tipText={t('documentation.tipMessage')}
            register={register}
            setValue={setValue}
            handleSubmit={onSubmit}
            name="document"
            width="100%"
            height="100%"
            isLoading={isUploading}
          />
        </FileInputContainer>
      )}
      <Headline>{t('documentation.uploadedFiles')}</Headline>
      <FilesList>
        {!isEmpty(documents) ? (
          map(
            (document) => (
              <FileItem onDelete={onDelete} key={document.id} file={document} />
            ),
            documents,
          )
        ) : (
          <ContentPlaceholder />
        )}
      </FilesList>
    </ContentWrapper>
  );
};

export default DocumentationDetailsPage;

import styled, { css } from 'styled-components';
import { breakpoints, verticalBreakpoints } from 'theme';
import { motion } from 'framer-motion';

export const CheckBoxWrapper = styled.div`
  text-align: left;

  & > label {
    font-size: 12px;
    color: #8298ab;
    font-weight: 600;
    padding-top: 1px;
  }
`;

export const PhoneMockupContainer = styled.div`
  /* flex: 1; */
  width: 340px;
`;
export const SelectBoxContainer = styled.div`
  width: 360px;
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  gap: 24px;
  border-right: ${({ rightBorder, theme }) =>
    rightBorder && `1px solid ${theme.color.lightGrey}`};
  padding: ${({ padding = '0px' }) => padding};

  @media (max-width: 1832px) {
    display: flex;
  }
`;
export const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ currentView }) =>
    currentView === 'single' || currentView === 'double' ? '40px' : ''};
`;
export const VerticalLine = styled.div`
  height: 60px;
  width: 1px;
  background-color: ${({ theme }) => theme.color.lightGrey};
  align-self: flex-end;
`;

export const ElementWrapper = styled.div`
  width: 340px;
`;

export const PushSettingsSection = styled.div`
  display: flex;
`;

export const PushMessageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  width: 100%;
  gap: 60px;
  position: relative;
  padding-bottom: 24px;
  /* padding-left: 4px; */
  padding-right: ${({ pushMessageView }) =>
    pushMessageView === 'double' ? '34px' : '4px'};
  //flex-start
  justify-content: ${({ pushMessageView }) =>
    pushMessageView === 'single' ? 'center' : 'space-between'};

  //ipad user
  @media (max-width: 960px) {
    justify-content: ${({ pushMessageView }) =>
      pushMessageView === 'double' ? 'space-between' : 'center'};
    /* gap: 60px; */
    gap: 30px;
    padding-right: ${({ pushMessageView }) =>
      pushMessageView === 'double' ? '34px' : '30px'};
    padding-left: 20px;
  }

  @media ${breakpoints.downSmPlus} {
    padding: 0px 20px 24px 20px;
  }

  @media (min-width: 2000px) {
    justify-content: center;
    gap: 112px;
  }
`;

export const ContentContainer = styled(motion.div)`
  width: 100%;
  padding: 0 12px;
  padding: 0 0px 0 30px;
  height: calc(100svh - 50px);
  overflow-y: scroll;
  overflow-x: hidden;
  @media ${breakpoints.downSmPlus} {
    padding: 0px 8px;
  }
`;

export const StyledSectionHeader = styled.div`
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 10px;
  color: ${({ theme }) => theme.color.pickledBluewood};
`;

export const SubmitPopupWrapper = styled(motion.div)`
  width: 100vw;
  height: 100svh;
  background-color: ${({ theme }) => theme.color.darkGrey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000000;
`;

export const SubmitPopup = styled(motion.div)`
  width: 800px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 36px 30px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.02), 0 -1px 6px 0 rgba(0, 0, 0, 0.06);

  & > button:first-of-type {
    position: absolute;
    right: 14px;
    top: 14px;
  }

  @media (max-width: 900px) {
    width: 90%;
  }
  @media ${breakpoints.downSmPlus} {
    max-height: 80%;
    overflow: auto;
    padding: 24px 16px;
  }
`;

export const PopupHeadTitle = styled.h3`
  font-size: 30px;
  line-height: 50px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 30px;
`;

export const PopupHeadMessage = styled.p`
  font-size: 12px;
  line-height: 20px;
`;

export const InfoContainer = styled.div`
  width: 100%;
  overflow: hidden;
  /* word-wrap: break-word; */
  text-overflow: ellipsis;
  /* overflow-wrap: break-word; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 22px;
  @media ${breakpoints.smPlus} {
    display: table;
    border-spacing: 12px;
  }
`;

export const InfoBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-size: 16px;
  gap: 8px;
  @media ${breakpoints.smPlus} {
    display: table-row;
  }

  @media ${breakpoints.downSmPlus} {
    padding-bottom: 22px;
    flex-direction: column;
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme.color.lightGrey};
    :last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
`;

export const InfoTitle = styled.p`
  /* font-weight: bold; */
  text-transform: uppercase;
  font-weight: 600;
  min-width: 120px;
  @media ${breakpoints.smPlus} {
    display: table-cell;
  }
  color: ${({ theme }) => theme.color.baliHai};
  font-size: 13px;
  font-family: 'Arial';
`;

export const InfoMessage = styled.p`
  font-weight: 400;
  font-weight: ${({ boldFonts }) => (boldFonts ? 'bold' : 'normal')};
  /* width: calc(100% - 120px); */
  width: ${({ messageWidth }) => messageWidth};
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-align: left;
  padding-left: ${({ noLeftPadding }) => (noLeftPadding ? '0px' : '4px')};
  font-size: 17px;
  /* display: table-cell; */
  @media ${breakpoints.downSmPlus} {
    width: 100%;
    text-align: center;
    padding-left: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  @media ${breakpoints.downSmPlus} {
    flex-direction: column-reverse;
    gap: 12px;
  }
`;

export const StyledContainer = styled.div`
  /* border: 1px solid ${({ theme }) => theme.color.brightTurquoise}; */
  box-shadow: 0 0 16px 1px ${({ theme }) => theme.color.lightGrey};
  width: 90%;
  border-radius: 16px;
  padding: 30px 18px;
  @media ${breakpoints.downSmPlus} {
    max-height: 60%;
    padding: 16px 8px;
    overflow: auto;
  }
`;

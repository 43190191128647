import styled, { css } from 'styled-components';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { ScrollbarStyle } from '../styled';

const sharedStylesPicker = css`
  height: 36px;
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.color.bittersweet : theme.color.botticelli};
  border-radius: 4px;
  outline: none;
  padding-left: 14px;
  font-weight: 600;
  font-size: 13px;
  line-height: 32px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  cursor: pointer;

  &:focus {
    border-color: ${({ theme }) => theme.color.brightTurquoise};
    color: ${({ theme }) => theme.color.brightTurquoise};
  }
`;

export const StyledPickerErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledPickerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: ${({ width = '105px' }) => width};

  & > .react-datepicker-wrapper:hover + img {
    filter: brightness(0) saturate(100%) invert(73%) sepia(79%) saturate(2725%)
      hue-rotate(128deg) brightness(95%) contrast(87%);
  }

  & > .react-datepicker__tab-loop + img {
    filter: brightness(0) saturate(100%) invert(73%) sepia(79%) saturate(2725%)
      hue-rotate(128deg) brightness(95%) contrast(87%);
  }

  .react-datepicker {
    border: none;
    box-shadow: 0px 0px 8px 2px ${({ theme }) => theme.color.lightGrey};
  }

  .react-datepicker-popper {
    z-index: 100;
    transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);

    ${ScrollbarStyle}

    width: 100%;
    .react-datepicker__time-container {
      width: 100%;
    }
    .react-datepicker--time-only {
      width: 80%;
    }
    .react-datepicker__header {
      border-bottom: 3px solid ${({ theme }) => theme.color.lightGrey};
      background-color: ${({ theme }) => theme.color.white};
    }
    .react-datepicker-time__header {
      font-weight: bold;
      font-family: 'Open Sans';
    }
    .react-datepicker__time-box {
      width: 100%;
    }
    .react-datepicker__time-list-item {
      font-family: 'Open Sans';
      letter-spacing: 1px;
      word-spacing: 4px;
    }
  }
`;

export const StyledTimePicker = styled(DatePicker)`
  ${sharedStylesPicker};
  width: 100%;
`;

export const StyledTimeIcon = styled.img`
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

import styled from 'styled-components';
import Select from 'react-select';

export const StyledSelect = styled(Select)`
    width: ${({ width }) => width || '340px'};
    position: relative;
    color: transparent;
    .react-select__control {
        border: 1px solid ${({ error, theme }) =>
          error ? theme.color.bittersweet : theme.color.botticelli};
        cursor: pointer;
        box-shadow: none;
        min-height: 0;
    };
    .react-select__control:hover {
        border-color: ${({ theme }) => theme.color.botticelli};
    };
    .react-select__value-container {
        padding: 0 10px;
    };
    .react-select__single-value {
      height: 18px;
    }
    .react-select__menu {
      display: block;
    }
    .react-select__indicator-separator {
        position: absolute;
    };
    .react-select__control--menu-is-open .react-select__indicator {
        transform: rotate(180deg);
    };
    .react-select__menu {
        margin-top: 4px;
        margin-bottom: 0px;
    };
    .react-select__option {
        background-color: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
      }),
`;

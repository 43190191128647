import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import {
  Button,
  CheckBox,
  DatePickerControlled,
  FilterTooltip,
  Loader,
  Pagination,
  Tooltip,
} from 'components';
import SelectInputControlled from 'components/Inputs/SelectInputControlled/SelectInputControlledV2';
import { STATISTICS_TYPES } from 'configs';
import {
  getCurrentCommunity,
  getGroups,
} from 'store/selectors/currentCommunity';
import { getEventTypes } from 'store/selectors/eventTypes';
import DateRangeSelect from '../../Inputs/DateRangeSelect';
import SelectInputControlledCheckbox from '../../Inputs/SelectInputControlled/SelectInputControlledCheckbox';
import {
  getAppointmentScheduleStatistic,
  getMemberFiguresStatistics,
} from 'store/selectors/statistics';
import { SchedulerFilter } from 'types';
import { StyledLabel } from '../../Inputs/styled';
import { APPOINTMENT_SCHEDULE_COLUMNS } from 'configs/statistics';
import {
  getAppointmentScheduleStatisticsCsv,
  getAppointmentScheduleStatisticsXlsx,
  getMemberFiguresStatisticsCsv,
  getMemberFiguresStatisticsXlsx,
} from 'services/statistics';
import { CsvIcon, XlsxIcon } from 'static';
import { getCurrentGroup } from 'store/selectors/currentGroup';
import { breakpoints } from 'theme';

import {
  CheckboxesWrapper,
  Container,
  ExportTooltipLoader,
  ExportTooltipOptions,
  FieldWrapper,
  FilterBlock,
  FiltersInfo,
} from './styled';
import { getProfileName } from '../../../utils';
import { getMembersByGroups } from '../utils';
import { useInfoPopup } from '../../../hooks';
import { getAuthUser } from '../../../store/selectors/auth';

interface AppointmentScheduleFiltersProps {
  type: STATISTICS_TYPES;
  filters: SchedulerFilter;
  setFilters: (values: SchedulerFilter) => void;
  setType: (type: STATISTICS_TYPES) => void;
}

const FilterTooltipInnerComponent = ({
  type,
  filters,
  columnsList,
  membersOptions,
  setFilters,
}: {
  type: STATISTICS_TYPES;
  filters: SchedulerFilter;
  columnsList: { label: string; value: string }[];
  membersOptions: { label: string; value: string }[];
  setFilters: (values: SchedulerFilter) => void;
}) => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const community = useSelector(getCurrentCommunity);
  const eventTypes = useSelector(getEventTypes) || [];

  const handleDateRangeChange = (v) => {
    setFilters({
      ...filters,
      dateRange: v,
    });
  };

  const handleGroupsChange = (v) => {
    setFilters({
      ...filters,
      groups: v,
    });
  };

  const handleMembersChange = (v) => {
    setFilters({
      ...filters,
      members: v,
    });
  };

  const handleFromChange = (v) => {
    setFilters({
      ...filters,
      from: new Date(moment(v).utc().startOf('day').format()),
    });
  };

  const handleToChange = (v) => {
    setFilters({
      ...filters,
      to: new Date(moment(v).utc().endOf('day').format()),
    });
  };

  const handleEventTypesChange = (v) => {
    setFilters({
      ...filters,
      eventTypes: v,
    });
  };

  const handleVisibleColumnsChange = (e: any) => {
    const value = e.target.name;

    setFilters({
      ...filters,
      visibleColumns: filters.visibleColumns.includes(value)
        ? filters.visibleColumns.filter((i) => i !== value)
        : [...filters.visibleColumns, value],
    });
  };

  return (
    <FilterBlock>
      <FieldWrapper>
        <DateRangeSelect
          value={filters.dateRange}
          onChange={handleDateRangeChange}
        />
      </FieldWrapper>
      {filters.dateRange.value === 'dateRange' ? (
        <>
          <FieldWrapper>
            <DatePickerControlled
              label={t('common.from')}
              date={filters.from}
              maxDate={filters.to}
              handleChange={handleFromChange}
            />
          </FieldWrapper>
          <FieldWrapper>
            <DatePickerControlled
              label={t('common.to')}
              date={filters.to}
              minDate={filters.from}
              handleChange={handleToChange}
            />
          </FieldWrapper>
        </>
      ) : null}
      <FieldWrapper>
        <SelectInputControlledCheckbox
          label={t('event.eventType')}
          width="100%"
          options={eventTypes.map((t) => ({
            label: t.title,
            value: t.id,
          }))}
          value={filters.eventTypes}
          closeMenuOnSelect={false}
          addAllOption={true}
          maxMenuHeight={300}
          onChange={handleEventTypesChange}
        />
      </FieldWrapper>
      {!groupId ? (
        <FieldWrapper>
          <SelectInputControlledCheckbox
            label={t('group.groups')}
            width="100%"
            options={community.groups.map((g) => ({
              label: g.name,
              value: g.id,
            }))}
            value={filters.groups}
            closeMenuOnSelect={false}
            addAllOption={true}
            maxMenuHeight={230}
            onChange={handleGroupsChange}
          />
        </FieldWrapper>
      ) : null}
      {type === STATISTICS_TYPES.APPOINTMENT_SCHEDULE && (
        <div>
          <StyledLabel>{t('common.columns')}</StyledLabel>
          <CheckboxesWrapper>
            {columnsList.map((i) => (
              <CheckBox
                key={i.value}
                name={i.value}
                label={i.label}
                isSelected={filters.visibleColumns.includes(i.value)}
                onChange={handleVisibleColumnsChange}
              />
            ))}
          </CheckboxesWrapper>
        </div>
      )}
      {type === STATISTICS_TYPES.MEMBER_FIGURES && (
        <FieldWrapper>
          <SelectInputControlledCheckbox
            label={t('community.members')}
            width="100%"
            options={membersOptions}
            value={filters.members}
            closeMenuOnSelect={false}
            addAllOption={true}
            maxMenuHeight={230}
            onChange={handleMembersChange}
          />
        </FieldWrapper>
      )}
    </FilterBlock>
  );
};

const AppointmentScheduleFilters: FC<AppointmentScheduleFiltersProps> = ({
  type,
  filters,
  setFilters,
  setType,
}) => {
  const { t } = useTranslation();
  const { groupId } = useParams();
  const { showInfoPopup } = useInfoPopup();
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
  const [exportTooltipShown, setExportTooltipShown] = useState<boolean>(false);
  const [exportFileLoading, setExportFileLoading] = useState<boolean>(false);
  const profile = useSelector(getAuthUser);
  const community = useSelector(getCurrentCommunity);
  const group = useSelector(getCurrentGroup);
  const groups = useSelector(getGroups) || [];
  const eventTypes = useSelector(getEventTypes) || [];
  const appointmentScheduleData = useSelector(getAppointmentScheduleStatistic);
  const memberFiguresData = useSelector(getMemberFiguresStatistics);
  const isDownMd = useMedia(breakpoints.downMd);
  const isDownMdPlus = useMedia(breakpoints.downMdPlus);
  const isDownSmPlus = useMedia(breakpoints.downSmPlus);

  const membersOptions = useMemo(() => {
    if (type === STATISTICS_TYPES.MEMBER_FIGURES) {
      if (groupId) {
        return group?.users?.data?.map((u) => ({
          label: getProfileName(u.profile),
          value: u.id,
        }));
      }

      const allGroupsSelected = filters.groups.length === groups.length;

      if (allGroupsSelected || filters.groups.length === 0) {
        return community.users.map((u) => ({
          label: getProfileName(u.profile),
          value: u.id,
        }));
      } else {
        return getMembersByGroups({
          groups,
          filteredGroups: filters.groups,
        });
      }
    }

    return [];
  }, [type, groups, group, filters.groups, groupId, community.users]);

  const filterTooltipWidth = useMemo(() => {
    if (isDownMd) {
      return '380px';
    }

    return '550px';
  }, [isDownMd]);

  const exportTooltipWidth = useMemo(() => {
    return '340px';
  }, [isDownMdPlus, isDownSmPlus]);

  const columnsList = [
    { value: 'start', label: t('common.startTime') },
    { value: 'description', label: t('common.description') },
    { value: 'end', label: t('common.endTime') },
    { value: 'location', label: t('event.location') },
    { value: 'creationDate', label: t('event.createdAt') },
    { value: 'organizedBy', label: t('event.eventOrganizerShort') },
  ];

  const tableTypeOptions = Object.values(STATISTICS_TYPES).map((value) => ({
    value,
    label: t(`statistics.${value}`),
    newLabel: value === STATISTICS_TYPES.MEMBER_FIGURES,
  }));

  const filtersInfo = useMemo(() => {
    let infoString = '';

    if (filters.dateRange.value === 'dateRange') {
      infoString += `${moment(filters.from).format('DD.MM.YYYY')} - ${moment(
        filters.to,
      ).format('DD.MM.YYYY')}; `;
    } else {
      infoString += `${moment(filters.dateRange.value[0]).format(
        'DD.MM.YYYY',
      )} - ${moment(filters.dateRange.value[1]).format('DD.MM.YYYY')}; `;
    }

    if (filters.eventTypes.length === eventTypes.length) {
      infoString += `${t('communitySettings.tabs.eventTypes')}: ${t(
        'common.all',
      )}; `;
    } else if (filters.eventTypes.length !== 0) {
      infoString += `${t(
        'communitySettings.tabs.eventTypes',
      )}: ${filters.eventTypes.map((i) => i.label).join(', ')}; `;
    }

    if (group?.name) {
      infoString += `${t('group.groups')}: ${group.name}; `;
    } else if (filters.groups.length === community?.groups?.length) {
      infoString += `${t('group.groups')}: ${t('common.all')}; `;
    } else if (filters.eventTypes.length !== 0) {
      infoString += `${t('group.groups')}: ${filters.groups
        .map((i) => i.label)
        .join(', ')}; `;
    }

    if (type === STATISTICS_TYPES.APPOINTMENT_SCHEDULE) {
      if (filters.visibleColumns.length === 6) {
        infoString += `${t('common.columns')}: ${t('common.all')}`;
      } else {
        infoString += `${t('common.hiddenColumns')}: ${Object.values(
          APPOINTMENT_SCHEDULE_COLUMNS,
        )
          .filter((i) => !filters.visibleColumns.includes(i))
          .map((i) => columnsList.find((j) => j.value === i)?.label)
          .join(', ')}; `;
      }
    }

    if (type === STATISTICS_TYPES.MEMBER_FIGURES) {
      if (
        (groupId && filters.members?.length === group?.users?.data?.length) ||
        filters.members?.length === membersOptions?.length
      ) {
        infoString += `${t('community.members')}: ${t('common.all')}; `;
      } else if (filters.members?.length !== 0) {
        infoString += `${t('community.members')}: ${filters.members
          ?.map((i) => i.label)
          .join(', ')}; `;
      }
    }

    return infoString;
  }, [filters, group, type, groupId, membersOptions]);

  const handlePaginationClick = (page: number) => {
    setFilters({
      ...filters,
      page,
    });
  };

  const openExportTooltip = () => {
    setExportTooltipShown(true);
  };

  const closeExportTooltip = () => {
    setExportTooltipShown(false);
  };

  const fileExportRequestBody = useMemo(() => {
    const from =
      filters.dateRange.value === 'dateRange'
        ? moment(filters.from)
        : (filters.dateRange.value[0] as Moment);
    const to =
      filters.dateRange.value === 'dateRange'
        ? moment(filters.to)
        : (filters.dateRange.value[1] as Moment);
    const body = {
      communityId: community.id,
      from,
      to,
      groupIds: groupId ? [groupId] : filters.groups?.map((i) => i.value) || [],
      eventTypeIds: filters.eventTypes?.map((i) => i.value) || [],
      visibleColumns: filters.visibleColumns,
      excludeCommunityEvents: !!groupId,
    };

    return body;
  }, [filters, community, group]);

  const handleExportXlsxClick = async () => {
    setExportFileLoading(true);

    try {
      if (type === STATISTICS_TYPES.APPOINTMENT_SCHEDULE) {
        const data = await getAppointmentScheduleStatisticsXlsx(
          fileExportRequestBody,
        );

        const link = document.createElement('a');

        link.href =
          'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
          data;

        // const url = window.URL.createObjectURL(new Blob([data]));

        // link.href = url;

        link.download = `${t('statistics.appointmentSchedule')} - ${
          community.name
        } (${moment(fileExportRequestBody.from).format(
          'DD.MM.YYYY',
        )} - ${moment(fileExportRequestBody.to).format('DD.MM.YYYY')}).xlsx`;

        return link.click();
      } else if (type === STATISTICS_TYPES.MEMBER_FIGURES) {
        await getMemberFiguresStatisticsXlsx({
          ...fileExportRequestBody,
          excludeCommunityEvents: false,
          memberIds: filters.members.map((m) => m.value) || [],
        });
        showInfoPopup({
          type: 'info',
          size: 'md',
          title: t('statistics.memberFiguresExportSuccess.title'),
          message: `${t('statistics.memberFiguresExportSuccess.message')}\n\n${
            profile.email
          }\n`,
          loop: true,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setExportFileLoading(false);
      closeExportTooltip();
    }
  };

  const handleExportCsvClick = async () => {
    setExportFileLoading(true);

    try {
      if (type === STATISTICS_TYPES.APPOINTMENT_SCHEDULE) {
        const data = await getAppointmentScheduleStatisticsCsv(
          fileExportRequestBody,
        );

        const link = document.createElement('a');
        const href = URL.createObjectURL(data);

        link.href = href;

        link.download = `${t('statistics.appointmentSchedule')} - ${
          community.name
        } (${moment(fileExportRequestBody.from).format(
          'DD.MM.YYYY',
        )} - ${moment(fileExportRequestBody.to).format('DD.MM.YYYY')}).csv`;

        return link.click();
      } else if (type === STATISTICS_TYPES.MEMBER_FIGURES) {
        await getMemberFiguresStatisticsCsv({
          ...fileExportRequestBody,
          excludeCommunityEvents: false,
          memberIds: filters.members.map((m) => m.value) || [],
        });
        showInfoPopup({
          type: 'info',
          size: 'md',
          title: t('statistics.memberFiguresExportSuccess.title'),
          message: `${t('statistics.memberFiguresExportSuccess.message')}\n\n${
            profile.email
          }\n`,
          loop: true,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setExportFileLoading(false);
      closeExportTooltip();
    }
  };

  const totalCount = useMemo(() => {
    if (type === STATISTICS_TYPES.APPOINTMENT_SCHEDULE) {
      return appointmentScheduleData?.totalCount;
    }

    if (type === STATISTICS_TYPES.MEMBER_FIGURES) {
      return memberFiguresData?.totalCount;
    }

    return 0;
  }, [type, appointmentScheduleData, memberFiguresData]);

  return (
    <Container>
      <FieldWrapper>
        <SelectInputControlled
          label={t('statistics.tableType')}
          width="100%"
          options={tableTypeOptions}
          maxMenuHeight={250}
          value={{ value: type, label: t(`statistics.${type}`) }}
          onChange={(v) => setType(v.value)}
        />
      </FieldWrapper>
      <FilterTooltip
        InnerComponent={
          <FilterTooltipInnerComponent
            type={type}
            filters={filters}
            columnsList={columnsList}
            membersOptions={membersOptions}
            setFilters={setFilters}
          />
        }
        position="bottom-center"
        tooltipStyle={{
          maxWidth: filterTooltipWidth,
          width: filterTooltipWidth,
        }}
        isVisible={filtersVisible}
        closeTooltip={() => setFiltersVisible(false)}
      >
        <StyledLabel>{t('filters.clickToSelect')}</StyledLabel>
        <Button width="100%" onClick={() => setFiltersVisible(!filtersVisible)}>
          {t('filters.showFilters')}
        </Button>
      </FilterTooltip>
      <Tooltip
        isVisible={exportTooltipShown}
        text={
          <ExportTooltipOptions>
            <div onClick={handleExportXlsxClick}>
              <XlsxIcon />
              XLSX
            </div>
            <hr />
            <div onClick={handleExportCsvClick}>
              <CsvIcon />
              CSV
            </div>
            {exportFileLoading ? (
              <ExportTooltipLoader>
                <Loader size="30px" type={null} />
              </ExportTooltipLoader>
            ) : null}
          </ExportTooltipOptions>
        }
        isHover={false}
        auto={false}
        noArrow
        position="bottom-center"
        opacity="1"
        tooltipStyle={{
          width: exportTooltipWidth,
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 0px 4px 0px #6a6a6a7d',
          border: 'none',
          color: '#000000',
          zIndex: 100,
        }}
        closeTooltip={closeExportTooltip}
      >
        <FieldWrapper>
          <StyledLabel>{t('filters.downloadXlsxOrCsv')}</StyledLabel>
          <Button
            width="100%"
            onClick={
              exportTooltipShown ? closeExportTooltip : openExportTooltip
            }
          >
            Export
          </Button>
        </FieldWrapper>
      </Tooltip>
      {totalCount && Math.ceil((totalCount || 0) / filters.limit) > 1 ? (
        <Pagination
          page={filters.page}
          totalCount={totalCount || 0}
          itemsPerPage={filters.limit}
          onClick={handlePaginationClick}
        />
      ) : null}
      <FiltersInfo>{filtersInfo}</FiltersInfo>
    </Container>
  );
};

export default AppointmentScheduleFilters;

import styled from 'styled-components';
import { breakpoints } from 'theme';

export const ContainerWrapper = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  /* flex: 1; */
`;

export const SelectBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  gap: 24px;
  /* height: 100%; */
  border-right: ${({ rightBorder, theme }) =>
    rightBorder && `1px solid ${theme.color.lightGrey}`};
  padding: ${({ padding = '0px' }) => padding};
`;

export const SelectBoxWrapper = styled.div`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth !== 0 ? maxWidth + 'px' : '100%')};
`;

export const VerticalLine = styled.div`
  height: 60px;
  width: 1px;
  background-color: ${({ theme }) => theme.color.lightGrey};
  align-self: flex-end;
`;

export const ElementWrapper = styled.div`
  width: 340px;
`;

export const PushSettingsSection = styled.div`
  display: flex;
`;

export const CheckBoxWrapper = styled.div`
  /* position: absolute;
  top: -38px; */
  /* right: 0; */

  & > label {
    font-size: 12px;
    color: #8298ab;
    font-weight: 600;
    padding-top: 1px;
  }
`;

export const PushMessageContainer = styled.div`
  position: relative;
  width: 100%;
  /* margin-top: 40px; */
`;

export const MessageContainer = styled.div``;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
`;

export const MessageWrapper = styled.div`
  margin-top: 20px;
  /* padding: 16px 8px; */
  padding-top: 20px;
  /* border: 1px solid ${({ theme }) => theme.color.lightGrey}; */
  border-radius: 16px;
  width: 100%;
  @media ${breakpoints.downSmPlus} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const LinkTextWithButton = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: flex-start;
  text-align: ${({ textAlign = 'left' }) => textAlign};
`;

LinkTextWithButton.Link = styled.a`
  color: ${({ theme }) => theme.color.brightTurquoise};
  font-size: 16px;
  padding: 4px;
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;
LinkTextWithButton.Button = styled.button`
  background-color: ${({ theme }) => theme.color.brightTurquoise};
  color: black;
  border-radius: 16px;
  padding: 6px 12px;
  border: none;
  width: fit-content;
  cursor: pointer;
`;

export const LoaderWrapper = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import React from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';

import { Button, CloseButton } from '../../index';

import {
  Wrapper,
  StyledPopup,
  StyledHeader,
  StyledBody,
  StyledFooter,
} from './styled';

const FeaturePopup = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      {createPortal(
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
        /* @ts-ignore*/
        <AnimatePresence>
          {isOpen && (
            <Wrapper
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <StyledPopup>
                <CloseButton onClick={onClose} />
                <PerfectScrollbar
                  options={{
                    wheelSpeed: 0.1,
                    wheelPropagation: false,
                    minScrollbarLength: 4,
                  }}
                >
                  <StyledHeader>
                    <div>
                      <img
                        src="https://gumb-cdn-prod.s3.eu-central-1.amazonaws.com/Gumb-%E2%80%93-Web-App+-+2023-08-14T154151.826.png"
                        alt="Member figures export"
                      />
                    </div>
                  </StyledHeader>
                  <StyledBody>
                    <h3>{t('featurePopup.title')}</h3>
                    <p>{t('featurePopup.p1')}</p>
                    <p>{t('featurePopup.p2')}</p>
                  </StyledBody>
                  <StyledFooter>
                    <Button
                      variant="text"
                      buttonStyles={{
                        fontSize: '17px',
                        color: '#304659',
                        textTransform: 'none',
                        width: 'max-content',
                        padding: '0 10px',
                      }}
                      onClick={onClose}
                    >
                      {t('featurePopup.b1')}
                    </Button>
                    <Button
                      buttonStyles={{
                        fontSize: '17px',
                        color: '#304659',
                        textTransform: 'none',
                      }}
                      onClick={() => {
                        navigate('overall/#memberPresence', { replace: true });
                        onClose();
                      }}
                    >
                      {t('featurePopup.b2')}
                    </Button>
                  </StyledFooter>
                </PerfectScrollbar>
              </StyledPopup>
            </Wrapper>
          )}
        </AnimatePresence>,
        document.getElementById('modal'),
      )}
    </>
  );
};

export default FeaturePopup;

import styled from 'styled-components';
import { breakpoints } from 'theme';

export const StyledMessageSection = styled.section`
  /* padding: 0 50px; */
  position: relative;
  padding-right: 50px;
  /* border: 2px solid black; */

  form textarea:last-of-type {
    margin-bottom: 14px;
  }

  @media ${breakpoints.downSmPlus} {
    padding-right: 30px;
  }
`;

export const StyledForm = styled.form`
  position: relative;
  @media ${breakpoints.downMd} {
    padding-left: 20px;
  }
  @media ${breakpoints.downSmPlus} {
    padding-left: 0px;
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const StyledCheckboxWrapper = styled.div`
  position: absolute;
  top: -38px;
  right: 0;

  & > label {
    font-size: 12px;
    color: #8298ab;
    font-weight: 600;
    padding-top: 1px;
  }
`;

import styled from 'styled-components';

import { breakpoints } from 'theme';

export const StyledChangeSidebar = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.brightTurquoise};
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    height: 12px;
    margin-right: 5px;
  }
`;

export const StyledTitle = styled.p`
  font-size: 24px;
  line-height: 26px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-weight: 800;
`;

export const StyledIconTextBlock = styled.div`
  display: grid;
  grid-template-columns: 33px auto;

  & > svg {
    width: 18px;
    height: auto;
  }
`;
export const BlackDot = styled.div`
  width: 10px;
  height: 10px;
  margin-top: 5.3px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.black};
`;

export const StyledTextBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledText = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.shark};
  white-space: break-spaces;
`;

export const StyledSmallText = styled.p`
  font-size: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.baliHai};
`;

export const StyledButtonsBlock = styled.div`
  display: flex;
  justify-content: center;
  gap: 18px;
  margin-top: 22px;

  @media ${breakpoints.downMd} {
    & > label {
      width: 100%;
    }
  }
`;

export const NumberInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  height: 68px;
  @media ${breakpoints.downMd} {
    & > div > div {
      max-width: none;
    }
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.color.baliHai};
  :last-child {
    label {
      margin: 0px !important;
      line-height: ${({ onlyInputNumber }) =>
        onlyInputNumber ? '11px' : '32px'};
      font-size: ${({ onlyInputNumber }) =>
        onlyInputNumber ? '10px' : '12px'};
      text-align: center;
      @media (max-width: 349px) {
        line-height: 11px;
      }
    }
    border-right: none;
    padding: ${({ onlyInputNumber }) => (onlyInputNumber ? '0px 4px' : '0px')};
    div {
      flex-direction: ${({ onlyInputNumber }) =>
        onlyInputNumber ? 'column-reverse' : 'column'} !important;
      width: ${({ onlyInputNumber }) =>
        onlyInputNumber ? '86% !important' : '120px !important'};
      margin-top: ${({ onlyInputNumber }) =>
        onlyInputNumber ? '-4px' : '0px'} !important;
      gap: ${({ onlyInputNumber }) =>
        onlyInputNumber ? '6px' : '0px'} !important;
      img:first-child {
        left: ${({ onlyInputNumber }) =>
          onlyInputNumber ? '5px !important' : '9px !important'};
      }
      img:last-child {
        right: ${({ onlyInputNumber }) =>
          onlyInputNumber ? '5px !important' : '12px !important'};
      }
      align-items: center !important;
      img {
        top: calc(50% - 9px) !important;
      }
      input {
        border-radius: 99999px;
      }
    }
  }
`;

import React, {
  CSSProperties,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
} from 'react';
import { v4 as uuid } from 'uuid';
import { MenuItem, PlusButton, StyledPlusIcon, Tooltip } from 'components';
import { CommunityRoles } from 'types';

import { PlusButtonWrapper, StyledList, StyledMenuListHeader } from './styled';

interface CommunityMenuListWrapperPops {
  title: string;
  icon: ReactNode;
  to?: string;
  isAddButtonContained?: boolean;
  isPlusIcon?: boolean;
  toolTipText?: string;
  userRole?: CommunityRoles;
  children: JSX.Element | JSX.Element[];
  onClick?: () => void;
  iconStyles?: CSSProperties;
}

const CommunityMenuListWrapper: FC<CommunityMenuListWrapperPops> = ({
  title,
  icon,
  isAddButtonContained,
  children,
  to = '',
  isPlusIcon,
  userRole,
  iconStyles,
  toolTipText,
  onClick = () => {},
}) => {
  const tooltipProps = {
    tooltipStyle: {
      fontSize: 12,
      maxWidth: 200,
    },
  };
  const handleOnClick = () => {
    onClick();
  };
  const isAdminOrOwner =
    userRole === CommunityRoles.ADMIN || userRole === CommunityRoles.OWNER;
  return (
    <StyledList>
      <StyledMenuListHeader isAddButtonNotContained={!isAddButtonContained}>
        <MenuItem
          to={to}
          title={title}
          icon={icon}
          left
          iconStyles={iconStyles}
        />
        {isPlusIcon && isAdminOrOwner && (
          <StyledPlusIcon onClick={handleOnClick} />
        )}
        {isAddButtonContained && isAdminOrOwner && (
          <PlusButtonWrapper>
            <PlusButton
              text={toolTipText}
              tooltipStyle={tooltipProps.tooltipStyle}
              position="top-center"
              width="28px"
              contained={isAddButtonContained}
              onClick={onClick}
              adjustToolTipPosition={{ x: -0.7 }}
            />
          </PlusButtonWrapper>
        )}
      </StyledMenuListHeader>
      {children}
    </StyledList>
  );
};

export default CommunityMenuListWrapper;

import { EventReply, EventTaskReply } from './eventReply';
import { Community, CommunityPermission } from './community';
import { Group, GroupPermission } from './group';
import { EventType } from './eventType';
import { Document } from './document';

export enum RegistrationDeadlineModificator {
  WEEK = 'week',
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
  HOUR = 'hour',
}

export enum RepeatedEventModificator {
  WEEK = 'week',
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
  HOUR = 'hour',
}

export type RegistrationDeadline = {
  modificator: RegistrationDeadlineModificator;
  value: number;
};

export type RepeatedEventRule = {
  intervalStep: number;
  invetrvalModificator: RepeatedEventModificator;
  perWeek?: number;
  perMonth?: number;
};

export type Event = {
  allDay: boolean;
  communityId: string;
  createdAt: string;
  defaultEventReplies: string[];
  defaultEventTaskReplies: string[];
  deletedAt: string | null;
  description: string;
  documents: Document[];
  endDate: string | null;
  eventSeriesId: string;
  eventTypeId: string;
  from: string;
  groupId: string | null;
  id: string;
  isActive: boolean;
  isAfterRegDeadline: boolean;
  isDST: boolean;
  isDisabled: boolean;
  isRepeated: boolean;
  isResponseRequired: boolean;
  location: string;
  recipients: string[];
  registrationDeadline: RegistrationDeadline | null;
  repeatedRules: RepeatedEventRule;
  subEventReplies: EventReply[];
  subEventTaskReplies: EventTaskReply[];
  tasks: string[];
  title: string;
  to: string;
  updatedAt: string;
  userId: string;
  optionalMessages?: { timeStamp: string; message: string }[];
  link?: string;
};

export type EventToRender = {
  id?: string;
  title: string | JSX.Element | JSX.Element[];
  dateString?: string;
  description?: string;
  time?: string | null;
  color?: string;
  width?: number;
  eventType?: string;
  recipients?: string[];
  date?: Date;
  tasks?: string[];
  subEventReplies?: EventReply[];
  subEventTaskReplies?: EventTaskReply[];
  isAfterRegDeadline?: boolean;
  placement?: string;
  onExportPDFClick?: () => void | null;
  isPlusIcon?: boolean;
  optionalMessage?: { timeStamp: string; message: string }[];
};

export type MyAppointmentsEvent = Event & {
  community: Community;
  communityPermission: CommunityPermission;
  group: (Group & { community: Community }) | null;
  groupPermission: GroupPermission | null;
  eventType: EventType;
};

import styled, { css } from 'styled-components';
import { EventCard } from 'pages/UserProfileEvents/styled';
import { theme, breakpoints } from 'theme';

export const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${({ height }) => height + 27 + 'px'});
  overflow: auto;

  &.members {
    overflow-y: scroll;
  }
`;

export const ContentWrapper = styled.div`
  padding: 20px 23px 36px 23px;
  // height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (min-width: 2300px) {
    justify-content: left;
    padding-left: 500px;
  }
  @media (max-width: 684px) {
    padding: 12px 12px 36px 12px;
  }
`;

export const ExportModuleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  /* text-align: center; */
  max-width: ${({ maxWidth = 'min(100%,550px)' }) => maxWidth};
  gap: ${({ customGap = '24px' }) => customGap};
  /* height: calc(100% - 80px); */
  overflow: auto;
  padding-top: 24px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 684px) {
    max-width: 100%;
  }
  @media (max-width: 346px) {
    padding-top: 24px;
  }
`;

export const MaxWidthWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ButtonLoaderWrapper = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: min('314px, 100%');
`;

export const ExportButtonWrapper = styled.div`
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin-top: 30px;
  min-width: 314px;
  & > button {
    svg path {
      fill: ${({ theme }) => theme.color.black};
    }
  }
  max-width: 100%;
  @media (max-width: 346px) {
    min-width: 100%;
  }
`;

export const ComponentTitle = styled.h4`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-weight: 700;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const DeleteButtonWrapper = styled.div`
  width: 100%;
  & > button {
    svg path {
      fill: black;
    }
  }
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 22px;
  @media (max-width: 684px) {
    padding: 0px 30px;
  }
  @media (max-width: 356px) {
    padding: 0px;
  }
`;

export const ComponentInfoText = styled.div`
  width: ${({ width = '100%' }) => width};
  font-size: 14px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-direction: column;
  text-align: ${({ textAlign = 'center' }) => textAlign};
  padding: 0px 11px;
  white-space: pre-wrap;
  ${({ customPadding }) =>
    customPadding &&
    css`
      padding: ${customPadding};
    `}
`;

export const SubTitleText = styled.p`
  padding: 0px 8%;
  margin-bottom: 16px;
  @media (max-width: 684px) {
    padding: 0px 0px;
  }
`;

export const StyledDescriptionList = styled.ul`
  text-align: left;
  position: relative;
  left: 12px;
  width: 97%;
  margin-top: ${({ marginTop = 0 }) => marginTop};
  @media (max-width: 356px) {
    left: 0px;
  }
`;

export const ImportButtonWrapper = styled.label`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

export const ImportButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 314px;
  height: 80px;
  border-radius: 8px;
  padding: 4px 16px;
  /* background-color: ${({ theme }) => theme.color.brightTurquoise}; */
  transition: all 0.2s ease;
  cursor: pointer;
  border: 1px dashed
    ${({ theme, error }) =>
      error ? theme.color.bittersweet : theme.color.brightTurquoise};
`;

export const FilePreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  font-size: 14px;
  font-weight: normal;
  color: ${({ theme }) => theme.color.brightTurquoise};
  svg {
    height: 30px;
    width: 30px;
  }
  svg path {
    fill: ${({ theme }) => theme.color.brightTurquoise};
  }
`;
FilePreview.Text = styled.span`
  margin-left: 50px;
`;

export const SuccessMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  /* left: -7px; */
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  width: 314px;
  height: auto;
  max-height: max-content;
  align-self: center;
  text-align: center;
`;

export const EventAndRecipientContainer = styled.div`
  flex-grow: 1;
  max-height: max-content;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  align-items: baseline;
  width: 100%;
  overflow-y: auto;
`;

export const CustomEventCard = styled(EventCard)`
  width: 167px;
  height: 150px;
  border-right: none;
  padding-top: 13px;
  justify-self: flex-start;
  align-self: flex-start;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }
`;

export const PostSuccessMessage = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: center;
  align-self: center;
  width: min(314px, 100%);
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const AnimationContainer = styled.div`
  width: 80px;
  height: 80px;
`;

export const FloatingBulkDelete = styled.div`
  position: absolute;
  right: 0px;
  min-width: 200px;
  max-width: 100%;
`;

export const SubSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: ${({ gap = '12px' }) => gap};
  margin-bottom: ${({ marginBottom = '24px' }) => marginBottom};
  margin-top: ${({ marginTop = 'none' }) => marginTop};
  width: min(314px, 100%);
  align-self: center;
  @media (max-width: 684px) {
    width: 100%;
    align-items: center;
    padding: 0px 20px;
  }
  @media (max-width: 346px) {
    padding: 0px;
    > button {
      width: 100% !important;
    }
  }
`;

export const ExportColumnsBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.color.lightGrey};
  gap: 8px;
  border-radius: 8px;
  margin: 49.5px 0px 22px 0px;
  align-self: center;
  position: relative;
  @media ${breakpoints.md} {
    width: 80%;
  }
`;
export const CopyAll = styled.div`
  display: flex;
  position: absolute;
  top: -30px;
  right: 50%;
  width: max-content;
  transform: translateX(50%);
`;

export const ExportColumnName = styled.span`
  border: 1px solid ${({ theme }) => theme.color.lightGrey};
  padding: 2px 5px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  border-radius: 0.5rem;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

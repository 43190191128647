import styled from 'styled-components';
import { breakpoints } from 'theme';

export const EventsList = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  transition: max-width 0.3s;
  min-height: 140px;
  /* padding-left: 5px; */
  padding-top: 5px;

  ::-webkit-scrollbar {
    display: none;
  }

  @media ${breakpoints.downMd} {
    padding-left: 20px;
  }
  @media ${breakpoints.downSmPlus} {
    padding-left: 0px;
  }
`;

export const EventCard = styled.div`
  min-width: 140px;
  width: 140px;
  margin: 0 3px;
  color: white;
  transition: transform 0.3s;
  cursor: pointer;

  &:first-of-type {
    margin-left: 0;
  }

  &:hover {
    transform: scale(1.03);
  }
`;

EventCard.Community = styled.div`
  height: 38px;
  display: flex;
  background: #ebf2f7;
  border-radius: 4px 4px 0 0;
  padding: 4px 8px;
`;

EventCard.Event = styled.div`
  height: 92px;
  background: ${({ color = '#8298AB' }) => color};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 0 0 4px 4px;
`;

EventCard.Community.ImageBlock = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 7px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

EventCard.Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

EventCard.EventType = styled.div`
  font-size: 8px;
  padding: 0 3px;
  background: #0000003b;
  display: inline-block;
  width: max-content;
  border-radius: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
  min-height: 12px;

  span {
    position: relative;
  }
`;

EventCard.SelectButton = styled.div`
  width: 13px;
  height: 13px;
  border: 1px solid white;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;

  &:before {
    content: '';
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: white;
    display: block;
    position: relative;
    top: 2px;
    left: 2px;
    opacity: ${({ selected }) => (selected ? 1 : 0)};
    transition: opacity 0.3s;
  }
`;

EventCard.EventTitle = styled.div`
  font-size: 10px;
  line-height: 13px;
  position: relative;
  height: auto;
  max-height: 39px;
  display: flex;
  align-items: center;

  span {
    overflow: hidden;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 54px;
    text-align: left;
    font-weight: 700;
    @media ${breakpoints.downMd} {
      font-weight: 600;
    }
  }
`;

EventCard.Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .icon-description {
    width: 14px;
    height: 14px;
    font-size: 10px;
    border-width: 1px;
  }
`;

EventCard.DateTime = styled.div`
  font-size: 8px;
  line-height: 10px;
  text-align: left;
`;

export const CommunityTitle = styled.p`
  font-size: 11px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: max-content;
  max-width: 89px;
`;

export const GroupTitle = styled.p`
  font-size: 8px;
  color: ${({ theme }) => theme.color.baliHai};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: max-content;
  max-width: 89px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FilterBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  & > div {
    width: 100%;
  }

  & > div:last-of-type {
    width: auto;
    margin: 10px auto;
  }
`;

export const FilterButton = styled.div`
  height: 39px;
  display: flex;
  align-items: center;
  margin: 0 15px;

  img {
    cursor: pointer;
  }
`;

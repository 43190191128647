import styled from 'styled-components';
import { breakpoints } from 'theme';

export const Container = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 25px;
  border-bottom: 1px solid ${({ theme }) => theme.color.porcelain};
  overflow: hidden;
  border-radius: 8px;
  transition: all 0.3s;
  :hover {
    background-color: ${({ theme }) => theme.color.blackSqueeze};
  }
  @media ${breakpoints.downSmPlus} {
    padding: 0px 10px;
  }
`;

export const ButtonsContainer = styled.div`
  width: calc(70px + 1vw);
  min-width: 70px;
  display: flex;
  row-gap: 20px;
  align-items: center;
  justify-content: space-between;
`;

import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { breakpoints } from 'theme';

export const StyledPopup = styled(motion.div)`
  width: 800px;
  position: relative;
  background: ${({ theme }) => theme.color.white};
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.color.brightTurquoise};
  ${({ popupStyles }) => popupStyles};

  @media ${breakpoints.md} {
    max-height: 95%;
  }

  @media ${breakpoints.downMd} {
    width: 94%;
  }

  & > button:first-of-type {
    position: absolute;
    top: 13px;
    right: 24px;
    z-index: 100;

    @media ${breakpoints.downSmPlus} {
      right: 16px;
    }

    svg path {
      fill: black;
      transition: fill 0.3s;
    }

    &:hover {
      svg path {
        fill: white;
      }
    }
  }
`;

export const StyledHeader = styled.div`
  background: ${({ theme }) => theme.color.brightTurquoise};
  padding: 30px 32px 0 32px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media ${breakpoints.downSmPlus} {
    padding: 30px 24px 0 24px;
  }

  & > div {
    width: 100%;
    display: flex;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    border: 2px solid black;
    border: none;

    img {
      width: 100%;
      max-height: 35vh;
      object-fit: contain;
    }
  }
`;

export const StyledBody = styled.div`
  padding: 24px 48px 0px 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media ${breakpoints.downSmPlus} {
    padding: 16px;
  }
  h3 {
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    margin-bottom: 12px;
    white-space: pre-wrap;
    color: ${({ theme }) => theme.color.pickledBluewood};
    span {
      font-size: 26px;
      color: ${({ theme }) => theme.color.pickledBluewood};
      @media ${breakpoints.downMd} {
        font-size: 20px;
        line-height: 40px;
        span {
          font-size: 20px;
        }
      }
    }

    @media ${breakpoints.downMd} {
      font-size: 24px;
      line-height: 40px;
      span {
        font-size: 20px;
      }
    }
  }

  p {
    font-size: 17px;
    line-height: 28px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.pickledBluewood};
    text-align: left;
    white-space: pre-wrap;
    /* 
    &:nth-of-type(2) {
      margin-top: 26px;
    } */

    @media ${breakpoints.downMd} {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 0 26px;
  margin-top: 28px;
  margin-bottom: 32px;
  & > button {
    @media ${breakpoints.smPlus} {
      height: 50px;
    }
  }

  @media ${breakpoints.lgPlus} {
    margin-top: 40px;
    margin-bottom: 44px;
  }

  @media ${breakpoints.downSmPlus} {
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 40px;
  }
`;

export const StyledAnchorTag = styled.a`
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  color: ${({ theme }) => theme.color.black};
  display: ${({ display }) => (display ? display : 'flex')};
  gap: 5px;
  border-bottom: 1px solid;
  width: max-content;
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  text-align: left;
  max-width: 100%;
  overflow-wrap: break-word;
`;

import { call, put, takeEvery } from 'redux-saga/effects';

import {
  doGetCommunityEventTypes,
  doDeleteCommunityEventType,
  doCreateCommunityEventType,
  doEditCommunityEventType,
} from 'store/actionCreators/currentCommunity';
import {
  requestCommunityEventTypes,
  requestDeleteCommunityEventType,
  requestCreateCommunityEventType,
  requestEditCommunityEventType,
} from 'services/events';
import {
  selectCommunitySubscriptionPending,
  selectIsCommunitySubscriptionActive,
} from 'store/selectors/subscriptions';
import { checkSubscription } from '../helper';

function* onGetCommunityEventTypes({
  payload,
}: ReturnType<typeof doGetCommunityEventTypes>) {
  try {
    const data = yield call(requestCommunityEventTypes, payload);
    yield put(doGetCommunityEventTypes.success({ data, communityId: payload }));
  } catch ({ response }) {
    yield put(doGetCommunityEventTypes.failure(response.data));
  }
}

function* onCreateCommunityEventType({
  payload,
}: ReturnType<typeof doCreateCommunityEventType>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const data = yield call(
      requestCreateCommunityEventType,
      payload.communityId,
      payload.data,
    );
    yield put(doCreateCommunityEventType.success(data));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doCreateCommunityEventType.failure(response.data));
  }
}

function* onEditCommunityEventType({
  payload,
}: ReturnType<typeof doEditCommunityEventType>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const { communityId, id } = payload;
    const data = yield call(
      requestEditCommunityEventType,
      communityId,
      id,
      payload.data,
    );
    yield put(doEditCommunityEventType.success(data));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doEditCommunityEventType.failure(response.data));
  }
}

function* onDeleteCommunityEventType({
  payload,
}: ReturnType<typeof doDeleteCommunityEventType>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    yield call(
      requestDeleteCommunityEventType,
      payload.communityId,
      payload.id,
    );
    yield put(doDeleteCommunityEventType.success(payload));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doDeleteCommunityEventType.failure(response.data));
  }
}

export default function* () {
  yield takeEvery(doGetCommunityEventTypes, onGetCommunityEventTypes);
  yield takeEvery(doDeleteCommunityEventType, onDeleteCommunityEventType);
  yield takeEvery(doCreateCommunityEventType, onCreateCommunityEventType);
  yield takeEvery(doEditCommunityEventType, onEditCommunityEventType);
}

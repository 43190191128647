import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useFieldArray, useForm } from 'react-hook-form';
import { setLocale } from 'yup';
import { useTranslation } from 'react-i18next';

import { CommunityValues, FIELDS } from './types';

export const useGeneralCommunitySettingsForm = (
  defaultValues: CommunityValues,
) => {
  const { t } = useTranslation();

  setLocale({
    mixed: { required: t('errors.required') },
    string: {
      min: ({ min }) => t('errors.min', { value: min }),
      max: ({ max }) => t('errors.max', { value: max }),
      url: ({ url }) => t('errors.url', { value: url }),
    },
  });

  const resolver: Resolver<CommunityValues> = yupResolver(
    yup.object().shape({
      [FIELDS.COMMUNITY_NAME]: yup.string().required().min(3).max(50).trim(),
      [FIELDS.CATEGORY]: yup.string().required().trim(),
      [FIELDS.PARTNERSHIPS]: yup.array().of(
        yup.object().shape({
          label: yup.string(),
          value: yup.string().nullable().url(),
        }),
      ),
      [FIELDS.LINKS]: yup.array().of(
        yup.object().shape({
          label: yup.string(),
          value: yup.string().required(),
        }),
      ),
      [FIELDS.WEBSITE]: yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      }),
    }),
  );

  const form = useForm<CommunityValues>({
    resolver,
    defaultValues,
  });

  const linksFieldProps = useFieldArray({
    name: FIELDS.LINKS,
    control: form.control,
  });

  const partnershipsFieldProps = useFieldArray({
    name: FIELDS.PARTNERSHIPS,
    control: form.control,
  });

  return {
    ...form,
    linksFieldProps,
    partnershipsFieldProps,
  };
};

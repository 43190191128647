import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Control, FieldError, useController } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';

import { InfoIcon } from 'static';

import { StyledWrapper, StyledLabel, StyledErrorMessage } from '../styled';
import {
  StyledSelect,
  StyledIconPlaceholder,
  StyledIcon,
  StyledOptionSingle,
} from './styled';

interface SelectInputControlledV3Props {
  name: string;
  control: Control;
  label?: string;
  info?: string;
  width?: string;
  options: { [key: string]: any }[];
  error?: FieldError;
  isDisabled?: boolean;
  placeholder?: string;
  maxMenuHeight?: number;
  defaultValue?: { [key: string]: any };
  onBlur?: () => void;
}

const OptionSingle = (props) => {
  const { innerProps, data } = props;
  return (
    <div {...innerProps}>
      <StyledOptionSingle>
        {data.icon &&
          (data.icon.length === 1 ? (
            <StyledIconPlaceholder>{data.icon}</StyledIconPlaceholder>
          ) : (
            <StyledIcon src={data.icon} alt={data.label} />
          ))}
        {data.label}
      </StyledOptionSingle>
    </div>
  );
};

const SelectInputControlledV3: FC<SelectInputControlledV3Props> = ({
  name,
  control,
  label,
  info,
  options,
  width,
  error,
  isDisabled,
  placeholder = '',
  maxMenuHeight = 115,
  defaultValue = null,
  onBlur = () => {},
}) => {
  const { t } = useTranslation();

  const { field } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <StyledWrapper>
      {label && (
        <StyledLabel error={error} htmlFor={name}>
          {label}{' '}
          {info && (
            <>
              <InfoIcon data-tip data-for="infoTip" />
              <ReactTooltip id="infoTip" effect="solid">
                {info}
              </ReactTooltip>
            </>
          )}
        </StyledLabel>
      )}
      <StyledSelect
        error={error}
        width={width}
        id={name}
        name={name}
        options={options}
        placeholder={placeholder}
        classNamePrefix="react-select"
        isDisabled={isDisabled}
        value={field.value}
        maxMenuHeight={maxMenuHeight}
        components={{ Option: OptionSingle }}
        onChange={field.onChange}
        onBlur={onBlur}
      />
      {error && (
        <StyledErrorMessage>
          {error.message || t(`errors.${error.type}`)}
        </StyledErrorMessage>
      )}
    </StyledWrapper>
  );
};

export default SelectInputControlledV3;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { wrap, isNil, isEmpty, some } from 'lodash/fp';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getMyId } from 'store/selectors/auth';

import {
  Community,
  CommunityRoles,
  CommunitySocialLink,
  CommunityUser,
  FullUser,
  GroupUser,
  Profile,
  UserNotificationItem,
} from 'types';

export const invokeIfArgExists = wrap((func, value) =>
  isNil(value) ? value : func(value),
);

export const dobBlockVisible = ({ dob }: Profile): boolean => !!dob;

export const phonesBlockVisible = ({ phones }: Profile): boolean =>
  !isEmpty(phones);

export const addressBlockVisible = ({
  street,
  region,
  postalCode,
  city,
  country,
}: Profile): boolean => !!(street || region || postalCode || city || country);

export const getNameFromEmail = (email: string): string =>
  email ? email.substring(0, email.indexOf('@')) : null;

export const getProfileName = ({
  firstName,
  lastName,
  profileName,
  email,
}: {
  firstName?: string;
  lastName?: string;
  profileName?: string;
  email?: string;
}): string => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  if (profileName) {
    return profileName;
  }

  if (email) {
    return getNameFromEmail(email);
  }

  return '👤';
};

export const getProfileNameCapital = ({
  firstName = '',
  lastName = '',
}): {
  firstName?: string;
  lastName?: string;
} => {
  const fName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
  const lName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
  return `${fName} ${lName}`.trim();
};

export const getCommunityLinks = (
  community: Community,
): CommunitySocialLink[] => {
  const socialLinks =
    community.links?.filter(
      (l) =>
        l.label === 'facebook' ||
        l.label === 'linkedin' ||
        l.label === 'instagram' ||
        l.label === 'twitter' ||
        l.label === 'youtube',
    ) || [];

  return socialLinks;
};

export const getCommonCommunityLinks = (
  community: Community,
): CommunitySocialLink[] => {
  const socialLinks =
    community.links?.filter(
      (l) =>
        !['facebook', 'linkedin', 'instagram', 'twitter', 'youtube'].includes(
          l.label,
        ),
    ) || [];

  return socialLinks;
};

export const getBasicColor = (
  type: 'secondary' | 'danger' | 'default' | 'text',
  theme: { color: { [key: string]: string } },
): string => {
  if (type === 'secondary' || type === 'text') return 'transparent';
  if (type === 'danger') return theme.color.bittersweet;
  if (type === 'default') return theme.color.brightTurquoise;

  return theme.color.brightTurquoise;
};

export const getCurrencySymbol = (code: string): string => {
  switch (code) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'CZK':
      return 'Kč';
    case 'PLN':
      return 'zł';
    case 'DKK':
    case 'SEK':
    case 'NOK':
      return 'kr';
    default:
      return code;
  }
};

export const checkUnreadNotifications = (
  notifications: UserNotificationItem[],
): boolean => some({ isRead: false }, notifications);

export const getKeyByValue = (
  object: { [key: string]: string },
  value: string,
): string => Object.keys(object).find((key) => object[key] === value);

export const canUserEditCommunity = (
  user: FullUser,
  community: Community,
): boolean => {
  const exactUserCommunity = user.communities.find(
    (c) => c.id === community.id,
  );
  const userRoleInCommunity = exactUserCommunity?.communityPermission?.role;

  return (
    userRoleInCommunity === CommunityRoles.ADMIN ||
    userRoleInCommunity === CommunityRoles.OWNER ||
    user.id === community.subscription?.userId
  );
};

export const validCurrencyCodes = {
  USD: 'USD',
  EUR: 'EUR',
  AUD: 'AUD',
  BRL: 'BRL',
  CAD: 'CAD',
  CNY: 'CNY',
  CZK: 'CZK',
  DKK: 'DKK',
  HKD: 'HKD',
  HUF: 'HUF',
  INR: 'INR',
  ILS: 'ILS',
  JPY: 'JPY',
  MYR: 'MYR',
  MXN: 'MXN',
  TWD: 'TWD',
  NZD: 'NZD',
  NOK: 'NOK',
  PHP: 'PHP',
  PLN: 'PLN',
  GBP: 'GBP',
  RUB: 'RUB',
  SGD: 'SGD',
  SEK: 'SEK',
  CHF: 'CHF',
  THB: 'THB',
};

// export const alphabetSortUsers = <T extends GroupUser | CommunityUser>(
//   arrayOfUsers: T[],
//   filteredChannels?: Channel<DefaultGenerics>[]
// ): T[] => {
//   if (arrayOfUsers || filteredChannels) {
//     const sorting = arrayOfUsers.sort(function (previous, current) {
//       return getProfileName(previous.profile)?.localeCompare(
//         getProfileName(current.profile),
//       );
//     });
//     if (!filteredChannels) {
//       return sorting;
//     }
//     const adf = sorting?.map((li) => {
//       const unreadCount = filteredChannels?.filter((ch) => ch.state?.members[li?.profile?.userId]);
//       return {
//         ...li, unreadCount: unreadCount?.[0]?.state?.unreadCount
//       };
//     });
//     return adf;
//   };
//   return arrayOfUsers;
// };

export const useAlphabetSortUsers = <T extends GroupUser | CommunityUser>(
  arrayOfUsers: T[],
  filteredChannels?: Channel<DefaultGenerics>[],
): T[] => {
  const authId = useSelector(getMyId);

  // useMemo hook to memoize the sorted and processed list of users
  const sortedUsers = useMemo(() => {
    if (arrayOfUsers.length === 0) return arrayOfUsers;

    const sorting = [...arrayOfUsers].sort((previous, current) => {
      return getProfileName(previous.profile)?.localeCompare(
        getProfileName(current.profile),
      );
    });

    if (!filteredChannels || filteredChannels.length === 0) {
      return sorting;
    }

    const processedUsers = sorting.map((user) => {
      const userId = user.profile?.userId;
      const me = user.profile?.userId === authId;
      const userChannels = filteredChannels.filter(
        (channel) => channel.state?.members[userId],
      );
      const unreadCount = userChannels.reduce(
        (count, channel) => count + (me ? 0 : channel.state?.unreadCount || 0),
        0,
      );
      return {
        ...user,
        unreadCount,
      };
    });

    return processedUsers.sort((previous, current) => {
      return current.unreadCount - previous.unreadCount;
    });
  }, [arrayOfUsers, filteredChannels]);

  return sortedUsers;
};

export const getMessage = (i18n) => {
  const message = {
    en: 'New message from',
    de: 'Neue Nachricht von',
    es: 'Nuevo mensaje de',
    it: 'Nuovo messaggio da',
    fr: 'Nouveau message de',
    ru: 'Новое сообщение от',
  };
  return message?.[i18n?.language || 'en'];
};

import { isEmpty, map } from 'lodash/fp';
import React from 'react';

import { FileItem, FileInput, ContentPlaceholder } from 'components';
import { hasCommunityAdminPermissions } from 'utils';

import { FileInputContainer, FilesList, Headline } from './styled';

const Documentation = ({
  t,
  documents,
  register,
  community,
  currentUser,
  setValue,
  onSubmit,
  onDelete,
  isUploading,
}) => {
  const uploadBlockVisible = hasCommunityAdminPermissions(
    currentUser.id,
    community,
  );

  return (
    <>
      {uploadBlockVisible && (
        <FileInputContainer>
          <FileInput
            text={t('documentation.chooseFile')}
            tipText={t('documentation.tipMessage')}
            register={register}
            setValue={setValue}
            handleSubmit={onSubmit}
            name="document"
            width="100%"
            height="100%"
            isLoading={isUploading}
          />
        </FileInputContainer>
      )}
      <Headline>{t('documentation.uploadedFiles')}</Headline>
      <FilesList uploadBlockVisible={uploadBlockVisible}>
        {!isEmpty(documents) ? (
          map(
            (document) => (
              <FileItem onDelete={onDelete} key={document.id} file={document} />
            ),
            documents,
          )
        ) : (
          <ContentPlaceholder />
        )}
      </FilesList>
    </>
  );
};

export default Documentation;

import styled, { css } from 'styled-components';

import { ColorPickerIcon } from 'static';

export const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & .sketch-picker {
    position: absolute !important;
    right: 0;
    top: 76px;
    z-index: 2;
  }
`;

export const StyledInput = styled.div`
  width: 100%;
  height: 39px;
  border: 1px solid ${({ theme }) => theme.color.botticelli};
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

export const StyledColorBlock = styled.div`
  margin: 4px 4px 4px 4px;
  height: calc(100% - 8px);
  border-radius: 2px;
  background-color: ${({ color }) => color};
  cursor: pointer;
  flex-grow: 1;
`;

export const StyledIcon = styled(ColorPickerIcon)`
  margin-left: 17px;
  margin-right: 13px;

  ${({ isPickerVisible, theme }) =>
    isPickerVisible &&
    css`
      path {
        fill: ${theme.color.brightTurquoise};
      }
    `}
`;

export const StyledLabel = styled.label`
  margin: 10px 0 2px 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.color.baliHai};
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Sidebar,
  SidebarHeader,
  SidebarContent,
  ColorInput,
  SidebarInputsWrapper,
  ColorInputBlock,
  InputsBlock,
} from 'components';
import SidebarFooter from '../common/SidebarFooter';
import TextInput from 'components/Inputs/TextInput/TextInputV2';
import CheckBox from 'components/CheckBox/CheckBoxV2';
import { useEditCommunityEventType } from './hooks';
import { FIELDS } from './form';
import { getEventTypeLoading } from 'store/selectors/eventTypes';
import { closeModal } from 'store/actionCreators/modal';
import { getData } from 'store/selectors/modal';
import { EventType } from 'types';

const EditCommunityEventType = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(getEventTypeLoading);
  const eventType = useSelector(getData) as EventType;

  const handleSidebarClose = () => {
    dispatch(closeModal());
  };

  const { register, onDelete, onSubmit, errors, control } =
    useEditCommunityEventType(eventType);

  return (
    <Sidebar>
      <form onSubmit={onSubmit}>
        <SidebarHeader
          label={t('event.editEvent')}
          onCloseClick={handleSidebarClose}
        />
        <SidebarContent height={'calc(100svh - 108px)'}>
          <SidebarInputsWrapper>
            <InputsBlock>
              <TextInput
                name={FIELDS.TITLE}
                register={register}
                label={t('event.eventTypeName')}
                width="225px"
                error={errors[FIELDS.TITLE]}
              />
              <ColorInputBlock>
                <ColorInput name={FIELDS.COLOR} control={control} />
              </ColorInputBlock>
            </InputsBlock>
            <CheckBox
              name={FIELDS.IS_RESPONSE_REQUIRED}
              control={control}
              label={t('event.responseRequired')}
            />
          </SidebarInputsWrapper>
        </SidebarContent>
        <SidebarFooter
          isLoading={isLoading}
          isDeleteButtonVisible={!!eventType}
          onDelete={onDelete}
        />
      </form>
    </Sidebar>
  );
};

export default EditCommunityEventType;

import { call, put, takeEvery } from 'redux-saga/effects';

import { doGetMyEvents } from 'store/actionCreators/profile';
import {
  doRequestEvents,
  doGetMailEvents,
  doRequestOverallEvents,
  doCreateEvent,
  doUpdateEvents,
  doDeleteEvents,
  doCreateCommunityEvent,
  doUpdateCommunityEvents,
  doDeleteCommunityEvents,
  doClearOverallEvents,
  doClearGroupEvents,
  doGetEventUploadStatus,
  doDeleteBulkEvents,
} from 'store/actionCreators/currentCommunity';
import {
  requestEvents,
  requestOverallEvents,
  createEvent,
  updateEvents,
  deleteEvents,
  createCommunityEvent,
  updateCommunityEvents,
  deleteCommunityEvents,
  requestMyEvents,
  requestMailEvents,
  getBulkEventUploadStatus,
  deleteBulkEvents,
  deleteGroupBulkEvents,
  getGroupBulkEventUploadStatus,
} from 'services/events';
import {
  selectCommunitySubscriptionPending,
  selectIsCommunitySubscriptionActive,
} from 'store/selectors/subscriptions';
import { checkSubscription } from '../helper';

function* onRequestEvents({ payload }: ReturnType<typeof doRequestEvents>) {
  try {
    if (payload.page === 1) {
      yield put(doClearGroupEvents.trigger());
    }

    const eventsData = yield call(
      requestEvents,

      payload.groupId,
      payload.from,
      payload.to,
      payload.eventTypeId,
      payload.filterValue,
      payload.page,
      payload.limit,
    );
    yield put(doRequestEvents.success(eventsData));
  } catch (e) {
    yield put(doRequestEvents.failure(e));
  }
}

function* onRequestOverallEvents({
  payload,
}: ReturnType<typeof doRequestOverallEvents>) {
  try {
    if (payload.page === 1) {
      yield put(doClearOverallEvents.trigger());
    }

    const data = yield call(
      requestOverallEvents,
      payload.communityId,
      payload.from,
      payload.to,
      payload.eventTypeId,
      payload.filterValue,
      payload.page,
      payload.limit,
    );

    yield put(doRequestOverallEvents.success(data));
  } catch (e) {
    yield put(doRequestOverallEvents.failure(e));
  }
}

function* onRequestMyEvents({ payload }: ReturnType<typeof doGetMyEvents>) {
  try {
    let noPendingEvents = false;
    let data = yield call(
      requestMyEvents,
      payload.from,
      payload.eventTypeId,
      payload.key,
      payload.page,
      payload.limit,
      payload.awaiting,
      payload.nomination,
    );
    if (
      data.totalUnrespondedEventsCount === 0 &&
      payload.unrespondedCount > 0 &&
      payload.awaiting
    ) {
      noPendingEvents = true;
      data = { ...data, noPendingEvents };
    }
    yield put(doGetMyEvents.success(data));
  } catch (e) {
    yield put(doGetMyEvents.failure(e));
  }
}

function* onGetMailEvents({ payload }: ReturnType<typeof doGetMailEvents>) {
  try {
    const data = yield call(
      requestMailEvents,
      payload.communityId,
      payload.from,
      payload.eventTypeId,
      payload.eventsFromGroups,
      payload.key,
      payload.page,
      payload.limit,
    );
    yield put(doGetMailEvents.success(data));
  } catch (e) {
    yield put(doGetMailEvents.failure(e));
  }
}

function* onCreateEvent({ payload }: ReturnType<typeof doCreateEvent>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );

    const data = yield call(
      createEvent,
      payload.groupId,
      payload.data,
      payload.filterFrom,
      payload.filterTo,
    );
    const dataToState = data.map((event) => ({
      ...event,
      subEventReplies: [],
      subEventTaskReplies: [],
    }));
    yield put(doCreateEvent.success(dataToState));
    yield call(payload.onSuccess);
  } catch (e) {
    yield put(doCreateEvent.failure(e));
    yield call(payload.onFailure, e.response.data.message);
  }
}

function* onUpdateEvents({ payload }: ReturnType<typeof doUpdateEvents>) {
  try {
    const response = yield call(
      updateEvents,
      payload.groupId,
      payload.data,
      payload.editType,
      payload.filterFrom,
      payload.filterTo,
    );
    yield put(doUpdateEvents.success({ response }));
    yield call(payload.onSuccess);
  } catch (e) {
    yield put(doUpdateEvents.failure(e));
    yield call(payload.onFailure, e.response.data.message);
  }
}

function* onDeleteEvents({ payload }: ReturnType<typeof doDeleteEvents>) {
  try {
    const response = yield call(
      deleteEvents,
      payload.groupId,
      payload.eventId,
      payload.editType,
      payload.eventSeriesId,
      payload.from,
      payload.notify,
    );
    yield call(payload.onSuccess);
    yield put(doDeleteEvents.success({ ids: response.data }));
  } catch (e) {
    yield put(doDeleteEvents.failure(e));
    yield call(payload.onFailure, e.response.data.message);
  }
}

function* onCreateCommunityEvent({
  payload,
}: ReturnType<typeof doCreateCommunityEvent>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );

    const data = yield call(
      createCommunityEvent,
      payload.communityId,
      payload.data,
      payload.filterFrom,
      payload.filterTo,
    );
    const dataToState = data.map((event) => ({
      ...event,
      subEventReplies: [],
      subEventTaskReplies: [],
    }));
    yield put(doCreateCommunityEvent.success(dataToState));
    yield call(payload.onSuccess);
  } catch (e) {
    yield put(doCreateCommunityEvent.failure(e));
    yield call(payload.onFailure, e.response.data.message);
  }
}

function* onUpdateCommunityEvents({
  payload,
}: ReturnType<typeof doUpdateCommunityEvents>) {
  try {
    const response = yield call(
      updateCommunityEvents,
      payload.communityId,
      payload.data,
      payload.editType,
      payload.filterFrom,
      payload.filterTo,
    );
    yield put(doUpdateCommunityEvents.success({ response }));
    yield call(payload.onSuccess);
  } catch (e) {
    yield put(doUpdateCommunityEvents.failure(e));
    yield call(payload.onFailure, e.response.data.message);
  }
}

function* onDeleteCommunityEvents({
  payload,
}: ReturnType<typeof doDeleteCommunityEvents>) {
  try {
    const response = yield call(
      deleteCommunityEvents,
      payload.communityId,
      payload.eventId,
      payload.editType,
      payload.eventSeriesId,
      payload.from,
      payload.notify,
    );
    yield call(payload.onSuccess);
    yield put(doDeleteCommunityEvents.success({ ids: response.data }));
  } catch (e) {
    yield put(doDeleteCommunityEvents.failure(e));
    yield call(payload.onFailure, e.response.data.message);
  }
}

function* onGetEventUploadStatus({ payload }) {
  try {
    let data;
    if (payload.groupId) {
      data = yield call(getGroupBulkEventUploadStatus, payload.groupId);
    } else {
      data = yield call(getBulkEventUploadStatus, payload.communityId);
    }
    yield put(
      doGetEventUploadStatus.success({
        uploadEventsCount: data.eventBulkLength,
        uploadStatus: data.uploadStatus,
      }),
    );
  } catch (err) {
    console.log(err);
  }
}

function* onDeleteBulkEvents({
  payload,
}: ReturnType<typeof doDeleteBulkEvents>) {
  try {
    let newUploadStatus: any;
    if (payload.groupId) {
      newUploadStatus = yield call(deleteGroupBulkEvents, payload.groupId);
    } else {
      newUploadStatus = yield call(deleteBulkEvents, payload.communityId);
    }
    yield call(payload.onSuccess);
    yield put(doDeleteBulkEvents.success(newUploadStatus));
  } catch (err) {
    console.log(err);
  }
}

export default function* () {
  yield takeEvery(doRequestEvents, onRequestEvents);
  yield takeEvery(doRequestOverallEvents, onRequestOverallEvents);
  yield takeEvery(doGetMyEvents, onRequestMyEvents);
  yield takeEvery(doGetMailEvents, onGetMailEvents);
  yield takeEvery(doCreateEvent, onCreateEvent);
  yield takeEvery(doUpdateEvents, onUpdateEvents);
  yield takeEvery(doDeleteEvents, onDeleteEvents);
  yield takeEvery(doCreateCommunityEvent, onCreateCommunityEvent);
  yield takeEvery(doUpdateCommunityEvents, onUpdateCommunityEvents);
  yield takeEvery(doDeleteCommunityEvents, onDeleteCommunityEvents);
  yield takeEvery(doGetEventUploadStatus, onGetEventUploadStatus);
  yield takeEvery(doDeleteBulkEvents, onDeleteBulkEvents);
}

import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Community } from 'types';

import { Button, Loader } from 'components';
import { CopyIcon, SuccessIcon, XlsxIcon } from 'static';
import { exportFileData } from 'utils/importCsv';
import {
  ButtonLoaderWrapper,
  ComponentInfoText,
  ComponentTitle,
  CopyAll,
  DeleteButtonWrapper,
  ExportButtonWrapper,
  ExportColumnName,
  ExportColumnsBlock,
  ExportModuleWrapper,
  FloatingBulkDelete,
  MaxWidthWrapper,
  StyledDescriptionList,
  SubTitleText,
  TextWrapper,
} from './styles';
import { ROUTES } from 'configs';
import { StepsContext } from 'components/Steps';
import { getSampleCsvFile } from 'services/events';
import { EXPORT_FIELDS, exportFields } from 'constants/export_csv';
import moment from 'moment';
import 'moment/locale/en-in';
import { hasCommunityOrGroupAdminPermissions } from 'utils';
import { useSelector } from 'react-redux';
import { getCurrentUserInCommunity } from 'store/selectors/currentCommunity';

type Props = {
  communityDetails: Community;
};

const ExportSampleCsv: React.FC<Props> = ({ communityDetails }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [copiedField, setCopiedField] = useState(null);
  const [exportButtonLoader, setExportButtonLoader] = useState<boolean>(false);
  const currentUser = useSelector(getCurrentUserInCommunity);

  const {
    allowNextStep,
    allowPreviousStep,
    isLastStep,
    setCondition,
    currentStep,
    setCurrentStepTo,
  } = useContext(StepsContext);

  const defaultData = {
    [EXPORT_FIELDS.TITLE]: t('bulkUploadEvents.exportDataSample.event_name'),
    [EXPORT_FIELDS.EVENT_TYPE_NAME]: t(
      'bulkUploadEvents.exportDataSample.event_type_name',
    ),
    [EXPORT_FIELDS.LOCATION]: t(
      'bulkUploadEvents.exportDataSample.event_location',
    ),
    [EXPORT_FIELDS.DATE]: moment().add(1, 'd').format('DD.MM.YYYY'),
    [EXPORT_FIELDS.FROM]: moment()
      .startOf('isoWeek')
      .hours(9)
      .minute(30)
      .format('HH:mm'),
    [EXPORT_FIELDS.TO]: moment().startOf('isoWeek').hours(22).format('HH:mm'),
    [EXPORT_FIELDS.DESCRIPTION]: t(
      'bulkUploadEvents.exportDataSample.event_description',
    ),
    // [EXPORT_FIELDS.ALL_DAY]: 'no',
  };
  const handleRedirect = () => {
    if (
      !hasCommunityOrGroupAdminPermissions(currentUser.id, communityDetails)
    ) {
      return;
    }
    navigate(
      `${ROUTES.COMMUNITY}/${communityDetails.id}/${ROUTES.COMMUNITY_SETTINGS}/#event-types`,
    );
  };

  const handleExportClick = async () => {
    if (
      !hasCommunityOrGroupAdminPermissions(currentUser.id, communityDetails)
    ) {
      return;
    }
    setExportButtonLoader(true);
    const exportData: {
      [EXPORT_FIELDS.TITLE]: string;
      [EXPORT_FIELDS.EVENT_TYPE_NAME]: string;
      [EXPORT_FIELDS.LOCATION]: string;
      [EXPORT_FIELDS.DATE]: string;
      [EXPORT_FIELDS.FROM]: string;
      [EXPORT_FIELDS.TO]: string;
      [EXPORT_FIELDS.DESCRIPTION]: string;
    } = defaultData;

    const binaryXlsxData = await getSampleCsvFile([exportData]);

    exportFileData(
      binaryXlsxData,
      `${communityDetails.name}.xlsx`,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    );
    setExportButtonLoader(false);
  };
  let timeoutId = null;
  const copyText = async (text, index) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setCopiedField(null);
    await navigator.clipboard.writeText(text);
    setCopiedField(index);
    timeoutId = setTimeout(() => setCopiedField(null), 1500);
  };

  const copyAll = async () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setCopiedField(null);
    const copyText = exportFields.join(' ');
    await navigator.clipboard.writeText(copyText.toString());
    setCopiedField(-1);
    timeoutId = setTimeout(() => setCopiedField(null), 1500);
  };

  useEffect(() => {
    const eventTypes = communityDetails?.eventTypes?.filter(
      (eventType) => !eventType?.deletedAt,
    );
    setCondition(eventTypes?.length > 0 ? true : false, true);
  }, []);

  return (
    <ExportModuleWrapper customGap="8px">
      <MaxWidthWrapper>
        <TextWrapper>
          <ComponentTitle>
            {t('bulkUploadEvents.stepOne.componentTitle')}
          </ComponentTitle>

          <ComponentInfoText customPadding={'0px 28px'}>
            <SubTitleText>
              {t('bulkUploadEvents.stepOne.componentDescription')}
            </SubTitleText>
            <StyledDescriptionList>
              {/* <li>{t('bulkUploadEvents.stepOne.p1')}</li>
              <li>{t('bulkUploadEvents.stepOne.p2')}</li>
              <li>{t('bulkUploadEvents.stepOne.p3')}</li>
              <li>{t('bulkUploadEvents.stepOne.p4')}</li> */}
              <li
                dangerouslySetInnerHTML={{
                  __html: t('bulkUploadEvents.stepOne.p5'),
                }}
              ></li>
            </StyledDescriptionList>
            <ExportColumnsBlock>
              {exportFields.map((li, index) => (
                <ExportColumnName
                  key={index + li}
                  onClick={() => copyText(li, index)}
                >
                  {li}
                </ExportColumnName>
              ))}
              <CopyAll>
                <ExportColumnName
                  onClick={copyAll}
                  style={{
                    backgroundColor: '#ebf2f7',
                    fontWeight: '600',
                    padding: '2px 10px 3px 10px',
                  }}
                >
                  <span style={{ borderBottom: '1px solid black' }}>
                    {t('bulkUploadEvents.stepOne.copy')}
                  </span>
                  {copiedField === -1 ? (
                    <SuccessIcon
                      width={12}
                      height={12}
                      style={{ marginLeft: '3px' }}
                    />
                  ) : (
                    <CopyIcon
                      width={12}
                      height={12}
                      style={{ marginLeft: '3px' }}
                    />
                  )}
                </ExportColumnName>
              </CopyAll>
            </ExportColumnsBlock>
            <StyledDescriptionList>
              <li>{t('bulkUploadEvents.stepOne.p6')}</li>
              <li>{t('bulkUploadEvents.stepOne.p7')}</li>
            </StyledDescriptionList>
          </ComponentInfoText>
        </TextWrapper>
      </MaxWidthWrapper>
      <MaxWidthWrapper>
        <ExportButtonWrapper>
          <Button
            onClick={handleRedirect}
            width="max(200px, 100%)"
            buttonStyles={{ minWidth: '200px' }}
            variant="secondary"
          >
            {t('bulkUploadEvents.stepOne.eventTypes')}
          </Button>

          {exportButtonLoader ? (
            <ButtonLoaderWrapper>
              <Loader type="button" size="40px" />
            </ButtonLoaderWrapper>
          ) : (
            <Button
              onClick={() => {
                handleExportClick();
              }}
              IconComponent={XlsxIcon}
              width="max(200px, 100%)"
              disabled={!allowNextStep}
              variant="primary"
              style={{ backgroundColor: 'rgb(235, 242, 247)' }}
            >
              {t('bulkUploadEvents.stepOne.template')}
            </Button>
          )}
        </ExportButtonWrapper>
      </MaxWidthWrapper>
    </ExportModuleWrapper>
  );
};

export default ExportSampleCsv;

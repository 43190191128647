import React, { FC, MutableRefObject } from 'react';
import { noop } from 'lodash/fp';
import { v4 as uuid } from 'uuid';

import { Button, CloseButton } from 'components';

import { StyledPopup, Wrapper, Message, ButtonContainer } from './styled';

interface PopupEventProps {
  wrapperRef?: MutableRefObject<any>;
  popupMessageText?: string;
  popupButtons?: any[];
  buttonContainerStyles?: any;
  popupStyles: any;
  innerComponent: any;
  closePopup?: () => void;
  darkBackground?: boolean;
}

const PopupEvent: FC<PopupEventProps> = ({
  popupMessageText,
  popupButtons = [],
  buttonContainerStyles,
  popupStyles,
  innerComponent,
  closePopup = noop,
  wrapperRef,
  darkBackground = true,
}) => {
  const handleConfirmAndClose = (confirmCallback) => {
    confirmCallback();
    closePopup();
  };

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      darkBackground={darkBackground}
    >
      <StyledPopup
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0 }}
        ref={wrapperRef}
        id="popUpWrapper"
        popupStyles={popupStyles}
      >
        <CloseButton onClick={closePopup} />
        {popupMessageText && <Message>{popupMessageText}</Message>}
        {innerComponent}
        {popupButtons && (
          <ButtonContainer buttonContainerStyles={buttonContainerStyles}>
            {popupButtons.map((button) => (
              <Button
                key={uuid()}
                width="80%"
                type="button"
                variant={button.variant || 'default'}
                buttonStyles={button.buttonStyles}
                IconComponent={button.IconComponent || null}
                onClick={
                  button.handleConfirm === noop
                    ? closePopup
                    : () => handleConfirmAndClose(button.handleConfirm)
                }
              >
                {button.popupButtonText}
              </Button>
            ))}
          </ButtonContainer>
        )}
      </StyledPopup>
    </Wrapper>
  );
};

export default PopupEvent;

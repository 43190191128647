import { TFunction } from 'react-i18next';
import moment from 'moment';

import { EventFormData, FIELDS } from './form';
import { Event, EventType } from 'types';
import { frequency, RepeatEventFrequency } from 'configs/frequency';
import { deadlineTimeUnits } from '../../configs/deadlineTimeUnits';
import {
  makeBinaryArrayFromDaysObjectsArray,
  makeIntFromBinaryArray,
} from 'utils';
import { TimeIntervalUnit } from '../../configs/intervalTimeUnits';

const convertEventDateTime = (dateString: string) => {
  if (dateString) {
    const date = new Date(dateString);

    return new Date(
      `${
        date.getMonth() + 1
      }/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:00`,
    );
  }

  const date = new Date();

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes() % 5 === 0
      ? date.getMinutes() + 30
      : date.getMinutes() - (date.getMinutes() % 5) + 30,
  );
};

const getEventTypeById = (
  id: string,
  eventTypes: EventType[],
): { label: string; value: string } => {
  const type = eventTypes.find((i) => i.id === id);

  return type ? { label: type.title, value: type.id } : null;
};

const getRepeatedRules = ({
  value,
  weekDays,
  startDate,
  intervalStep = 1,
  intervalModifier,
}: {
  value: RepeatEventFrequency;
  weekDays?: { label: string; value: number }[];
  startDate?: Date;
  intervalStep?: number;
  intervalModifier?: TimeIntervalUnit;
}) => {
  let rules = null;

  switch (value) {
    case RepeatEventFrequency.DAILY:
      rules = {
        intervalStep: 1,
        invetrvalModificator: 'day',
      };
      break;
    case RepeatEventFrequency.WEEKLY:
      rules = {
        intervalStep: 1,
        invetrvalModificator: 'week',
        perWeek: makeIntFromBinaryArray(
          makeBinaryArrayFromDaysObjectsArray(weekDays),
        ),
      };
      break;
    case RepeatEventFrequency.MONTHLY:
      rules = {
        intervalStep: 1,
        invetrvalModificator: 'month',
        perMonth: startDate.getDate(),
      };
      break;
    case RepeatEventFrequency.YEARLY:
      rules = {
        intervalStep: 1,
        invetrvalModificator: 'year',
      };
      break;
    case RepeatEventFrequency.EVERY_WORKING_DAY:
      rules = {
        intervalStep: 1,
        invetrvalModificator: 'week',
        perWeek: 62,
      };
      break;
    case RepeatEventFrequency.OTHER: {
      rules = {
        intervalStep,
        invetrvalModificator: intervalModifier,
      };

      if (intervalModifier === TimeIntervalUnit.WEEK) {
        rules.perWeek = makeIntFromBinaryArray(
          makeBinaryArrayFromDaysObjectsArray(weekDays),
        );
      }

      if (intervalModifier === TimeIntervalUnit.MONTH) {
        rules.perMonth = startDate.getDate();
      }

      break;
    }
    default:
      break;
  }

  return rules;
};

export const mapEventToFormValues = (
  t: TFunction,
  event: Event,
  eventTypes: EventType[],
): EventFormData => {
  const upDeadlineTimeUnits = deadlineTimeUnits.find(
    (i) => i.value === event?.registrationDeadline?.modificator,
  );

  const eventDefaultValues: EventFormData = {
    [FIELDS.TITLE]: event?.title || '',
    [FIELDS.EVENT_TYPE_ID]: getEventTypeById(event?.eventTypeId, eventTypes),
    [FIELDS.LOCATION]: event?.location || null,
    [FIELDS.START_TIME]: convertEventDateTime(event?.from),
    [FIELDS.END_TIME]:
      event?.to && event.allDay
        ? new Date(new Date(event.to).getTime() + 15 * 60000)
        : event?.to
        ? new Date(
            `${new Date(event.from).getMonth() + 1}/${new Date(
              event.from,
            ).getDate()}/${new Date(event.from).getFullYear()} ${new Date(
              event.to,
            ).getHours()}:${new Date(event.to).getMinutes()}:00`,
          )
        : convertEventDateTime(event?.to),
    [FIELDS.FROM]: event?.from ? new Date(event.from) : new Date(),
    [FIELDS.TO]: event?.to ? new Date(event.to) : new Date(),
    [FIELDS.END_DATE]: event?.endDate
      ? new Date(
          new Date(
            `${new Date(event.endDate).getMonth() + 1}/${new Date(
              event.endDate,
            ).getDate()}/${new Date(event.endDate).getFullYear()}`,
          ),
        )
      : null,
    [FIELDS.RECIPIENTS]: event?.recipients || [],
    [FIELDS.TASKS]: event?.tasks || [],
    [FIELDS.DOCUMENTS]: event?.documents
      ? event.documents.map((d) => d.id)
      : [],
    [FIELDS.REPEATED_RULES]: event?.repeatedRules || null,
    [FIELDS.REPEATED_RULES_OPTION]: {
      value: frequency[0].value,
      label: t(`frequencies.${frequency[0].value}`),
    },
    [FIELDS.REPEATED_RULES_VALUE]:
      event?.repeatedRules?.intervalStep?.toString() || null,
    [FIELDS.REPEATED_RULES_MODIFIER]: null,
    [FIELDS.ALL_DAY]: !!event?.allDay,
    [FIELDS.DESCRIPTION]: event?.description,
    [FIELDS.REGISTRATION_DEADLINE_VALUE]:
      event?.registrationDeadline?.value || null,
    [FIELDS.REGISTRATION_DEADLINE_UNIT]: upDeadlineTimeUnits
      ? {
          ...upDeadlineTimeUnits,
          label: upDeadlineTimeUnits
            ? t(`event.${upDeadlineTimeUnits?.label}`)
            : '',
        }
      : null,
    [FIELDS.WEEK_DAYS]: [
      { label: 'Su', value: 0 },
      { label: 'Mo', value: 0 },
      { label: 'Tu', value: 0 },
      { label: 'We', value: 0 },
      { label: 'Th', value: 0 },
      { label: 'Fr', value: 0 },
      { label: 'Sa', value: 0 },
    ],
  };

  return eventDefaultValues;
};

export const getEventRequestBody = (formData: EventFormData) => {
  const eventTypeId = formData[FIELDS.EVENT_TYPE_ID].value;

  const body: any = {
    title: formData[FIELDS.TITLE],
    location: formData[FIELDS.LOCATION],
    eventTypeId,
    allDay: formData[FIELDS.ALL_DAY],
    recipients: formData[FIELDS.RECIPIENTS],
    tasks: formData[FIELDS.TASKS],
    documents: formData[FIELDS.DOCUMENTS],
  };

  body.description = formData[FIELDS.DESCRIPTION] || '';

  if (formData[FIELDS.REGISTRATION_DEADLINE_VALUE]) {
    body.registrationDeadline = {
      value: formData[FIELDS.REGISTRATION_DEADLINE_VALUE],
      modificator: formData[FIELDS.REGISTRATION_DEADLINE_UNIT].value,
    };
  }

  const repeatedRules = getRepeatedRules({
    value: formData[FIELDS.REPEATED_RULES_OPTION]?.value,
    weekDays: formData[FIELDS.WEEK_DAYS],
    startDate: formData[FIELDS.FROM],
    intervalStep: parseInt(formData[FIELDS.REPEATED_RULES_VALUE], 10),
    intervalModifier: formData[FIELDS.REPEATED_RULES_MODIFIER]?.value,
  });

  if (repeatedRules) {
    body.repeatedRules = repeatedRules;
  }

  if (
    formData[FIELDS.REPEATED_RULES_OPTION] &&
    formData[FIELDS.REPEATED_RULES_OPTION]?.value !==
      RepeatEventFrequency.DO_NOT_REPEAT
  ) {
    body.endDate = new Date(
      `${formData[FIELDS.END_DATE].toDateString()} 00:00:00 GMT`,
    );
    body.isRepeated = true;
  } else {
    body.isRepeated = false;
  }

  body.from =
    moment(formData[FIELDS.FROM])
      .startOf('day')
      .hours(moment(formData[FIELDS.START_TIME]).hours())
      .minutes(moment(formData[FIELDS.START_TIME]).minutes())
      .seconds(0)
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';

  if (
    moment(formData[FIELDS.START_TIME]).isAfter(
      moment(formData[FIELDS.END_TIME]),
    )
  ) {
    body.to =
      moment(formData[FIELDS.FROM])
        .add(1, 'day')
        .startOf('day')
        .hours(moment(formData[FIELDS.END_TIME]).hours())
        .minutes(moment(formData[FIELDS.END_TIME]).minutes())
        .seconds(0)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
  } else {
    body.to =
      moment(formData[FIELDS.FROM])
        .startOf('day')
        .hours(moment(formData[FIELDS.END_TIME]).hours())
        .minutes(moment(formData[FIELDS.END_TIME]).minutes())
        .seconds(0)
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
  }

  return body;
};

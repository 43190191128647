import styled from 'styled-components';

export const HeaderContainer = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.blackSqueeze};
  padding: 0 25px;
`;

export const Header = styled.div`
  color: ${({ theme }) => theme.color.baliHai};
  font-size: 12px;
}`;

import { put, call, takeLatest, takeEvery, select } from 'redux-saga/effects';
import i18n from 'i18next';
import { get } from 'lodash/fp';

import { doSetOnlineMembers } from 'store/actionCreators/chat';
import { doCheckIsAuthorized } from 'store/actionCreators/auth';
import {
  requestCommunities,
  getCommunityLanding,
  getOverallScheduler,
  getCommunityTotal,
  getCommunityMembersByGroups,
  getCommunityUserGroups,
  getOverallScheduleStatistic,
  addUserToCommunity,
  updateCommunity,
  renewCommunityInvitationCode,
} from 'services/communities';
import { addMembersToGroup, rearrangeGroups } from 'services/groups';
import { doInviteMember } from 'store/actionCreators/currentCommunity/currentCommunity';
import { deleteGroupUser, createUserProfile } from 'services/users';
import {
  doRequestCurrentCommunity,
  doRequestOverallScheduler,
  doGetCommunityEventTypes,
  doGetCommunityResponses,
  doGetCommunityTasks,
  doGetDocuments,
  doGetDocumentsOverview,
  doGetCommunityLanding,
  doGetCommunityTotal,
  doGetCommunityMembersByGroups,
  doGetCommunityUserGroups,
  doAddMemberToGroup,
  doRemoveMemberFromGroup,
  doGetOverallScheduleStatistics,
  doRearrangeGroups,
  doUpdateCommunityNotificationsSettings,
  doRenewInvitationCode,
} from 'store/actionCreators/currentCommunity';
import { openModal } from 'store/actionCreators/modal';
import { getCurrentUserSubscription } from 'store/actionCreators/subscriptions';
import {
  selectCurrentUserSubscription,
  selectIsCommunitySubscriptionActive,
  selectCommunitySubscriptionPending,
} from 'store/selectors/subscriptions';
import {
  isCurrentUserOwner,
  getGroups,
  getOverallScheduler as getOverallSchedulerSelector,
} from 'store/selectors/currentCommunity';
import { POPUP_MESSAGE } from 'constants/popups';
import { checkSubscription } from '../helper';
import { toast } from '../../../utils/toastAbstraction';

function* onRequestOverallScheduler({
  payload,
}: ReturnType<typeof doRequestOverallScheduler>) {
  try {
    const data = yield call(
      getOverallScheduler,
      payload.communityId,
      payload.from,
      payload.to,
      payload.eventTypeId,
    );
    yield put(doRequestOverallScheduler.success(data));
  } catch ({ response }) {
    yield put(doRequestOverallScheduler.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

function* onRequestCurrentCommunity({
  payload,
}: ReturnType<typeof doRequestCurrentCommunity>) {
  try {
    const data = yield call(requestCommunities, payload);
    yield put(doRequestCurrentCommunity.success(data));
    yield put(doSetOnlineMembers.trigger());
    yield put(doGetCommunityResponses(payload.communityId));
    yield put(doGetCommunityEventTypes(payload.communityId));
    yield put(doGetCommunityTasks(payload.communityId));
    yield put(doGetDocumentsOverview(payload.communityId));
    yield put(doGetDocuments(payload.communityId));
  } catch ({ response }) {
    yield put(doRequestCurrentCommunity.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

function* onGetCommunityLanding({
  payload,
}: ReturnType<typeof doGetCommunityLanding>) {
  try {
    const data = yield call(getCommunityLanding, payload);
    yield put(doGetCommunityLanding.success(data));
  } catch ({ response }) {
    yield put(doGetCommunityLanding.failure(response.data));
    yield put(doCheckIsAuthorized.trigger({}));
  }
}

function* onGetCommunityTotal({
  payload,
}: ReturnType<typeof doGetCommunityTotal>) {
  try {
    const data = yield call(
      getCommunityTotal,
      payload.communityId,
      payload.from,
      payload.to,
    );
    yield put(doGetCommunityTotal.success(data));
  } catch ({ response }) {
    yield put(doGetCommunityTotal.failure(response.data));
  }
}

function* onGetCommunityMembersByGroups({
  payload,
}: ReturnType<typeof doGetCommunityMembersByGroups>) {
  try {
    const data = yield call(
      getCommunityMembersByGroups,
      payload.communityId,
      payload.withoutBlocked,
    );
    yield put(doGetCommunityMembersByGroups.success(data.groups));
  } catch ({ response }) {
    yield put(doGetCommunityMembersByGroups.failure(response.data));
  }
}

function* onGetCommunityUserGroups({
  payload,
}: ReturnType<typeof doGetCommunityUserGroups>) {
  try {
    const { communityId, userIds } = payload;

    const data = yield call(getCommunityUserGroups, communityId, userIds);
    yield put(doGetCommunityUserGroups.success({ usersGroups: data, userIds }));
  } catch ({ response }) {
    yield put(doGetCommunityUserGroups.failure());
  }
}

export function* onAddMemberToGroup({
  payload,
}: ReturnType<typeof doAddMemberToGroup>) {
  try {
    yield call(
      checkSubscription,
      selectIsCommunitySubscriptionActive,
      selectCommunitySubscriptionPending,
    );
    const { maxMembers, info } = yield select(selectCurrentUserSubscription);
    const isOwner = yield select(isCurrentUserOwner);
    if (info.users >= maxMembers) {
      yield put(doAddMemberToGroup.failure());
      yield put(
        openModal.trigger({
          type: POPUP_MESSAGE,
          data: {
            popupHeaderText: i18n.t('subscriptions.limitExceededHeader'),
            popupMessageText: i18n.t(
              isOwner
                ? 'subscriptions.limitExceededMessageOwner'
                : 'subscriptions.limitExceededMessage',
            ),
            popupButtonText: 'Ok',
          },
        }),
      );
    } else {
      const { communityId, groupId } = payload;
      const data = yield call(
        addMembersToGroup,
        communityId,
        groupId,
        payload.data,
      );
      yield put(doAddMemberToGroup.success(data));
      yield call(payload.onSuccess);
    }
  } catch ({ response }) {
    yield put(doAddMemberToGroup.failure(response));
    yield put(
      openModal.trigger({
        type: POPUP_MESSAGE,
        data: {
          popupHeaderText: response.data.message,
          popupButtonText: 'Ok',
        },
      }),
    );
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onRemoveMemberFromGroup({
  payload,
}: ReturnType<typeof doRemoveMemberFromGroup>) {
  try {
    const { communityId, groupId, user } = payload;
    const data = yield call(deleteGroupUser, communityId, groupId, [user.id]);
    yield put(doRemoveMemberFromGroup.success(data));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doRemoveMemberFromGroup.failure(response));
    yield put(
      openModal.trigger({
        type: POPUP_MESSAGE,
        data: {
          popupHeaderText: response.data.message,
          popupButtonText: 'Ok',
        },
      }),
    );
  }
}

export function* onGetOverallStatistic({
  payload,
}: ReturnType<typeof doGetOverallScheduleStatistics>) {
  try {
    yield put(doGetOverallScheduleStatistics.request(payload));
    const { replies, repliesToEventTypes } = yield call(
      getOverallScheduleStatistic,
      payload,
    );
    yield put(
      doGetOverallScheduleStatistics.success({ replies, repliesToEventTypes }),
    );
  } catch ({ response }) {
    yield put(doGetOverallScheduleStatistics.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onRearrangeGroups({
  payload,
}: ReturnType<typeof doRearrangeGroups>) {
  const { start, end, communityId } = payload;
  const communityGroups = yield select(getGroups);
  const overallScheduler = yield select(getOverallSchedulerSelector);
  const overallSchedulerGroups = overallScheduler?.groups || [];
  const newMenuList = [...communityGroups];
  const newSchedulerList = [...overallSchedulerGroups];

  newMenuList.splice(end, 0, newMenuList.splice(start, 1)[0]);
  newSchedulerList.splice(end, 0, newSchedulerList.splice(start, 1)[0]);

  yield put(
    doRearrangeGroups.success({
      newMenuList,
      newSchedulerList,
      dragFinished: !!communityId,
    }),
  );

  if (communityId) {
    yield call(rearrangeGroups, start, end, communityId);
  }
}

export function* onInviteMember({
  payload,
}: ReturnType<typeof doInviteMember>) {
  try {
    if (payload.firstName) {
      const response = yield call(createUserProfile, {
        communityId: payload.communityId,
        firstName: payload.firstName,
        lastName: payload.lastName,
        role: payload.role,
        email: payload.email,
        groups: payload.groups,
      });
      yield call(payload.onSuccess);
      yield put(
        doInviteMember.success({
          user: response.data,
          groups: payload.groups,
        }),
      );
    } else {
      const response = yield call(
        addUserToCommunity,
        payload.communityId,
        payload.userId,
        payload.role,
        payload.groups,
      );
      yield put(getCurrentUserSubscription.trigger());
      yield call(payload.onSuccess);
      yield put(
        doInviteMember.success({
          user: response.data,
          groups: payload.groups,
        }),
      );
    }
  } catch ({ response }) {
    yield call(payload.onFailure, response);
    yield put(doInviteMember.failure());
  }
}

export function* onUpdateCommunityNotificationsSettings({
  payload,
}: ReturnType<typeof doUpdateCommunityNotificationsSettings>) {
  try {
    const data = yield call(updateCommunity, payload.data.id, {
      category: payload.data.category,
      notifications: payload.data.notifications,
    });
    yield put(doUpdateCommunityNotificationsSettings.success(data));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doUpdateCommunityNotificationsSettings.failure(response));
    yield toast(i18n.t('community.updateFailure'), {
      appearance: 'error',
      autoDismiss: true,
    });
  }
}

export function* onRenewInvitationCode({
  payload,
}: ReturnType<typeof doRenewInvitationCode>) {
  try {
    const data = yield call(renewCommunityInvitationCode, payload);
    yield put(doRenewInvitationCode.success(data.data.invitationCode));
  } catch (_) {
    yield put(doRenewInvitationCode.failure());
  }
}

export default function* () {
  yield takeLatest(doRequestCurrentCommunity, onRequestCurrentCommunity);
  yield takeLatest(doGetCommunityLanding, onGetCommunityLanding);
  yield takeLatest(doRequestOverallScheduler, onRequestOverallScheduler);
  yield takeLatest(doGetCommunityTotal, onGetCommunityTotal);
  yield takeLatest(
    doGetCommunityMembersByGroups,
    onGetCommunityMembersByGroups,
  );
  yield takeEvery(doGetCommunityUserGroups, onGetCommunityUserGroups);
  yield takeLatest(doAddMemberToGroup, onAddMemberToGroup);
  yield takeLatest(doRemoveMemberFromGroup, onRemoveMemberFromGroup);
  yield takeLatest(doGetOverallScheduleStatistics, onGetOverallStatistic);
  yield takeLatest(doRearrangeGroups, onRearrangeGroups);
  yield takeLatest(doInviteMember, onInviteMember);
  yield takeLatest(
    doUpdateCommunityNotificationsSettings,
    onUpdateCommunityNotificationsSettings,
  );
  yield takeLatest(doRenewInvitationCode, onRenewInvitationCode);
}

import styled from 'styled-components';
import { motion } from 'framer-motion';

import { breakpoints } from 'theme';

export const Wrapper = styled(motion.div)`
  width: 100vw;
  height: 100svh;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.darkGrey};
  z-index: 10000000000000000;
  left: 0;
  top: 0;
`;

export const StyledPopup = styled(motion.div)`
  width: 800px;
  position: relative;
  background: ${({ theme }) => theme.color.white};
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.color.brightTurquoise};
  ${({ popupStyles }) => popupStyles};

  @media ${breakpoints.downMd} {
    width: 94%;
  }

  & > button:first-of-type {
    position: absolute;
    top: 13px;
    right: 24px;
    z-index: 100;

    svg path {
      fill: black;
      transition: fill 0.3s;
    }

    &:hover {
      svg path {
        fill: white;
      }
    }
  }
`;

export const StyledHeader = styled.div`
  background: ${({ theme }) => theme.color.brightTurquoise};
  padding: 30px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  & > div {
    width: 70%;
    display: flex;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    border: 2px solid black;
    border-bottom: none;

    img {
      width: 100%;
    }
  }
`;

export const StyledBody = styled.div`
  padding: 26px;

  h3 {
    font-size: 30px;
    line-height: 50px;
    text-align: center;
    margin-bottom: 26px;
    color: ${({ theme }) => theme.color.pickledBluewood};

    @media ${breakpoints.downMd} {
      font-size: 26px;
      line-height: 44px;
    }
  }

  p {
    font-size: 17px;
    line-height: 28px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.pickledBluewood};
    text-align: center;

    &:nth-of-type(2) {
      margin-top: 26px;
    }

    @media ${breakpoints.downMd} {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 0 26px;
  margin-top: 34px;
  margin-bottom: 26px;

  @media ${breakpoints.downSmPlus} {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

import React, { useEffect, useState, createContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Loader } from 'components';
import {
  StyledSteps,
  StepWrapper,
  Step,
  StepText,
  ButtonWrapper,
  MainContainer,
  SidebarTitle,
  StepsContainer,
  SidebarDescription,
  ComponentWrapper,
  ButtonContainer,
  GhostWrapper,
} from './styled';
import { hasCommunityOrGroupAdminPermissions } from 'utils';
import { Community, CommunityUser } from 'types';

type StepsProps = {
  orientation?: 'horizontal' | 'vertical';
  children: Array<JSX.Element>;
  isSidebar?: boolean;
  currentUser: CommunityUser;
  communityDetails: Community;
  loading: boolean;
  nextButtonsText?: Array<{
    index: number;
    text: string;
    preStepFunc?: () => Promise<void>;
  }>;
  overrideStep?: number; // use this to jump from a step to the desired one.
};

export const StepsContext = createContext({
  allowNextStep: true,
  allowPreviousStep: true,
  isLastStep: false,
  setCondition: (nextStep, prevStep) => {},
  triggerNextStep: () => {},
  // setCurrentStepTo: (num: number) => {
  //   console.log('why the heck am I running');
  // },
  setCurrentStepTo: undefined,
  currentStep: 0,
});

const Steps: React.FC<StepsProps> = ({
  orientation,
  children,
  isSidebar,
  nextButtonsText = [],
  overrideStep,
  currentUser,
  communityDetails,
  loading,
}) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [nextButtonLoader, setNextButtonLoader] = useState<boolean>(false);
  const [stepList, setStepList] = useState<Array<string>>([]);
  const [stepControl, setStepControl] = useState<{
    allowNextStep: boolean;
    allowPreviousStep: boolean;
    isLastStep: boolean;
  }>({ allowNextStep: true, allowPreviousStep: true, isLastStep: false });
  const hasPermission = !hasCommunityOrGroupAdminPermissions(
    currentUser.id,
    communityDetails,
  );
  const availableChildren = children.filter((c) => !!c);

  const handleNextStep = async () => {
    if (hasPermission) {
      return;
    }
    const executeFnBeforeNextStep = nextButtonsText.filter(
      (n) => n.index === currentStep,
    )[0]?.preStepFunc;
    if (executeFnBeforeNextStep) {
      try {
        setNextButtonLoader(true);
        await executeFnBeforeNextStep();
        currentStep !== availableChildren.length - 1 &&
          setCurrentStep((prevValue) => prevValue + 1);
      } catch (err) {
        console.log('an Error occured', err);
      } finally {
        setNextButtonLoader(false);
      }
    } else {
      currentStep !== availableChildren.length - 1 &&
        setCurrentStep((prevValue) => prevValue + 1);
    }
  };

  const gotoLastStep = () => {
    const lastStep = availableChildren.length - 1;
    setCurrentStep(lastStep);
  };

  const stepControlFn = (nextStep: boolean, prevStep: boolean) => {
    setStepControl((prevVal) => ({
      ...prevVal,
      allowNextStep: nextStep,
      allowPreviousStep: prevStep,
      isLastStep: availableChildren.length - 1 === currentStep,
    }));
  };

  const renderComponent: (currentStep: number) => JSX.Element = (
    currentStep,
  ) => {
    const Child = availableChildren.filter((_, idx) => idx === currentStep)[0];
    // Child.props = { ...Child.props, ...childProps }; // invalid
    return Child ?? <></>;
  };

  const renderDescription = (children) => {
    const currentComponent = children[currentStep];
    return currentComponent ? currentComponent.props.description : '';
  };

  const renderStepTitle = (children) => {
    const currentComponent = children[currentStep];
    return currentComponent ? currentComponent.props.titleText : undefined;
  };

  useEffect(() => {
    const stepNames = availableChildren.map((c) => c.props.label);
    setStepList(stepNames);
  }, [children]);

  useEffect(() => {
    setCurrentStep(overrideStep);
  }, [overrideStep]);

  return (
    <StepsContext.Provider
      value={{
        ...stepControl,
        setCondition: stepControlFn,
        triggerNextStep: () => {
          currentStep !== availableChildren.length - 1 &&
            setCurrentStep((prevValue) => prevValue + 1);
        },
        currentStep,
        setCurrentStepTo: gotoLastStep,
      }}
    >
      <MainContainer>
        <StyledSteps
          currentStep={currentStep}
          maxSteps={availableChildren.length - 1}
        >
          <SidebarTitle>
            {/* <p>{stepText[currentStep]?. ? stepTitle : `Step ${currentStep + 1}`}</p> */}
            <p>{renderStepTitle(availableChildren)}</p>
            <p>
              {renderDescription(availableChildren) && (
                <SidebarDescription>
                  {renderDescription(availableChildren)}
                </SidebarDescription>
              )}
            </p>
          </SidebarTitle>

          <StepsContainer
            height={`${(currentStep / (availableChildren.length - 1)) * 100}%`}
          >
            {stepList.map((stepLabel, idx) => (
              <StepWrapper key={idx}>
                <Step currentStep={currentStep} idx={idx}>
                  {idx + 1}
                </Step>
                <StepText currentStep={currentStep} idx={idx}>
                  {t('common.step') + ` ${idx + 1}`}
                </StepText>
              </StepWrapper>
            ))}
          </StepsContainer>
        </StyledSteps>
        <ComponentWrapper>
          {hasPermission && <GhostWrapper />}
          {renderComponent(currentStep)}
          <ButtonContainer extraMargin={currentStep === 1 || currentStep === 2}>
            <ButtonWrapper>
              {currentStep !== 0 &&
                currentStep !== availableChildren.length - 1 && (
                  <Button
                    disabled={nextButtonLoader || loading}
                    width="min(100px,100%)"
                    onClick={() => {
                      setCurrentStep((prevStep) => prevStep - 1);
                    }}
                    variant="secondary"
                  >
                    {nextButtonLoader || loading ? (
                      <div style={{ width: '200px' }}>
                        <Loader type="button" size="28px" />
                      </div>
                    ) : (
                      t('common.back')
                    )}
                  </Button>
                )}
              {currentStep !== availableChildren.length - 1 && (
                <Button
                  disabled={
                    !stepControl.allowNextStep || nextButtonLoader || loading
                  }
                  onClick={handleNextStep}
                  width={currentStep === 0 ? '100%' : 'min(200px,100%)'}
                >
                  {nextButtonLoader || loading ? (
                    <div style={{ width: '200px' }}>
                      <Loader type="button" size="28px" />
                    </div>
                  ) : (
                    nextButtonsText.filter((n) => n.index === currentStep)[0]
                      ?.text || t('common.next')
                  )}
                </Button>
              )}
            </ButtonWrapper>
          </ButtonContainer>
        </ComponentWrapper>
      </MainContainer>
    </StepsContext.Provider>
  );
};

export default Steps;

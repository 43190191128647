import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useLocalStorage, useMedia } from 'react-use';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';
import { COMMUNITY_ROUTES } from 'configs';
import { CommunityMenu, CommunitySidebarMobile } from 'components';
import {
  CommunitySettingsPage,
  CommunityOverallSchedulerPage,
  GroupPage,
  GroupSettingsPage,
  DocumentationPage,
  CommunityChatPage,
  EmailModulePage,
  CommunityLandingPage,
  PushNotificationPage,
} from 'pages';
import { breakpoints } from 'theme';
import FeaturePopup from '../components/Popups/FeaturePopup';
import FeaturePopup2 from '../components/Popups/FeaturePopup2';
import PushNotificationFeaturePopup from 'components/Popups/PushNotification';
import ImportFeaturePopup from 'components/Popups/importPopup';

const CommunityRouter = () => {
  const { addToast } = useToasts();
  const isWide = useMedia(breakpoints.md);
  const { t } = useTranslation();
  const [featurePopupVisible, setFeaturePopupVisible] =
    useState<boolean>(false);
  const [featurePopup2Visible, setFeaturePopup2Visible] =
    useState<boolean>(false);
  const [pushFeatureVisible, setPushFeatureVisible] = useState<boolean>(false);
  const [importFeatureVisible, setImportFeatureVisible] =
    useState<boolean>(false);

  const [lsFeaturePopup, setLsFeaturePopup] = useLocalStorage<boolean>(
    'memberFiguresPopupShown',
    false,
  );
  const [lsFeaturePopup2, setLsFeaturePopup2] = useLocalStorage<boolean>(
    'preregisteredMembersPopupShown',
    false,
  );
  const [lsPushFeaturePopup, setLsPushFeaturePopup] = useLocalStorage<boolean>(
    'pushNotificationFeaturePopupShown',
    false,
  );
  const [lsImportPopup, setLsImportFeaturePopup] = useLocalStorage<boolean>(
    'importFeaturePopupShown',
    false,
  );

  const [lsShowToast, setIsShowToast] = useLocalStorage<boolean>(
    'importforGroup',
    false,
  );

  useEffect(() => {
    if (!lsFeaturePopup) {
      setLsFeaturePopup(true);
      setTimeout(() => setFeaturePopupVisible(true), 3000);
    }
  }, [lsFeaturePopup]);

  useEffect(() => {
    if (!lsFeaturePopup2) {
      setLsFeaturePopup2(true);
      setTimeout(() => setFeaturePopup2Visible(true), 3000);
    }
  }, [lsFeaturePopup2]);

  useEffect(() => {
    if (!lsPushFeaturePopup) {
      setLsPushFeaturePopup(true);
      setTimeout(() => setPushFeatureVisible(true), 3000);
    }
  }, [lsPushFeaturePopup]);

  useEffect(() => {
    if (!lsImportPopup) {
      setLsImportFeaturePopup(true);
      setTimeout(() => setImportFeatureVisible(true), 3000);
    }
  }, [lsImportPopup]);

  useEffect(() => {
    if (!lsShowToast) {
      setIsShowToast(true);
      addToast(t('bulkUploadEvents.toastText'), {
        appearance: 'success',
        autoDismiss: false,
      });
    }
  }, [lsShowToast]);

  return (
    <>
      <FeaturePopup
        isOpen={featurePopupVisible}
        onClose={() => setFeaturePopupVisible(false)}
      />
      <FeaturePopup2
        isOpen={featurePopup2Visible}
        onClose={() => setFeaturePopup2Visible(false)}
      />
      <PushNotificationFeaturePopup
        isOpen={pushFeatureVisible}
        onClose={() => setPushFeatureVisible(false)}
      />
      <ImportFeaturePopup
        isOpen={importFeatureVisible}
        onClose={() => setImportFeatureVisible(false)}
      />
      {isWide ? <CommunityMenu /> : <CommunitySidebarMobile />}
      <Routes>
        <Route path="*" element={<CommunityLandingPage />} />
        <Route
          path={COMMUNITY_ROUTES.SETTINGS}
          element={<CommunitySettingsPage />}
        />
        <Route
          path={COMMUNITY_ROUTES.OVERALL}
          element={<CommunityOverallSchedulerPage />}
        />
        <Route
          path={`${COMMUNITY_ROUTES.OVERALL}/${COMMUNITY_ROUTES.EVENT}/:eventId`}
          element={<CommunityOverallSchedulerPage />}
        />
        <Route
          path={`${COMMUNITY_ROUTES.OVERALL}/${COMMUNITY_ROUTES.EVENT}/:eventId/details`}
          element={<CommunityOverallSchedulerPage />}
        />
        <Route
          path={`${COMMUNITY_ROUTES.GROUP}/:groupId`}
          element={<GroupPage />}
        />
        <Route
          path={`${COMMUNITY_ROUTES.GROUP}/:groupId/${COMMUNITY_ROUTES.EVENT}/:eventId`}
          element={<GroupPage />}
        />
        <Route
          path={`${COMMUNITY_ROUTES.GROUP}/:groupId/${COMMUNITY_ROUTES.EVENT}/:eventId/details`}
          element={<GroupPage />}
        />
        <Route
          path={`${COMMUNITY_ROUTES.GROUP}/:groupId/${COMMUNITY_ROUTES.SETTINGS}`}
          element={<GroupSettingsPage />}
        />
        <Route
          path={`${COMMUNITY_ROUTES.DOCUMENTATION}/*`}
          element={<DocumentationPage />}
        />
        <Route path={COMMUNITY_ROUTES.EMAIL} element={<EmailModulePage />} />
        <Route
          path={`${COMMUNITY_ROUTES.CHAT}/:chatId`}
          element={<CommunityChatPage />}
        />
        <Route
          path={COMMUNITY_ROUTES.PUSH_NOTIFICATION}
          element={<PushNotificationPage />}
        />
      </Routes>
    </>
  );
};

export default CommunityRouter;

import React, { FC } from 'react';
import { FieldError, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StyledWrapper, StyledLabel, StyledErrorMessage } from '../styled';
import { StyledArea } from './styled';

interface TextAreaProps extends Partial<Pick<UseFormMethods, 'register'>> {
  width?: string;
  height?: string;
  name: string;
  placeholder?: string;
  label?: string;
  defaultValue?: string;
  error?: FieldError;
  disabled?: boolean;
  rows?: number;
  zoom?: number;
  wrapperStyle?: React.CSSProperties;
  maxLength?: number;
  onChange?: () => void;
}

const TextArea: FC<TextAreaProps> = ({
  register,
  width = '100%',
  height,
  name,
  placeholder = '',
  label,
  defaultValue,
  error,
  disabled,
  rows = 5,
  zoom = 1,
  onChange,
  wrapperStyle,
  maxLength,
}) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper style={wrapperStyle}>
      {label && (
        <StyledLabel zoom={zoom} htmlFor={name}>
          {label}
        </StyledLabel>
      )}
      <StyledArea
        width={width}
        height={height}
        id={name}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        ref={register}
        error={error}
        defaultValue={defaultValue}
        disabled={disabled}
        rows={rows}
        zoom={zoom}
        maxLength={maxLength}
      />
      {error && (
        <StyledErrorMessage zoom={zoom}>
          {error.message || t(`errors.${error.type}`)}
        </StyledErrorMessage>
      )}
    </StyledWrapper>
  );
};

export default TextArea;

import styled from 'styled-components';

export const StyledInputsBlockStart = styled.div`
  display: grid;
  grid-template-columns: 171px auto;
  grid-gap: 10px;
  align-items: start;
`;

export const StyledInputsBlockEnd = styled.div`
  display: grid;
  grid-template-columns: 210px auto;
  grid-gap: 10px;
  align-items: end;
`;

export const StyledTimePickersBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`;

export const StyledCheckBoxBlock = styled.div`
  margin-left: 10px;
  margin-bottom: 5px;
`;

export const StyledCheckButtonsBlock = styled.div`
  margin-top: 20px;
`;

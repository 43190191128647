import React, { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Button, CloseButton } from '../../index';

import {
  Wrapper,
  StyledPopup,
  StyledHeader,
  StyledBody,
  StyledFooter,
} from './styled';

const FeaturePopup = ({ isOpen, onClose }) => {
  const { t, i18n } = useTranslation();

  const imgUrl = useMemo(() => {
    if (i18n.language === 'de') {
      return 'https://gumb-cdn-prod.s3.eu-central-1.amazonaws.com/Gumb-%E2%80%93-Web-App+-+2023-09-04T113017.559.png';
    }

    return 'https://gumb-cdn-prod.s3.eu-central-1.amazonaws.com/Gumb-%E2%80%93-Web-App+-+2023-09-04T111736.908.png';
  }, [i18n]);

  return (
    <>
      {createPortal(
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
        /* @ts-ignore*/
        <AnimatePresence>
          {isOpen && (
            <Wrapper
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              <StyledPopup>
                <CloseButton onClick={onClose} />
                <PerfectScrollbar
                  options={{
                    wheelSpeed: 0.1,
                    wheelPropagation: false,
                    minScrollbarLength: 4,
                  }}
                >
                  <StyledHeader>
                    <div>
                      <img src={imgUrl} alt="Pre-registered members" />
                    </div>
                  </StyledHeader>
                  <StyledBody>
                    <h3>{t('featurePopup2.title')}</h3>
                    <p>{t('featurePopup2.text')}</p>
                  </StyledBody>
                  <StyledFooter>
                    <Button width="300px" onClick={onClose}>
                      {t('common.ok')}
                    </Button>
                  </StyledFooter>
                </PerfectScrollbar>
              </StyledPopup>
            </Wrapper>
          )}
        </AnimatePresence>,
        document.getElementById('modal'),
      )}
    </>
  );
};

export default FeaturePopup;

import styled, { css } from 'styled-components';
import { breakpoints, theme } from 'theme';

import { getZoomedValue } from 'utils';

const getCellContentWidth = (width, zoom) => {
  const innerWidth = Math.floor((width ? width - 4 : 153) * zoom);
  const offset =
    (Math.floor((width || 157) * zoom) - innerWidth) % 2 === 1 ? 0 : 1;

  return innerWidth - offset;
};

const getHeadContentWidth = (width, zoom) => {
  const innerWidth = Math.floor((width || 157) * zoom);
  const outerWidth = Math.floor((width ? width + 2 : 139) * zoom);
  const offset = (outerWidth - innerWidth) % 2 === 1 ? 0 : 1;

  return innerWidth - offset;
};

const getCellBGColor = (isCurrentUser, opacity) => {
  if (!isCurrentUser) {
    return '#FFF';
  }

  return opacity === 1 ? '#caf5f5' : '#92ecea';
};

const sharedStylesDivider = css`
  ${({ divide, theme, zoom }) =>
    divide &&
    css`
      &:after {
        content: '';
        background-color: ${theme.color.brightTurquoise};
        width: ${getZoomedValue(6, zoom, 'px')};
        height: 100%;
        position: absolute;
        top: 0;
        left: ${divide === 'left' ? '-3px' : 'auto'};
        right: ${divide === 'right' ? '-1px' : 'auto'};
        z-index: 100;
      }
    `};
`;

export const StyledHighlight = styled.div`
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: ${({ zoom, size }) => getZoomedValue(size ? size : 3, zoom, 'px')};
    background: ${({ size }) =>
      size ? theme.color.pickledBluewood : '#1cc1be'};
    z-index: 100000;
    bottom: 0;
    left: 0;
  }

  &:before {
    bottom: auto;
    top: 0;
  }
`;

export const StyledHighlightTop = styled.div`
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: ${({ zoom }) => getZoomedValue(7, zoom, 'px')};
    background: ${theme.color.pickledBluewood};
    z-index: 100000;
    left: 0;
  }

  &:before {
    top: 0;
  }
`;

export const StyledSelectionHighlight = styled.div`
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: ${({ zoom }) => getZoomedValue(7, zoom, 'px')};
    height: 100%;
    background: ${theme.color.pickledBluewood};
    z-index: 100000;
    top: 0;
    bottom: 0;
  }

  &:before {
    left: 0;
    top: 0;
    bottom: 0;
  }

  &:after {
    right: 0;
    top: 0;
    bottom: 0;
  }
`;

export const StyledCellContainer = styled.div`
  height: ${({ zoom }) => getZoomedValue(39, zoom, 'px')};
  border-right: 1px solid #e5e9e9;
  border-bottom: 1px solid #e5e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ clickable, isStatisticsTab }) =>
    clickable || isStatisticsTab ? 1 : 0.5};
  pointer-events: ${({ clickable }) => (clickable ? 'all' : 'none')};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'initial')};
  background: ${({ clickable, isStatisticsTab, isCurrentUser }) =>
    getCellBGColor(isCurrentUser, clickable || isStatisticsTab ? 1 : 0.5)};
  ${sharedStylesDivider}
`;

export const StyledCellContent = styled.div`
  width: ${({ zoom, width }) => getCellContentWidth(width, zoom)}px;
  height: ${({ zoom }) =>
    Math.floor(36 * zoom) -
    (Math.floor(39 * zoom) - Math.floor(36 * zoom) === 2 ? 1 : 0)}px;
  padding: ${({ zoom, align }) =>
    align !== 'center' ? getZoomedValue(7, zoom, 'px') : 0};
  background: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align || 'center'};
  font-size: 10px;
  margin: 0 auto;
  color: ${({ color, theme }) =>
    color ? '#fff' : theme.color.pickledBluewood};
  border-radius: 4px;
  gap: ${({ zoom }) => getZoomedValue(5, zoom, 'px')};

  & > *:not(img):not(svg) {
    font-size: ${({ zoom }) => getZoomedValue(14, zoom, 'px')};
  }

  & > img,
  & > svg {
    width: auto;
    height: ${({ zoom }) => getZoomedValue(13, zoom, 'px')};
  }
`;

export const StyledStickyCell = styled.div`
  border-right: 1px solid #e5e9e9;
  border-bottom: 1px solid #e5e9e9;
  text-align: left;
  height: ${({ zoom }) => getZoomedValue(39, zoom, 'px')};
  padding-left: ${({ zoom }) => getZoomedValue(20, zoom, 'px')};
  overflow: hidden;
  white-space: nowrap;
  line-height: ${({ zoom }) => getZoomedValue(37, zoom, 'px')};
  font-size: ${({ zoom }) => getZoomedValue(14, zoom, 'px')};
  color: ${({ theme }) => theme.color.pickledBluewood};
  display: flex;
  align-items: center;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'all')};
  background: ${({ isCurrentUser }) => (isCurrentUser ? '#caf5f5' : '#FFF')};

  div {
    font-weight: 600;
  }
  ${({ selectedUser }) =>
    selectedUser &&
    css`
      border-left: 5px solid ${theme.color.pickledBluewood};
    `}
  .profile-name-wrapper {
    justify-content: space-between;
  }

  ${sharedStylesDivider}
`;

export const StyledFooterCell = styled.div`
  border-right: 1px solid ${({ theme }) => theme.color.porcelain};
  border-bottom: 1px solid ${({ theme }) => theme.color.porcelain};
  display: flex;
  align-items: center;
  font-size: ${({ fontSize }) => fontSize || 14}px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  cursor: pointer;
  pointer-events: ${({ clickable }) => (clickable ? 'all' : 'none')};
  justify-content: ${({ centered }) => (centered ? 'center' : 'flex-start')};
  padding-left: ${({ centered, zoom }) =>
    centered ? 0 : getZoomedValue(20, zoom, 'px')};
  transition: color 0.2s;
  font-size: ${({ zoom }) => getZoomedValue(14, zoom, 'px')};
  height: ${({ zoom }) => getZoomedValue(39, zoom, 'px')};
  background: white;
  text-align: left;

  &:hover {
    color: ${({ theme, clickable }) =>
      clickable ? theme.color.brightTurquoise : theme.color.pickledBluewood};
  }

  ${sharedStylesDivider}
`;

export const StyledGroupCell = styled.div`
  font-size: ${({ zoom }) => getZoomedValue(12, zoom, 'px')};
  padding-left: ${({ zoom }) => getZoomedValue(25, zoom, 'px')};
  height: ${({ zoom }) => getZoomedValue(39, zoom, 'px')};
  background-color: ${({ dark }) => (dark ? '#8298ab' : '#ebf2f7')};
  width: 100%;
  display: flex;
  align-items: center;
  color: ${({ dark }) => (dark ? '#ffffff' : '#8298ab')};
  ${sharedStylesDivider}
`;

export const StyledEventTypeLabel = styled.div`
  font-size: ${({ zoom }) => getZoomedValue(10, zoom, 'px')};
  height: ${({ zoom }) => getZoomedValue(23, zoom, 'px')};
  max-width: ${({ zoom }) => getZoomedValue(95, zoom, 'px')};
  line-height: ${({ zoom }) => getZoomedValue(15, zoom, 'px')};
  padding: 0 4px;
  background: #0000003b;
  display: inline-block;
  width: max-content;
  border-radius: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledTitle = styled.div`
  font-size: ${({ zoom }) => getZoomedValue(12, zoom, 'px')};
  line-height: ${({ zoom }) => getZoomedValue(16, zoom, 'px')};
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  ${({ isStatisticsTab, zoom }) =>
    isStatisticsTab &&
    css`
      white-space: break-spaces;
      & > span {
        display: block;
        height: ${getZoomedValue(2 * 16, zoom, 'px')};
      }
    `}

  & > span {
    overflow: hidden;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: ${({ zoom }) => getZoomedValue(3 * 16, zoom, 'px')};
    font-weight: 800;
    @media ${breakpoints.downMd} {
      font-weight: 700;
    }
  }

  svg,
  img {
    width: auto;
    height: ${({ zoom }) => getZoomedValue(15, zoom, 'px')};
  }
`;

StyledTitle.PlusIconText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  position: absolute;
  top: ${({ zoom }) => getZoomedValue(1, zoom, 'px')};
  left: ${({ zoom }) => getZoomedValue(1, zoom, 'px')};
  gap: ${({ zoom }) => getZoomedValue(6, zoom, 'px')};
  .plus-icon {
    font-weight: 400;
    width: ${({ zoom }) => getZoomedValue(38, zoom, 'px')};
    color: ${({ theme }) => theme.color.pickledBluewood};
    height: ${({ zoom }) => getZoomedValue(38, zoom, 'px')};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #8298ab;
    font-size: ${({ zoom }) => getZoomedValue(30, zoom, 'px')};
  }
  .plus-title {
    color: ${({ theme }) => theme.color.pickledBluewood};
    font-size: ${({ zoom }) => getZoomedValue(14, zoom, 'px')};
    font-weight: 600;
  }
`;

StyledTitle.Description = styled.div`
  position: absolute;
  bottom: ${({ zoom }) => getZoomedValue(-12, zoom, 'px')};
  left: ${({ zoom }) => getZoomedValue(1, zoom, 'px')};
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: ${({ zoom }) => getZoomedValue(11, zoom, 'px')};
  line-height: ${({ zoom }) => getZoomedValue(12, zoom, 'px')};
`;

export const StyledInfoIcon = styled.div`
  width: ${({ zoom }) => getZoomedValue(17.5, zoom, 'px')};
  height: ${({ zoom }) => getZoomedValue(17.5, zoom, 'px')};
  font-size: ${({ zoom }) => getZoomedValue(12, zoom, 'px')};
  border: ${({ zoom }) => getZoomedValue(2, zoom, 'px')} solid #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
`;

export const StyledHeadFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledHeadFooterLeft = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
`;

export const StyledHeadText = styled.div`
  font-size: ${({ zoom }) => getZoomedValue(10, zoom, 'px')};
  line-height: ${({ zoom }) => getZoomedValue(14, zoom, 'px')};
`;

export const StyledShareBlock = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: ${({ zoom }) => getZoomedValue(10, zoom, 'px')};
  right: ${({ zoom }) => getZoomedValue(7, zoom, 'px')};
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

export const StyledHeadContainer = styled.div`
  border-right: ${({ divide }) => (divide ? 0 : '1px solid #e5e9e9')};
  position: relative;
  ${sharedStylesDivider}

  ${({ selected }) =>
    selected &&
    css`
      border-right: 0px;
    `}
`;

export const StyledHeadContent = styled.div`
  width: ${({ zoom, width }) => getHeadContentWidth(width, zoom)}px;
  height: ${({ zoom, statisticsTab }) => (statisticsTab ? 70 : 149) * zoom}px;
  padding: ${({ zoom }) => getZoomedValue(7, zoom, 'px')};
  padding-top: ${({ zoom, statisticsTab }) =>
    !statisticsTab ? 7 * zoom : 0}px;
  background: ${({ color = '#8298AB' }) => color};
  text-align: left;
  color: #fff;
  border: 2px solid #fff;
  z-index: 10;
  position: relative;
  border-radius: ${({ zoom }) => getZoomedValue(10, zoom, 'px')};
  transition: border 0.3s ease-in-out, background-color 0.3s ease-in-out;

  ${({ centered }) =>
    centered &&
    css`
      display: flex;
      align-items: center;
    `}
  ${({ selected }) =>
    selected &&
    css`
      border: ${({ zoom }) => getZoomedValue(5, zoom, 'px')} solid
        ${theme.color.pickledBluewood};
      border-bottom-color: transparent;
      width: 100%;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}
  
  ${({ flex }) =>
    flex &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `}

  &:hover, &:active {
    border: ${({ zoom }) => getZoomedValue(4, zoom, 'px')} solid
      ${({ theme }) => theme.color.pickledBluewood};
  }

  &:hover {
    .plus-icon {
      background-color: #8298abb0;
      color: ${({ theme }) => theme.color.white};
    }
  }
`;

export const StyledTaskIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ zoom }) => getZoomedValue(30, zoom, 'px')};
  min-width: ${({ zoom }) => getZoomedValue(30, zoom, 'px')};
  height: ${({ zoom }) => getZoomedValue(30, zoom, 'px')};
  flex-basis: ${({ zoom }) => getZoomedValue(30, zoom, 'px')};
  padding: ${({ zoom, statisticsTab }) =>
    getZoomedValue(statisticsTab ? 0 : 5, zoom, 'px')};
  margin: ${({ zoom, statisticsTab }) =>
    getZoomedValue(statisticsTab ? 20 : 0, zoom, 'px')};
  margin-right: ${({ zoom, statisticsTab }) =>
    getZoomedValue(statisticsTab ? 20 : 11, zoom, 'px')};
  transform: scale(${({ zoom }) => zoom});

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const StyledCardBottomIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
`;

export const StyledOptionalMessageIcon = styled.div`
  display: flex;
  width: ${({ zoom }) => getZoomedValue(17.5, zoom, 'px')};
  height: ${({ zoom }) => getZoomedValue(17.5, zoom, 'px')};
  margin-top: ${({ zoom }) => getZoomedValue(3, zoom, 'px')};
  svg {
    width: ${({ zoom }) => getZoomedValue(17.5, zoom, 'px')};
    min-width: ${({ zoom }) => getZoomedValue(17.5, zoom, 'px')};
    height: ${({ zoom }) => getZoomedValue(17.5, zoom, 'px')};
    path {
      fill: ${({ theme }) => theme.color.white};
      color: ${({ theme }) => theme.color.white};
    }
  }
`;
